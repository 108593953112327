import { StreakTypes } from '@User';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { setModal } from '../../../actions/ui';
import { getStreakCelebrationModalFeatureFlag } from '../../../domains/Utils/useAmplitudeExperiments';

import { useStreaks } from '../../../hooks';
import { usePrevious } from '../../../hooks/usePrevious';
import { STREAK_MILESTONES_SHOW_MILESTONE_MODAL } from '../../../types';
import { Analytics } from '../../../utils/analytics';
import { Events } from '../../../utils/analytics/events';

export const useStreakMilestoneModalHandler = () => {
  const dispatch = useDispatch();
  const { streaks, streakType } = useStreaks();
  const prevCount = usePrevious(streaks.data.weekly.longestCount);
  const streakCelebrationModalFeatureFlag = getStreakCelebrationModalFeatureFlag();

  useEffect(() => {
    if (streakType === StreakTypes.Hidden) return; // prevent animation if streaks are turned off
    if (
      typeof prevCount === 'undefined' ||
      prevCount >= streaks.data.weekly.longestCount ||
      !(STREAK_MILESTONES_SHOW_MILESTONE_MODAL as readonly number[]).includes(
        streaks.data.weekly.longestCount,
      )
    )
      return;
    Analytics.logEventWithProperties(Events.core_streak_milestone_completion, {});
    if (streakCelebrationModalFeatureFlag === 'modal') {
      dispatch(setModal('streakMilestone'));
    }
  }, [streaks.data.weekly.longestCount, dispatch, streakCelebrationModalFeatureFlag]);
};
