import { MenuItemIcons } from '@Cortex';
import { useDispatch } from 'react-redux';

import { uiSliceActions } from '../../../../reducers/uiReducer';
import { Analytics } from '../../../../utils/analytics';
import { useShouldRenderReferAFriend } from './hooks/useShouldRenderReferAFriend';
import { useTrackImpressions } from './hooks/useTrackImpressions';
import { ReferAFriendWidgetPresentation } from './ReferAFriendWidget.presentation';
import { ProfileSection, SideDeckTab } from '../../../../types';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';

export function ReferAFriendWidget() {
  useTrackImpressions();

  const dispatch = useDispatch();
  const shouldRender = useShouldRenderReferAFriend();
  const isMobileView = useIsMobileView();

  function handleClickClose() {
    Analytics.logEvent('player_refer_a_friend_banner_dismiss');
    dispatch(uiSliceActions.setIsReferAFriendDismissed(true));
  }

  function handleClickRefer() {
    dispatch(uiSliceActions.setSideDeckOpen(true));
    dispatch(
      uiSliceActions.setSideDeckTab({
        tab: SideDeckTab.Profile,
        profileSection: ProfileSection.Refer,
      }),
    );
    Analytics.logEvent('player_refer_a_friend_banner_click');
  }

  if (!shouldRender) return null;

  return (
    <ReferAFriendWidgetPresentation
      icon={MenuItemIcons.Close}
      isMobileView={isMobileView}
      onClickButton={handleClickRefer}
      onClickClose={handleClickClose}
    />
  );
}
