import { TransitionStatus } from 'react-transition-group';
import { DefaultTheme } from 'styled-components';

import colors, { ColorsType } from './colors';
import { font, fontFamily, fontSize, Font } from './typography';

const animations = {
  fadeIn: 'fadeIn',
  fadeOut: 'fadeOut',
  rotateFull: 'rotateFull', // 360 rotation
};

const breakPoints = {
  xs: '412px',
  sm: '576px',
  md: '768px',
  lg: '992px',
  xl: '1200px',
  xxl: '1400px',
};

const customMediaQuery = (str: string, side: string, value: number): string =>
  `@media (${str}-${side}: ${value}px)`;

const mediaQuery = {
  minWidth: {
    custom: (value: number): string => customMediaQuery('min', 'width', value),
    xs: `@media (min-width: ${breakPoints.xs})`,
    sm: `@media (min-width: ${breakPoints.sm})`,
    md: `@media (min-width: ${breakPoints.md})`,
    lg: `@media (min-width: ${breakPoints.lg})`,
    xl: `@media (min-width: ${breakPoints.xl})`,
    xxl: `@media (min-width: ${breakPoints.xxl})`,
  },
  maxWidth: {
    custom: (value: number): string => customMediaQuery('max', 'width', value),
    xs: `@media (max-width: ${breakPoints.xs})`,
    sm: `@media (max-width: ${breakPoints.sm})`,
    md: `@media (max-width: ${breakPoints.md})`,
    lg: `@media (max-width: ${breakPoints.lg})`,
    xl: `@media (max-width: ${breakPoints.xl})`,
    xxl: `@media (max-width: ${breakPoints.xxl})`,
  },
  minHeight: {
    custom: (value: number): string => customMediaQuery('min', 'height', value),
  },
  maxHeight: {
    custom: (value: number): string => customMediaQuery('max', 'height', value),
  },
};

const transitionGroupHandlers = {
  transition500: (props: { animationState: TransitionStatus }) => {
    switch (props.animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return '500ms';
      case 'exiting':
        return 0;
      case 'exited':
        return '500ms';
    }
  },
  opacity100: (props: { animationState: TransitionStatus }) => {
    switch (props.animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return 1;
      case 'exiting':
        return 1;
      case 'exited':
        return 0;
    }
  },
};

const theme: DefaultTheme = {
  colors,
  fontFamily,
  fontSize,
  breakPoints,
  mediaQuery,
  font,
  animations,
  transitionGroupHandlers,
};

export default theme;

declare module 'styled-components' {
  export interface DefaultTheme {
    colors: ColorsType;
    fontFamily: typeof fontFamily;
    fontSize: typeof fontSize;
    breakPoints: typeof breakPoints;
    mediaQuery: typeof mediaQuery;
    font: Font;
    animations: typeof animations;
    transitionGroupHandlers: typeof transitionGroupHandlers;
  }
}
