import styled from 'styled-components';

export const PaymentInfo = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 1.5rem;
  color: #d4d2ea;
  > div:first-child {
    flex-shrink: 0;
  }
`;

export const PaymentRow = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`;

export const PaymentInfoLeft = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const CtaWrapper = styled.div`
  margin-top: 1.5rem;
`;

export const PaymentInfoBold = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  color: white;
  white-space: nowrap;
`;

export const PaymentText = styled.div`
  flex-shrink: 1;
`;

export const PaymentDescription = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const PaymentDescriptionMedium = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
`;
