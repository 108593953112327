import styled, { css } from 'styled-components';
import { FontColors } from '@Cortex';
import colors from '../../../../styles/colors';

export const Container = styled.div<{ isSideDeckOpen: boolean }>`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  width: 100%;
  height: 100%;
  gap: 1rem;
  padding: 0.25rem;

  ${({ theme, isSideDeckOpen }) => css`
    ${theme.mediaQuery.maxWidth.md} {
      display: flex;
      flex-direction: column;
    }

    ${isSideDeckOpen && theme.mediaQuery.maxWidth.xxl} {
      display: flex;
      flex-direction: column;
    }
  `}
`;

export const Genre = styled.div<{ selected: boolean; isSideDeckOpen: boolean }>`
  display: flex;
  position: relative;
  flex: 1;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  border-radius: 8px;
  background-color: ${props => (props.selected ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
  box-shadow: ${props =>
    props.selected ? `0 0 0 2px ${colors.white}` : `0 0 0 1px rgba(255, 255, 255, 0.2)`};
  transition: background 0.2s ease-in-out;
  cursor: pointer;

  &:hover {
    background: rgba(255, 255, 255, 0.1);
  }

  ${({ theme, isSideDeckOpen }) => css`
    ${theme.mediaQuery.maxWidth.md} {
      align-items: flex-start;
    }

    ${isSideDeckOpen && theme.mediaQuery.maxWidth.xxl} {
      align-items: flex-start;
    }
  `}
`;

export const Selected = styled.div<{ selected: boolean }>`
  display: ${({ selected }) => (selected ? 'flex' : 'none')};
  position: absolute;
  top: 12px;
  right: 12px;
  height: 2.25rem;
  width: 2.25rem;
  border-radius: 100px;
  background-color: ${colors.white};
  align-items: center;
  justify-content: center;
`;

export const CheckmarkIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const Image = styled.img<{ isSideDeckOpen: boolean }>`
  display: none;
  width: 10rem;
  height: 10rem;
  object-fit: cover;

  ${({ theme, isSideDeckOpen }) => css`
    ${theme.mediaQuery.minWidth.md} {
      display: block;
    }

    ${isSideDeckOpen && theme.mediaQuery.maxWidth.xxl} {
      display: none;
    }
  `}
`;

export const MobileImage = styled.img<{ isSideDeckOpen: boolean }>`
  display: none;
  height: 1.5rem;
  object-fit: contain;

  ${({ theme, isSideDeckOpen }) => css`
    ${theme.mediaQuery.maxWidth.md} {
      display: block;
      margin-bottom: 1rem;
    }

    ${isSideDeckOpen && theme.mediaQuery.maxWidth.xxl} {
      display: block;
      margin-bottom: 1rem;
    }
  `}
`;

export const Title = styled.span<{ isSideDeckOpen: boolean }>`
  color: ${FontColors.White};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: 1rem;
  text-align: center;
  margin-top: 0.5rem;
  width: 100%;

  ${({ theme, isSideDeckOpen }) => css`
    ${theme.mediaQuery.maxWidth.md} {
      text-align: left;
    }

    ${isSideDeckOpen && theme.mediaQuery.maxWidth.xxl} {
      text-align: left;
    }
  `}
`;

export const Description = styled.span<{ isSideDeckOpen: boolean }>`
  color: ${FontColors.WhiteTransparent};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 0.875rem;
  text-align: center;
  width: 100%;
  margin-top: 0.25rem;

  ${({ theme, isSideDeckOpen }) => css`
    ${theme.mediaQuery.maxWidth.md} {
      text-align: left;
    }

    ${isSideDeckOpen && theme.mediaQuery.maxWidth.xxl} {
      text-align: left;
    }
  `}
`;

export const Loading = styled.div`
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: center;
  align-items: center;
  margin-top: 2rem;

  &:after {
    animation: rotation 0.8s linear infinite;
    border-radius: 100%;
    border-right: 3px solid transparent;
    border-top: 3px solid rgba(255, 255, 255, 0.8);
    content: '';
    height: 2rem;
    position: absolute;
    width: 2rem;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;
