import ReactModal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';

import { baseStyles } from '../../../modals/modalStyles';
import { CLOSE_TIMEOUT_MS } from '../../../modals/modalStyles';
import { Button, ButtonVariants, FontColors, Text, TextBold } from '../../Cortex';
import * as S from './styles';
import { RootReducerType } from '../../../reducers';
import { useCallback } from 'react';
import { uiSliceActions } from '../../../reducers/uiReducer';
import { useAnnouncementWidget } from '../../../hooks/useAnnouncementWidget';
import { DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID } from '../../../domains/Memberships/components/DesktopAnnouncementBanner/constants';

export function DesktopAnnouncementMobileModal() {
  const dispatch = useDispatch();
  const modalType = useSelector((state: RootReducerType) => state.ui.modalType);
  const isOpen = modalType === 'desktopAnnouncementMobileModal';
  const { dismissAnnouncement } = useAnnouncementWidget();

  const handleCloseModal = useCallback(() => {
    dismissAnnouncement(DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID);
    dispatch(uiSliceActions.setModalType(null));
  }, [dismissAnnouncement, dispatch]);

  return (
    <ReactModal closeTimeoutMS={CLOSE_TIMEOUT_MS} isOpen={isOpen} style={baseStyles}>
      <S.Wrapper>
        <S.Container>
          <S.ImageContainer>
            <S.Image
              src={'https://cdn.brain.fm/images/download_desktop_app_background_small_modal.webp'}
            />
          </S.ImageContainer>
          <S.ContentContainer>
            <TextBold size="20px">Introducing the Brain.fm Mac App</TextBold>
            <Text color={FontColors.WhiteTransparent} size="16px">
              Stay in the zone without the need to search through tabs.
            </Text>
          </S.ContentContainer>
          <S.ButtonContainer>
            <S.ButtonWrapper href="https://www.brain.fm/download" target="_blank">
              <Button
                isFullWidth
                keepTextCase
                style={{ fontSize: '12px', height: '48px' }}
                variant={ButtonVariants.Secondary}
                onClick={handleCloseModal}
              >
                DOWNLOAD NOW
              </Button>
            </S.ButtonWrapper>

            <Button
              isFullWidth
              keepTextCase
              style={{ fontSize: '12px', height: '48px' }}
              variant={ButtonVariants.Base}
              onClick={handleCloseModal}
            >
              DISMISS
            </Button>
          </S.ButtonContainer>
        </S.Container>
      </S.Wrapper>
    </ReactModal>
  );
}
