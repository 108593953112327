import { membershipSagas } from '@Memberships';
import { musicSagas } from '@Music';
import { paymentSagas } from '@Payment';
import { teamsSaga } from '@Teams';
import { watchTrackResetTimerSaga } from '@Timer';
import { watchUserPreferencesSaga } from '@User';
import { all } from 'redux-saga/effects';

import { activationSagas } from '../domains/Activation/sagas';
import analyticsSaga from './analytics';
import appSaga from './app';
import authSaga from './authentication';
import membershipPlansSaga from './membershipPlans';
import legacyPaymentsSaga from './payments';
import sessionManagerSaga from './sessionManager';
import { watchRecentSessionSaga } from './recentSessions';
import uiSaga from './ui';
import userSaga from './user';

function* rootSaga() {
  yield all([
    activationSagas(),
    analyticsSaga(),
    appSaga(),
    authSaga(),
    membershipPlansSaga(),
    paymentSagas(),
    legacyPaymentsSaga(),
    musicSagas(),
    sessionManagerSaga(),
    membershipSagas(),
    uiSaga(),
    userSaga(),
    teamsSaga(),
    watchTrackResetTimerSaga(),
    watchUserPreferencesSaga(),
    watchRecentSessionSaga(),
  ]);
}

export default rootSaga;
