import { DynamicMentalState, Serving, Track } from '@Model';

import { getTrackGenreName, getTrackImageUrl, getTrackName } from '@Music';
import { useState, useMemo } from 'react';
import LoadingIcon from '../../components/icons/LoadingIcon';
import DynamicMentalStateCard from '../../domains/Cortex/DynamicMentalStateCard';
import { JumpBackInModal } from '../../domains/Session/components/JumpBackInModal';
import { MiniPlayer } from '../../domains/Session/components/MiniPlayer/MiniPlayer';
import * as S from './Home.styles';
import { CardHoverState } from './Home.types';
import { TrackInfoModal } from '../../domains/Music/components/TrackInfoModal/TrackInfoModal';
import SideBarClosedIcon from '../../assets/images/side_bar_closed_icon.svg';
import SideBarClosedIconRedDot from '../../assets/images/side_bar_closed_icon_red_dot.svg';
import SideBarOpenIcon from '../../assets/images/side_bar_open_icon.svg';
import SideBarOpenIconRedDot from '../../assets/images/side_bar_open_icon_red_dot.svg';
import { Icon } from '@Cortex';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import { useAnnouncementWidget } from '../../hooks/useAnnouncementWidget';

type Props = {
  currentTrack?: Track | Serving | null;
  dynamicMentalStates: DynamicMentalState[];
  hoverState: CardHoverState | string;
  isLoading: boolean;
  isSideDeckOpen: boolean;
  navigateToDynamicSessionPlayer: (mentalState: DynamicMentalState) => () => void;
  onClickMiniPlayer: () => void;
  onClickSideDeckButton: () => void;
  onSetHoverState: (state: CardHoverState) => () => void;
  selectedState: string;
};

export const HomePresentation = (props: Props) => {
  const {
    hoverState,
    selectedState,
    dynamicMentalStates,
    isLoading,
    isSideDeckOpen,
    currentTrack,
    navigateToDynamicSessionPlayer,
    onSetHoverState,
    onClickMiniPlayer,
    onClickSideDeckButton,
  } = props;

  const isMobileView = useIsMobileView();

  const [jumpBackInStatus, setJumpBackInStatus] = useState<'hidden' | 'minimized' | 'fullscreen'>(
    'minimized',
  );
  const [isMoreInfoOpened, setIsMoreInfoOpened] = useState(false);
  const [previewTrack, setPreviewTrack] = useState<Track | Serving | null>(null);

  const { announcements } = useAnnouncementWidget();

  const headerIcon = useMemo(() => {
    if (announcements.length > 0) {
      return isSideDeckOpen ? SideBarClosedIconRedDot : SideBarOpenIconRedDot;
    }
    return isSideDeckOpen ? SideBarClosedIcon : SideBarOpenIcon;
  }, [announcements.length, isSideDeckOpen]);

  return (
    <S.Container aria-label="Home" role="main">
      <S.Wrapper
        isJumpBackInVisible={jumpBackInStatus === 'fullscreen'}
        selectedState={selectedState}
      >
        {isLoading ? (
          <S.Body>
            <LoadingIcon aria-label="Loading content" role="status" />
          </S.Body>
        ) : (
          <S.Content>
            <S.Header role="banner">
              {!isMobileView && (
                <S.HeaderIconContainer
                  aria-expanded={isSideDeckOpen}
                  aria-label={isSideDeckOpen ? 'Close side panel' : 'Open side panel'}
                  role="button"
                  tabIndex={0}
                  onClick={onClickSideDeckButton}
                  onKeyDown={e => {
                    if (e.key === 'Enter' || e.key === ' ') {
                      onClickSideDeckButton();
                    }
                  }}
                >
                  <Icon aria-hidden="true" size={32} src={headerIcon} />
                  <S.HeaderIconLabel>{isSideDeckOpen ? 'Close' : 'Open'}</S.HeaderIconLabel>
                </S.HeaderIconContainer>
              )}
            </S.Header>
            <S.BodyDynamic>
              <S.MentalStatesWrapper aria-label="Mental States" role="list">
                {(dynamicMentalStates || [])?.map((mentalState, i) => (
                  <DynamicMentalStateCard
                    key={`mentalStates${mentalState.id}`}
                    aria-label={`Go to ${mentalState.displayValue} session`}
                    color={mentalState.primaryColor}
                    data-testid={`${mentalState.id}Card`}
                    imageUrl={mentalState.imageUrl}
                    imageUrlSecondary={mentalState.imageUrlSecondary}
                    isActive={hoverState === mentalState.id}
                    isFullColumnHeight={i === 0}
                    mentalState={mentalState.displayValue}
                    role="button"
                    tabIndex={0}
                    onClick={navigateToDynamicSessionPlayer(mentalState)}
                    onKeyDown={e => {
                      if (e.key === 'Enter' || e.key === ' ') {
                        navigateToDynamicSessionPlayer(mentalState)();
                      }
                    }}
                    onMouseEnter={onSetHoverState(mentalState.id)}
                    onMouseLeave={onSetHoverState('none')}
                  />
                ))}
              </S.MentalStatesWrapper>
              {currentTrack ? (
                <S.MiniPlayerWrapper>
                  <MiniPlayer
                    aria-label={`Now playing: ${getTrackName(currentTrack)}`}
                    role="complementary"
                    trackGenre={getTrackGenreName(currentTrack)}
                    trackImageUrl={getTrackImageUrl(currentTrack)}
                    trackName={getTrackName(currentTrack)}
                    onClick={onClickMiniPlayer}
                  />
                </S.MiniPlayerWrapper>
              ) : null}
            </S.BodyDynamic>
          </S.Content>
        )}
      </S.Wrapper>

      <TrackInfoModal
        isMobileFixed={true}
        isOpen={isMoreInfoOpened}
        track={previewTrack}
        width={500}
        onClose={() => setIsMoreInfoOpened(false)}
      />

      <S.JumpBackInContainer
        aria-expanded={jumpBackInStatus === 'fullscreen'}
        aria-label="Jump Back In section"
        role="complementary"
      >
        <JumpBackInModal
          onClose={() => setJumpBackInStatus('minimized')}
          onOpen={() => setJumpBackInStatus('fullscreen')}
          onOpenTrackInfoModal={() => setIsMoreInfoOpened(true)}
          onSelectPreviewTrack={track => setPreviewTrack(track)}
        />
      </S.JumpBackInContainer>
    </S.Container>
  );
};
