import { Text } from '@Cortex';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
`;

export const InnerContainer = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  overflow: hidden;
  padding: 1.5rem;
  width: 100%;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%);
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1.25rem;
  color: ${({ theme }) => theme.colors.white};
  z-index: 1;
  margin-bottom: 8px;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white700};
  margin-bottom: 24px;
  z-index: 1;
  width: 70%;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 48px;
  padding: 14px 16px;
  gap: 12px;
  border-radius: 800px;
  background: linear-gradient(140deg, #9130bb -19.11%, #f34675 86.25%, #f39946 203.68%);
  cursor: pointer;
  z-index: 1;
`;

export const ButtonText = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 0.75rem;
  letter-spacing: 1.2px;
  color: ${({ theme }) => theme.colors.white};
`;

export const CircleFadeUp = styled.img`
  height: 24px;
  width: 24px;
`;

export const BackgroundBlur = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
`;

export const SaveBadgeImage = styled.img`
  position: absolute;
  top: -30px;
  right: 0;
  width: 115px;
  height: 115px;
  z-index: 1;
`;
