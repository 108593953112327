import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../reducers';

export const useMilestonesProgressCompleted = () => {
  const {
    items: { firstActivity, uninterruptedListening, favorites },
  } = useSelector((state: RootReducerType) => state.milestones);

  return firstActivity.completed && uninterruptedListening.completed && favorites.completed;
};
