import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { TextBold } from '@Cortex';
import { NOOP } from '@Globals';
import {
  getTrackName,
  getDynamicFavorites,
  getTrackId,
  TrackInformationCard,
  getTrackHasMultipleNELs,
  getTrackImageUrl,
  getTrackIsNewlyCreated,
  getTrackNeuralEffectLevel,
  getTrackGenreName,
  useApplyFiltersToTracks,
} from '@Music';
import { getTrackVariationId } from '@Music';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootReducerType } from 'src/reducers';

import { Analytics } from '../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import { removeFromFavorites } from '../../../../actions';
import * as S from './Favorites.styles';
import { NoFavorites } from './components/NoFavorites';
import { FavoriteShuffleCard } from './components/FavoriteShuffleCard';
import { getDynamicFavoritesActivityPlayerPath } from '../../../../../../utils/getDynamicFavoritesActivityPlayerPath';
import { getTrackDynamicMentalStateId } from '../../../../../../domains/Music/lenses/getTrackDynamicMentalStateId';
import { getTrackRelatedActivities } from '../../../../../../domains/Music/lenses/getTrackRelatedActivities';
import { Serving, Track } from '@Model';
import { SortSelect } from '../../../SortSelect';

type Props = {
  onTrackClickMore: (track: Track | Serving) => void;
};

export function Favorites(props: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const favorites = useSelector(getDynamicFavorites);
  const userId = useSelector((state: RootReducerType) => state.user.info?.id || '');
  const dynamicMentalState = useSelector(
    (state: RootReducerType) => state.ui.sideDeck.librarySelectedMentalState,
  );

  const filteredTracks = useApplyFiltersToTracks(favorites || []);

  const handleOnShuffleClick = () => {
    navigate(
      getDynamicFavoritesActivityPlayerPath({
        userId,
        dynamicMentalStateId: getTrackDynamicMentalStateId(favorites[0]),
      }),
    );
  };

  return (
    <S.Container>
      {filteredTracks.length === 0 ? (
        <NoFavorites mentalState={dynamicMentalState} />
      ) : (
        <>
          <FavoriteShuffleCard onClick={handleOnShuffleClick} />
          <SortSelect />
          {filteredTracks.map(track => {
            const trackId = getTrackId(track);
            const trackName = getTrackName(track);
            const trackVariationId = getTrackVariationId(track);

            function handleTrackSelectTrackEvent() {
              CoreAnalytics.trackSelectTrackEvent({
                origin: TrackActionOrigins.Favorites,
                trackName,
              });
            }
            function handleClickRemove() {
              dispatch(removeFromFavorites({ track }));
            }
            function handleClickPlay() {
              Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromFavorites, {
                trackId,
                trackName,
                trackVariationId,
              } as any);

              navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)));

              handleTrackSelectTrackEvent();
            }

            return (
              <S.CardContainer key={getTrackVariationId(track)} data-testid="favoriteTrackItem">
                <TrackInformationCard
                  favoritesButtonTestId={`removeFromFavorites__${trackId}`}
                  hasMultipleNELs={getTrackHasMultipleNELs(track)}
                  imageUrl={getTrackImageUrl(track)}
                  isFavorited={true}
                  isInSideDeck
                  isNewlyCreated={getTrackIsNewlyCreated(track)}
                  moreIconTestId={`trackCardMoreInfo__${getTrackId(track)}`}
                  neuralEffectLevel={getTrackNeuralEffectLevel(track)}
                  relatedActivities={getTrackRelatedActivities(track)}
                  subtitle={getTrackGenreName(track)}
                  title={getTrackName(track)}
                  onAddToFavorites={NOOP}
                  onClick={handleClickPlay}
                  onClickMore={() => props.onTrackClickMore(track)}
                  onRemoveFromFavorites={handleClickRemove}
                />
              </S.CardContainer>
            );
          })}
        </>
      )}
    </S.Container>
  );
}
