import { useToken } from '@Authentication';
import { useGetAvailableStripeCouponPromotion } from '@Memberships';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { RootReducerType } from '../../../../../reducers';
import { EnhancedMembershipPlanType } from '../../../../../types';
import { performStripeCheckout } from '../lib/performStripeCheckout';
import { PaymentStatus } from '../types';
import { createFacebookEventId } from '../../../../../sagas/authentication';

interface HistoryState {
  redirect: boolean;
  from?: string;
}

type Props = {
  plan: EnhancedMembershipPlanType | null;
  onError: (msg: string | null) => void;
  onPaymentStatusChange: (status: PaymentStatus) => void;
};

export const useHandleStripeCheckout = ({ plan, onError, onPaymentStatusChange }: Props) => {
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = useToken();
  const { info } = useSelector((state: RootReducerType) => state.user);
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const location = useLocation();
  const utmSource = searchParams.get('utm_source') || undefined;

  return useCallback(
    (billingName: string) =>
      performStripeCheckout({
        email: info?.email,
        plan,
        token,
        userId: info?.id,
        utmSource,
        couponId: availablePromotion?.couponId,
        subscribeScreenFrom: (location.state as HistoryState)?.from,
        dispatch,
        onError,
        onPaymentStatusChange,
        cancelUrl: window.location.href,
        successUrl: `${window.location.origin}/?payment_success=true`,
        fbEventId: createFacebookEventId(),
      })(billingName),
    [
      info?.email,
      plan,
      token,
      info?.id,
      utmSource,
      availablePromotion?.couponId,
      dispatch,
      onError,
      onPaymentStatusChange,
    ],
  );
};
