import styled from 'styled-components';

export const Container = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const HeaderTextSubtitle = styled.div`
  color: #d4d2ea;
  font-family: TTNormsPro-Regular;
  font-weight: 400;
  font-size: 1rem;
  margin-bottom: 1.5rem;
`;

export const HeaderTextTitle = styled.div`
  font-family: TTNormsPro-Bold;
  font-weight: 700;
  font-size: 1.25rem;
  margin-bottom: 1rem;
`;

export const TimeRemaining = styled.div`
  display: flex;
  justify-content: center;
  margin-bottom: 1.5rem;
  gap: 8px;
`;

export const TimeValue = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 12px;
  min-width: 65px;
`;

export const TimeValueBig = styled.div`
  display: flex;
  justify-content: center;
  font-family: TTNormsPro-Regular;
  font-weight: 400;
  font-size: 1.5rem;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background-color: #0d0c11;
  border-radius: 8px;
  padding: 20px;
  width: 100%;
`;

export const TimeValueSmall = styled.div`
  color: ${({ theme }) => theme.colors.white500};
  font-family: TTNormsPro-Medium;
  font-weight: 400;
  font-size: 0.875rem;
  letter-spacing: 1.4px;
  text-transform: uppercase;
`;

export const TimeColon = styled.div`
  font-family: TTNormsPro-Bold;
  font-size: 2rem;
  text-align: center;
  margin-top: 15px;
`;
