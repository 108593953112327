import { TextSemiBold } from '../../../../../Cortex';
import * as S from './styles';

type Props = {
  onClick: () => void;
};

export function DesktopAnnouncementSmall(props: Props) {
  return (
    <S.Wrapper onClick={props.onClick}>
      <S.Body>
        <S.RedCircle />
        <TextSemiBold size={14}>NEW: Brain.fm Mac App</TextSemiBold>
      </S.Body>
    </S.Wrapper>
  );
}
