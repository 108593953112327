export function formatGenrePreferences(genres: string[]): string {
  if (genres.length === 1) {
    return genres[0];
  }
  if (genres.length === 2) {
    return genres.join(' & ');
  }
  if (genres.includes('Electronic')) return 'Modern Beats';
  if (genres.includes('Atmospheric')) return 'Epic, Ambient, and Spacey';
  if (genres.includes('Acoustic')) return 'Unplugged';
  if (genres.includes('Rain')) return 'Nature Soundscapes';
  return genres.join(', '); // fallback to original list if none of the special cases match
}
