import styled from 'styled-components';

export const CallToActionWidget = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 101;
`;

export const Body = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  flex: 1;
`;

export const TrialLeftNumber = styled.span`
  background: rgba(255, 255, 255, 0.1);
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  border-radius: 4px;
  padding: 0px 5px;
  margin: 0px;
`;

export const Image = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;
