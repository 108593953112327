import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { milestonesSliceActions, MilestonesState } from '../../../../../reducers/milestones';

export const useMilestonesWidgetModalHandler = () => {
  const dispatch = useDispatch();

  return useCallback(
    (payload: MilestonesState['progressModal']['data']) => {
      dispatch(milestonesSliceActions.setModal(payload));
    },
    [dispatch],
  );
};
