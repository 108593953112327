import { TextBold, Text, FontColors, Icon } from '@Cortex';
import { Transition } from 'react-transition-group';

import * as S from './Profile.styles';
import { ProfileItemActionType, ProfileItemType } from './Profile.types';
import BackIcon from './assets/back_icon.svg';
import { ProfileItem } from './component/ProfileItem/ProfileItem';
import { ProfileSubscription } from './component/ProfileSubscription/ProfileSubscription';
import { ProfilePayment } from './component/ProfilePayment/ProfilePayment';
import { Refer, Settings } from '@User';
import { ProfileAccountInfo } from './component/ProfileAccount/ProfileAccountInfo';
import { ProfileSection } from '../../types';
import { useRef } from 'react';
import { AccountMenuItems } from './utils/useAccountItems';
import BrainfmLogoIcon from './assets/brainfm_logo_icon.svg';
import { DownloadMacApp } from './component/DownloadMacApp';

type Props = {
  selectedView: ProfileSection;
  accountItems: ProfileItemType[];
  companyItems: ProfileItemType[];
  onSelectItem: (item: ProfileItemType) => void;
  onClickLogout: () => void;
  onClickBack: () => void;
};

export function ProfileDisplay({
  selectedView,
  accountItems,
  companyItems,
  onSelectItem,
  onClickLogout,
  onClickBack,
}: Props) {
  const profileRef = useRef<HTMLDivElement>(null);
  const sectionRef = useRef<HTMLDivElement>(null);
  const accountRef = useRef<HTMLDivElement>(null);
  const paymentRef = useRef<HTMLDivElement>(null);
  const subscriptionRef = useRef<HTMLDivElement>(null);
  const settingsRef = useRef<HTMLDivElement>(null);
  const referRef = useRef<HTMLDivElement>(null);
  const downloadMacAppRef = useRef<HTMLDivElement>(null);

  return (
    <S.Container data-testid="Profile">
      <Transition
        in={selectedView === ProfileSection.Profile}
        mountOnEnter
        nodeRef={profileRef}
        timeout={0}
        unmountOnExit
      >
        {animationState => (
          <S.Profile ref={profileRef}>
            <S.HeadingContainer>
              <TextBold size={32}>{'Profile'}</TextBold>
              <S.LogoutButton onClick={onClickLogout}>
                <Text color={FontColors.WhiteTransparent}>Logout</Text>
              </S.LogoutButton>
            </S.HeadingContainer>
            <S.FadeInWrapper animationState={animationState}>
              <S.SectionHeader>
                <TextBold color={FontColors.WhiteTransparent50} size={12}>
                  ACCOUNT
                </TextBold>
              </S.SectionHeader>
              <S.Content style={{ marginBottom: 16 }}>
                {accountItems.map(item => {
                  return (
                    <ProfileItem
                      key={item.label}
                      item={item}
                      testId={item.testid}
                      onClick={onSelectItem}
                    />
                  );
                })}
              </S.Content>
              <S.Content>
                <ProfileItem
                  item={{
                    type: ProfileItemActionType.Screen,
                    screen: ProfileSection.DownloadMacApp,
                    label: AccountMenuItems.Download,
                    iconSrc: BrainfmLogoIcon,
                    testid: 'downloadMacApp',
                  }}
                  onClick={onSelectItem}
                />
              </S.Content>

              <S.SectionHeader>
                <TextBold color={FontColors.WhiteTransparent50} size={12}>
                  COMPANY
                </TextBold>
              </S.SectionHeader>
              <S.Content>
                {companyItems.map(item => {
                  return (
                    <ProfileItem
                      key={item.label}
                      item={item}
                      testId={item.testid}
                      onClick={onSelectItem}
                    />
                  );
                })}
              </S.Content>
            </S.FadeInWrapper>
          </S.Profile>
        )}
      </Transition>
      <Transition
        in={selectedView !== ProfileSection.Profile}
        mountOnEnter
        nodeRef={sectionRef}
        timeout={0}
        unmountOnExit
      >
        {animationState => (
          <S.FadeInWrapper ref={sectionRef} animationState={animationState}>
            <S.SectionHeadingContainer>
              <S.BackIconContainer>
                <Icon size={24} src={BackIcon} onClick={onClickBack} />
              </S.BackIconContainer>
              <TextBold>{selectedView}</TextBold>
            </S.SectionHeadingContainer>
            <Transition
              in={selectedView === ProfileSection.Account}
              mountOnEnter
              nodeRef={accountRef}
              timeout={0}
              unmountOnExit
            >
              <ProfileAccountInfo ref={accountRef} />
            </Transition>

            <Transition
              in={selectedView === ProfileSection.PaymentMethod}
              mountOnEnter
              nodeRef={paymentRef}
              timeout={0}
              unmountOnExit
            >
              <ProfilePayment ref={paymentRef} />
            </Transition>

            <Transition
              in={selectedView === ProfileSection.Subscription}
              mountOnEnter
              nodeRef={subscriptionRef}
              timeout={0}
              unmountOnExit
            >
              <ProfileSubscription ref={subscriptionRef} />
            </Transition>

            <Transition
              in={selectedView === ProfileSection.Settings}
              mountOnEnter
              nodeRef={settingsRef}
              timeout={0}
              unmountOnExit
            >
              <Settings ref={settingsRef} />
            </Transition>

            <Transition
              in={selectedView === ProfileSection.Refer}
              mountOnEnter
              nodeRef={referRef}
              timeout={0}
              unmountOnExit
            >
              <Refer ref={referRef} />
            </Transition>

            <Transition
              in={selectedView === ProfileSection.DownloadMacApp}
              mountOnEnter
              nodeRef={downloadMacAppRef}
              timeout={0}
              unmountOnExit
            >
              <DownloadMacApp ref={downloadMacAppRef} />
            </Transition>
          </S.FadeInWrapper>
        )}
      </Transition>
    </S.Container>
  );
}
