import {
  Loading,
  Icon,
  LoadingText,
  ButtonVariantStates,
  Button,
  ButtonVariantSizes,
} from '@Cortex';
import { TEAMS_APP_URL } from '@Globals';
import { UserTeamType, UserTeamRoles } from '@Teams';
import React from 'react';

import TeamIcon from '../../../../assets/images/team_icon.svg';
import { Variants } from '../../../Cortex/components/Button/Button';
import { MembershipPanelAura } from '../../../Memberships/components/MembershipPanel/components/MembershipPanelAura';
import * as S from './TeamInfo.styles';

export interface Props {
  list: UserTeamType[];
  isLoading: boolean;
}

export const TeamInfoDisplay = ({ list, isLoading }: Props) => {
  return (
    <S.ContentContainer>
      {list.map(({ id, title, hasPlatformAccess, role }) => (
        <S.Container key={id}>
          <S.Header>
            <S.HeaderTopElements>
              <S.HeaderIcon>
                {isLoading ? (
                  <Loading height="44px" uniqueId="membershipPanelLoading" />
                ) : (
                  <S.IconBackground>
                    <Icon size={32} src={TeamIcon} />
                  </S.IconBackground>
                )}
                <S.HeaderIconAura>
                  <MembershipPanelAura
                    innerBackground={hasPlatformAccess ? S.normalAura.inner : S.redAura.inner}
                    outerBackground={hasPlatformAccess ? S.normalAura.outer : S.redAura.outer}
                  />
                </S.HeaderIconAura>
              </S.HeaderIcon>
              <S.HeaderText width={isLoading ? '100%' : 'auto'}>
                <S.HeaderTextSubtitle>
                  {isLoading ? <LoadingText width="25%" /> : `Team ${role}`}
                </S.HeaderTextSubtitle>
                <S.HeaderTextTitle>{isLoading ? <LoadingText /> : title}</S.HeaderTextTitle>
              </S.HeaderText>
              {!hasPlatformAccess ? (
                <div>
                  <S.Tag size="0.75rem">Inactive</S.Tag>
                </div>
              ) : null}
            </S.HeaderTopElements>
          </S.Header>
          <S.Children>
            {isLoading ? (
              <LoadingText />
            ) : (
              <S.SubscriptionInfoText>
                {hasPlatformAccess
                  ? "Enjoy using Brain.FM with the Team's subscription with no limits!"
                  : "Your team doesn't have an active subscription. Contact your team's admin or get an individual subscription instead."}
              </S.SubscriptionInfoText>
            )}
            {[UserTeamRoles.Admin, UserTeamRoles.Owner].includes(role) && (
              <S.ManageButtonWrapper>
                <S.StyledLink href={TEAMS_APP_URL} target="_blank">
                  <Button
                    keepTextCase={true}
                    size={ButtonVariantSizes.Small}
                    style={{ fontSize: '0.75rem' }}
                    variant={Variants.Secondary}
                    variantState={ButtonVariantStates.Idle}
                  >
                    MANAGE TEAM
                  </Button>
                </S.StyledLink>
              </S.ManageButtonWrapper>
            )}
          </S.Children>
        </S.Container>
      ))}
    </S.ContentContainer>
  );
};
