import { FontColors, TextSpaced } from '@Cortex';
import styled from 'styled-components';

export const SessionTypeTextContainer = styled.div`
  align-items: center;
  display: flex;
  justify-content: center;
  min-height: 20px;
  padding-bottom: 1rem;
  position: relative;
  text-align: center;
  width: 100%;
`;

export const SessionTypeText = styled(TextSpaced)<{ isVisible: boolean }>`
  margin: 0;
  opacity: ${props => (props.isVisible ? 1 : 0)};
  position: absolute;
  bottom: 0;
  transition: opacity 0.5s ease-in-out;
  width: fit-content;
  color: ${FontColors.WhiteTransparent};
  text-transform: uppercase;
`;

export const TimerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: fit-content;

  &:hover {
    cursor: pointer;
  }
`;

export const TimerDisplay = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 7rem;
  font-variant: tabular-nums;
  line-height: 8rem;
  margin: 0;
`;

const customProperties = `
  @property --gradient1 {
    syntax: '<color>';
    initial-value: rgba(255, 255, 255, 0.1);
    inherits: false;
  }

  @property --gradient2 {
    syntax: '<color>';
    initial-value: rgba(255, 255, 255, 0.01);
    inherits: false;
  }
`;

export const Pill = styled.div`
  ${customProperties};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 10px 16px;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(180deg, var(--gradient1), var(--gradient2)) padding-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(25px);
  transition:
    --gradient1 0.3s ease-in-out,
    --gradient2 0.3s ease-in-out,
    border-color 0.3s ease-in-out;

  &:hover {
    --gradient1: rgba(255, 255, 255, 0.2);
    --gradient2: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 255, 255, 0.15);
  }
`;
