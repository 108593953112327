import styled from 'styled-components';
import { FontColors } from '@Cortex';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const BoldText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  color: ${FontColors.White};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
`;
export const SemiboldText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.medium};
  color: ${FontColors.White};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
`;

export const DescriptionText = styled.div`
  font-family: ${({ theme }) => theme.fontFamily.regular};
  color: ${FontColors.WhiteTransparent};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 20px;
  margin-top: 10px;
`;
