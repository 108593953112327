export enum Events {
  activity = 'activity',
  mental_state = 'mental_state',
  timer_alarm_setting = 'timer_alarm_setting',
  started_from = 'started_from',

  session_track_complete = 'session_track_complete',
  session_track_favorite = 'session_track_favorite',
  session_minutes_played = 'session_minutes_played',

  dislike_track = 'dislike_track',

  core_streak_share = 'core_streak_share',
  core_streak_toggle = 'core_streak_toggle',
  core_streak_type = 'core_streak_type',
  core_streak_weekly_count_current = 'core_streak_weekly_count_current',
  core_streak_increase_impression = 'core_streak_increase_impression',

  core_streak_celebration_impression = 'core_streak_celebration_impression',
  core_streak_milestone_completion = 'core_streak_milestone_completion',

  activity_selection_opened = 'activity_selection_opened',
  preferences_opened = 'preferences_opened',
  learn_more_button_clicked = 'learn_more_button_clicked',
  learn_more_button_location = 'learn_more_button_location',

  share_track = 'share_track',

  track_genre = 'track_genre',
  track_name = 'track_name',

  filter_genre = 'filter_genre',
  filter_NEL = 'filter_NEL',
  filter_timer = 'filter_timer',

  preferences_activity_filters_changed = 'preferences_activity_filters_changed',
  preferences_genre_filters_changed = 'preferences_genre_filters_changed',
  preferences_nel_filters_changed = 'preferences_nel_filters_changed',

  intention = 'intention',
  session_minutes_left = 'session_minutes_left',
  session_length = 'session_length',
  session_paused_time = 'session_paused_time',
  track_minutes_played = 'track_minutes_played',
  track_minutes_left = 'track_minutes_left',
  interaction_type = 'interaction_type',
  session_paused_length = 'session_paused_length',

  timer_minutes_left = 'timer_minutes_left',
  timer_mode = 'timer_mode',
  timer_countup_time = 'timer_countup_time',
  pause_duration = 'pause_duration',
  quote_ID = 'quote_ID',

  pwa_app_install = 'pwa_app_install',

  test_subscription_track_play = 'test_subscription_track_play',
  test_extension_modal_impression = 'test_extension_modal_impression',
  test_extension_modal_dismissed = 'test_extension_modal_dismissed',
}

export enum SigninEvents {
  sign_in_page_view = 'sign_in_page_view',
  sign_up_page_view = 'sign_up_page_view',

  sign_in_email = 'sign_in_email',
  sign_in_email_error = 'sign_in_email_error',
  sign_in_email_success = 'sign_in_email_success',
  sign_up_email = 'sign_up_email',
  sign_up_email_error = 'sign_up_email_error',
  sign_up_email_success = 'sign_up_email_success',
  sign_in_facebook = 'sign_in_facebook',
  sign_in_facebook_error = 'sign_in_facebook_error',
  sign_in_facebook_success = 'sign_in_facebook_success',
  sign_up_facebook = 'sign_up_facebook',
  sign_up_facebook_error = 'sign_up_facebook_error',
  sign_up_facebook_success = 'sign_up_facebook_success',
  sign_in_apple = 'sign_in_apple',
  sign_in_apple_success = 'sign_in_apple_success',
  sign_in_apple_error = 'sign_in_apple_error',
  sign_up_apple = 'sign_up_apple',
  sign_up_apple_success = 'sign_up_apple_success',
  sign_up_apple_error = 'sign_up_apple_error',
  sign_in_google = 'sign_in_google',
  sign_in_google_error = 'sign_in_google_error',
  sign_in_google_success = 'sign_in_google_success',
  sign_up_google = 'sign_up_google',
  sign_up_google_error = 'sign_up_google_error',
  sign_up_google_success = 'sign_up_google_success',
  email_verified = 'email_verified',
  read_privacy = 'read_privacy',
  read_terms = 'read_terms',
}

export enum TimerEvents {
  Reset = 'player_timer_reset',
  PlayerTimerStart = 'player_timer_start',
}

export enum ExploreEvents {
  ExploreGenre = 'explore_genre_click_genre',
  PlayTrackFromExploreByGenre = 'explore_genre_track_select',
  PlayTrackFromExploreByMood = 'explore_mood_track_select',
  PlayTrackFromFavorites = 'explore_favorites_track_select',
  PlayTrackFromRecents = 'explore_recent_track_select',
  PlayTrackFromSearch = 'explore_searched_track_select',
  PlayTrackFromRecommendedTrack = 'explore_recommended_track_select',
  PlayTrackFromNewlyReleasedTracks = 'explore_newly_released_track_select',
  SearchForTracks = 'explore_searched',
  toggle_sortby_expansion__explore = 'toggle_sortby_expansion__explore',
  toggle_filters_expansion__explore = 'toggle_filters_expansion__explore',
  toggle_track_card_expansion__explore = 'toggle_track_card_expansion__explore',
  toggle_track_card_expansion__favorites = 'toggle_track_card_expansion__favorites',
  toggle_track_card_expansion__player = 'toggle_track_card_expansion__player',
  toggle_track_card_expansion__recent = 'toggle_track_card_expansion__recent',
  toggle_track_card_expansion__newly_released = 'toggle_track_card_expansion__newly_released',
  toggle_track_card_expansion__search = 'toggle_track_card_expansion__search',
  toggle_track_card_expansion__recommended_track = 'toggle_track_card_expansion__recommended_track',
}

export enum PostSessionEvents {
  /** when they rate a session X stars */
  post_session_rated_session = 'post_session_rated_session',
  /** Clicked the post-session CTA in the custom message (whatever it is) */
  post_session_CTA = 'post_session_CTA',
  /** clicked 'start new' to do another session after logging */
  post_session_start_new = 'post_session_start_new',
  /**  */
  post_session_click_twitter = 'post_session_click_twitter',
  /**  */
  post_session_click_facebook = 'post_session_click_facebook',
  /** 'Rate your session' screen -> '(ask me this less') */
  post_session_rating_ask_less = 'post_session_rating_ask_less',
}

export enum SessionEvents {
  session_start = 'session_start',
  /** fires when a session is ended (with or without logging) */
  session_end = 'session_end',
  /** fires when a session is successfully logged in history */
  session_log_complete = 'session_log_complete',
  /** fires after 5 minutes of focus music in countdown mode */
  session_first_five_minutes = 'session_first_five_minutes',

  /** when session timer ends */
  session_timer_end = 'session_timer_end',
  /** when session timer ends and user Clicks Restart button */
  session_timer_end_restart = 'session_timer_end_restart',
  /** when session timer ends and user clicks Keep Going button */
  session_timer_end_keep_going = 'session_timer_end_keep_going',
}

export enum PlayerEvents {
  poc_minutes_listened = 'poc_minutes_listened',
  /** */
  player_pause = 'player_pause',
  /** */
  player_resume = 'player_resume',
  /** opened the timer selection */
  player_timer_open = 'player_timer_open',
  /** Xed out of the timer selection */
  player_timer_close = 'player_timer_close',
  /** timer ended modal opens */
  player_timer_finished_open = 'player_timer_finished_open',
  /** timer ended modal closes */
  player_timer_finished_close = 'player_timer_finished_close',
  /** changed the timer selection */
  player_timer_changed = 'player_timer_changed',
  /** */
  player_skip = 'player_skip',
  /** */
  player_favorite = 'player_favorite',
  /** */
  player_unfavorite = 'player_unfavorite',
  /** */
  player_trackcard_click = 'player_trackcard_click',
  /** clicks 'I'm finished' (to logging) */
  player_imfinished = 'player_imfinished',
  /** change activity on the player */
  player_activity_switch = 'player_activity_switch',
  /** clicks brainfm logo on the player page */
  player_click_home = 'player_click_home',
  /** opens explore music from the player */
  player_explore_music = 'player_explore_music',
  /** clicks tweet icon on the quote */
  player_tweet_quote = 'player_tweet_quote',
  /** time that is shown on a timer */
  timer_shows = 'timer_shows',
  /** music starts playing (either because they hit play or by autostart) */
  player_start_music = 'player_start_music',

  /* refer a friend banner feature flagged tests */
  player_refer_a_friend_banner_click = 'player_refer_a_friend_banner_click',
  player_refer_a_friend_banner_dismiss = 'player_refer_a_friend_banner_dismiss',
  player_refer_a_friend_banner_impression = 'player_refer_a_friend_banner_impression',
}

export enum HomeScreenEvents {
  home_screen_view = 'home_screen_view',
  /** clicked profile page from the home screen */
  home_profile = 'home_profile',
  /** clicked focus from the home page */
  home_screen_start_focus = 'home_screen_start_focus',
  /** clicked sleep from the home page */
  home_screen_start_sleep = 'home_screen_start_sleep',
  /** clicked relax from the home page */
  home_screen_start_relax = 'home_screen_start_relax',
  /** selected a suggested track  */
  home_suggestion_select = 'home_suggestion_select',
  /** hovered over track (eventually hover and play) */
  home_suggestion_hover = 'home_suggestion_hover',
}

export enum PaymentEvents {
  /** user arrives at the subscription screen */
  subscription_screen_view = 'subscription_screen_view',
  /** hits purchase cta (attempted purchase) */
  subscription_purchase = 'subscription_purchase',
  /** attempted purchase goes through */
  subscription_purchase_success = 'subscription_purchase_success',
  /** attempted purchase fails */
  subscription_purchase_error = 'subscription_purchase_error',
  /** user cancels their subscription */
  subscription_cancel = 'subscription_cancel',
  /** what screen led to purchase screen to be seen */
  subscribe_screen_from = 'subscribe_screen_from',
  /** what plan is being purchased/cancelled */
  plan_type = 'plan_type',
  /** what couponId was entered */
  coupon_id = 'coupon_id',

  // user successfully applied a coupon manually
  coupon_applied = 'coupon_applied',

  /** what couponId was entered */
  selected_plan = 'selected_plan',
  coupon_code = 'coupon_code',
}

export enum ProfileEvents {
  profile_open_menu = 'profile_open_menu',
  profile_account = 'profile_account',
  profile_contact_support = 'profile_contact_support',
  profile_usage_stats = 'profile_usage_stats',
  profile_logout = 'profile_logout',
  profile_refer = 'profile_refer',
  profile_settings = 'profile_settings',
  profile_refer_a_friend_copy_link = 'profile_refer_a_friend_copy_link',
  profile_settings_disable_animations = 'profile_settings_disable_animations',
  profile_settings_enable_animations = 'profile_settings_enable_animations',
}

export enum SubscriptionEvents {
  core_renewal_disable_attempt = 'core_renewal_disable_attempt',
  core_renewal_disable_error = 'core_renewal_disable_error',
  core_renewal_disable_success = 'core_renewal_disable_success',
  core_renewal_enable_attempt = 'core_renewal_enable_attempt',
  core_renewal_enable_error = 'core_renewal_enable_error',
  core_renewal_enable_success = 'core_renewal_enable_success',
}

export enum AttributionEvents {
  traffic_ad_id = 'traffic_ad_id',
  traffic_ad_campaign = 'traffic_ad_campaign',
  traffic_ad_source = 'traffic_ad_source',
}

export enum OnboardingEvents {
  onboarding_initiate = 'onboarding_initiate',
  onboarding_complete = 'onboarding_complete',
  onboarding_skip = 'onboarding_skip',
  onboarding_variation = 'onboarding_variation',
  onboarding_card_title = 'onboarding_card_title',
  onboarding_module = 'onboarding_module',
  onboarding_card_index = 'onboarding_card_index',
  neurotype_id = 'neurotype_id',
  origin = 'origin',

  core_mobile_prompt_impression = 'core_mobile_prompt_impression',
  core_mobile_prompt_download = 'core_mobile_prompt_download',
  core_mobile_prompt_dismiss = 'core_mobile_prompt_dismiss',
}
