import { MembershipWidget } from '@Memberships';
import { useShouldRenderMembershipWidget } from '../../domains/Memberships/components/MembershipWidget/hooks/useShouldRenderMembershipWidget';
import { ReferAFriendWidget } from '../../domains/Memberships/components/ReferAFriendWidget';
import { useShouldRenderReferAFriend } from '../../domains/Memberships/components/ReferAFriendWidget/hooks/useShouldRenderReferAFriend';
import { useTeamSubscriptionInfo } from '../../hooks/useHasTeamSubscription';

import * as S from './WidgetBanner.styles';
import { ReferAFriendRewardsWidget } from '../../domains/Memberships/components/ReferAFriendRewardsWidget';
import { useIsEligibleForReferralRewards } from './hooks';

export const WidgetBanner = () => {
  const { isDataReady } = useTeamSubscriptionInfo();
  const shouldRenderMembershipWidget = useShouldRenderMembershipWidget();
  const shouldRenderReferAFriend = useShouldRenderReferAFriend();
  const isEligibleForReferralRewards = useIsEligibleForReferralRewards();

  if (!isDataReady) {
    return null;
  }

  if (shouldRenderMembershipWidget) {
    return (
      <S.Container>
        <MembershipWidget testid="membershipWidgetSessionHeader" />
      </S.Container>
    );
  }

  if (shouldRenderReferAFriend && isEligibleForReferralRewards) {
    return (
      <S.Container>
        <ReferAFriendRewardsWidget />
      </S.Container>
    );
  }

  if (shouldRenderReferAFriend) {
    return (
      <S.Container>
        <ReferAFriendWidget />
      </S.Container>
    );
  }

  return null;
};
