import { RootReducerType } from '../../../reducers';

export function getCardInformationDisplayValue(membership: RootReducerType['membership']) {
  if (!membership.cardType || !membership.cardLastFourDigits) {
    return 'No card on file.';
  }

  return `${getCardTypeDisplayValue(membership.cardType)} ***${membership.cardLastFourDigits}`;
}

function getCardTypeDisplayValue(cardType: string): string {
  // capitalize first letter for now.
  return cardType[0].toUpperCase() + cardType.slice(1);
}
