import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { Text } from '@Cortex';
import { Serving, Track } from '@Model';
import {
  addToFavorites,
  getTrackId,
  getTrackName,
  removeFromFavorites,
  useApplyFiltersToTracks,
} from '@Music';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useTracksByMood } from '../../../../../../api/modules/TracksByMood';
import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import { getDynamicMoodActivityPlayerPath } from '../../../../../../utils/getDynamicMoodActivityPlayerPath';
import { TracksByMoodDisplay } from './TracksByMood.display';
import * as S from './TracksByTag.styles';

interface Props {
  title: string | null;
  onTrackClickMore: (track: Track | Serving) => void;
}

export const TracksByMood = ({ title, onTrackClickMore }: Props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const favorites = useSelector((state: RootReducerType) => state.music.favorites);
  const selectedMentalStateId = useSelector(
    (state: RootReducerType) => state.ui.sideDeck.exploreSelectedMentalState?.id,
  );

  const { isLoading, error, data } = useTracksByMood(title || '');
  const filteredData = useApplyFiltersToTracks(data);

  if (isLoading) {
    return <S.Loading />;
  }

  if (error) {
    return <Text>{error.message}</Text>;
  }

  // sets up a dictionary for efficient lookup of favorite track
  const favoritesDictionary = favorites.reduce(
    (acc, track) => {
      acc[getTrackId(track)] = track;
      return acc;
    },
    {} as { [key: string]: Track | Serving },
  );

  const handleClickPlay = (track: Track | Serving) => {
    Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromExploreByMood, {
      trackId: getTrackId(track),
      trackName: getTrackName(track),
    } as any);

    navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)));

    CoreAnalytics.trackSelectTrackEvent({
      origin: TrackActionOrigins.Explore,
      trackName: getTrackName(track),
    });
  };

  const handlePlayAllClick = () => {
    if (!selectedMentalStateId || !title) return;

    navigate(
      getDynamicMoodActivityPlayerPath({
        mood: title,
        dynamicMentalStateId: selectedMentalStateId,
      }),
    );
  };

  return (
    <TracksByMoodDisplay
      genreName={title || ''}
      shouldRenderPlayAllButton={true}
      tracks={filteredData.map(data => ({
        ...data,
        isFavorited: Boolean(favoritesDictionary[getTrackId(data)]),
        onAddToFavorites: () =>
          dispatch(addToFavorites({ origin: TrackActionOrigins.Explore, track: data })),
        onClickPlay: () => handleClickPlay(data),
        onClickMore: () => onTrackClickMore(data),
        onRemoveFromFavorites: () => dispatch(removeFromFavorites({ track: data })),
      }))}
      onPlayAllClick={handlePlayAllClick}
    />
  );
};
