import styled, { css } from 'styled-components';

export const MainContainer = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const Container = styled.div`
  color: white;
  display: flex;
  flex-direction: column;
  position: relative;
  width: 100%;
`;

export const Title = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1.25rem;
  margin-bottom: 1.5rem;
  z-index: 1;
`;

export const Checkmark = styled.img`
  height: 24px;
  width: 24px;
  margin-right: 1rem;
`;

export const Benefits = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 1.5rem;
  z-index: 1;
`;

export const BenefitsTitle = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 1rem;
  margin-bottom: 0.25rem;
`;

export const BenefitsSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.white700};
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
`;

export const Image = styled.img`
  position: absolute;
  right: -24px;
  bottom: 0;
  height: 22.5rem;
  z-index: -1;
`;

export const PrimaryButtonWrapper = styled.div`
  z-index: 1;
`;
