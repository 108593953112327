import styled from 'styled-components';
import { Assets } from '../../../../utils/assets';

export const Wrapper = styled.div`
  position: absolute;
  top: 80px;
  right: 20px;
`;

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.6);
  z-index: 100;
`;

export const BgImg = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 105;
`;

export const WidgetContainer = styled.div`
  position: relative;
  padding: 20px;
  width: 260px;
  z-index: 110;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    display: none;
  }

  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  background: rgba(13, 12, 17, 0.5);
  backdrop-filter: blur(25px);
`;

export const Title = styled.h3`
  margin: 0 0 20px;
  color: rgba(255, 255, 255, 0.75);
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 12px;
  font-style: normal;
  font-weight: 700;
  line-height: 120%;
  letter-spacing: 1.2px;
  text-transform: uppercase;
`;

export const MilestoneList = styled.ul`
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 15px;
`;

export const MilestoneItem = styled.li<{ completed: boolean }>`
  display: flex;
  align-items: center;
  color: #ffffff;
  font-size: 14px;
  text-decoration: ${props => (props.completed ? 'line-through' : 'none')};
  &:not(:first-child) {
    border-top: 1px solid rgba(255, 255, 255, 0.1);
    padding-top: 15px;
  }

  &:before {
    content: '';
    display: inline-block;
    width: 24px;
    height: 24px;
    margin-right: 12px;
    border-radius: 50%;
    background: ${props => (props.completed ? '#FFFFFF' : 'rgba(255, 255, 255, 0.1)')};
    border: 1px solid ${props => (props.completed ? '#FFFFFF' : 'rgba(255, 255, 255, 0.2)')};
    box-sizing: border-box;
    ${props =>
      props.completed &&
      `
      background-image: url(${Assets.icons.check.url});
      background-repeat: no-repeat;
      background-position: center;
    `}
  }
`;

export const MilestoneModalContainer = styled.div`
  position: absolute;
  top: 120px;
  left: 0;
  z-index: 105;
  border-left: 1px solid white;
  width: calc(100% + 30px);
  margin-left: -30px;
  padding-left: 30px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  padding-top: 110px;
  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    border: 0.5px solid white;
    width: 50%;
  }
`;

export const MilestoneIcon = styled.div`
  display: flex;
  align-items: center;
`;

export const MilestoneModalTitle = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  color: white;
  font-size: 20px;
`;

export const MilestoneModalDescription = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  color: rgba(255, 255, 255, 0.75);
  font-size: 14px;
  margin-bottom: 10px;
`;
