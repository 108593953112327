import { LocalStorage } from '@amplitude/experiment-js-client/dist/types/src/storage/local-storage';
import { Button, ButtonVariants, ButtonVariantSizes } from '@Cortex';
import { Genres, NeuralEffectLevelSelection, PredefinedGenres, PresetGenres } from '@Music';
import { useUser } from '@User';
import {
  useOutsideClickRef,
  showToast,
  getAdhdModeFeatureFlag,
  getPresetFocusGenresFeatureFlag,
} from '@Utils';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import { dynamicReplaceTracksWithUpdatedPreferences } from '../../../../actions/sessionManager';
import { DA_PREFIXES } from '../../../../constants';
import { RootReducerType } from '../../../../reducers';
import { MentalStates } from '../../../../types';
import { Analytics } from '../../../../utils/analytics';
import { Events } from '../../../../utils/analytics/events';
import { Assets } from '../../../../utils/assets';
import {
  trackFilterPreferencesOpened,
  trackFilterPreferencesChanged,
} from '../../../Analytics/coreAnalytics';
import { FilterTypes } from '../../../Analytics/coreAnalytics.types';
import { useDynamicSession } from '../../lenses/useDynamicSession';
import { getIsAdhdModeActive } from '../../../../utils/getIsAdhdModeActive';
import { getSelectedPresets } from './lib/getSelectedPresets';
import { getUpdatedGenresForPreset } from './lib/getUpdatedGenresForPreset';
import * as S from './Preferences.styles';
import { NEL_LEARN_MORE_LINK, GENRE_TOGGLE_OPTION_STORAGE_KEY } from './constants';
import { DynamicActivity } from '@Model';
import { NELInfo } from './components/NELInfo';
import { MAIN_TOAST } from '@Globals';
import { AdhdSwitch } from '../AdhdSwitch/AdhdSwitch.container';
import { GenreToggle } from './components/GenreToggle';
import { GenreToggleOption } from './components/GenreToggle/GenreToggle';
import { PresetGenresTypes } from './types';

interface Props {
  onClose: () => void;
}

export function Preferences(props: Props) {
  const dispatch = useDispatch();
  const user = useUser();
  const session = useDynamicSession();
  const outsideClickRef = useOutsideClickRef({ onOutsideClick: props.onClose });
  const sessionDynamicActivity = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity,
  );
  const adhdModeEnabled = getAdhdModeFeatureFlag() === 'on' && session?.mentalState.id === 'focus';
  const presetFocusGenresEnabled =
    getPresetFocusGenresFeatureFlag() === 'on' && session?.mentalState.id === 'focus';
  const isSideDeckOpen = useSelector((state: RootReducerType) => state.ui.sideDeck.isOpen);
  const stateSelectedGenres = session
    ? user.mentalStatePreferences[session.mentalState.id as MentalStates].genreNames
    : [];
  const stateSelectedNELs = session
    ? user.mentalStatePreferences[session.mentalState.id as MentalStates].neuralEffectLevels
    : [];

  const [localSelectedGenres, setLocalGenres] = useState(stateSelectedGenres);
  const [localSelectedNELs, setLocalNELs] = useState(stateSelectedNELs);
  const [isAdhdModeActive, setIsAdhdModeActive] = useState(getIsAdhdModeActive(localSelectedNELs));
  const [genreToggleOption, setGenreToggleOption] = useState<GenreToggleOption>(
    (localStorage.getItem(GENRE_TOGGLE_OPTION_STORAGE_KEY) as GenreToggleOption) ||
      GenreToggleOption.Presets,
  );
  const [selectedPresetGenres, setSelectedPresetGenres] = useState<PresetGenresTypes[]>(
    getSelectedPresets(localSelectedGenres),
  );

  useEffect(() => {
    setSelectedPresetGenres(getSelectedPresets(localSelectedGenres));
  }, [localSelectedGenres]);

  useEffect(() => {
    setIsAdhdModeActive(getIsAdhdModeActive(localSelectedNELs));
  }, [localSelectedNELs]);

  const isSimilarDynamicActivity = (sessionDynamicActivity?.id || '').startsWith(
    DA_PREFIXES.SIMILAR,
  );
  const isFavoritesDynamicActivity = (sessionDynamicActivity?.id || '').startsWith(
    DA_PREFIXES.FAVORITES,
  );
  const isGenresDynamicActivity = (sessionDynamicActivity?.id || '').startsWith(
    DA_PREFIXES.GENRE_NAMES,
  );

  const isMoodDynamicActivity = (sessionDynamicActivity?.id || '').startsWith(DA_PREFIXES.MOOD);

  useEffect(() => {
    Analytics.logEventWithProperties(Events.preferences_opened, {});
    if (session?.mentalState) {
      trackFilterPreferencesOpened({
        filterType: FilterTypes.Consolidated,
        mentalState: session.mentalState.id as MentalStates,
      });
    }
  }, []);

  const handleAnalytics = (
    newState: {
      localSelectedGenres: string[];
      localSelectedNELs: string[];
      localSelectedActivity: Omit<DynamicActivity, 'mentalState'>;
    },
    oldState: {
      stateSelectedGenres: string[];
      stateSelectedNELs: string[];
      stateSelectedActivity: Omit<DynamicActivity, 'mentalState'>;
    },
  ) => {
    // track genres changes
    let genresChanged = null;
    let nelChanged = null;
    if (
      JSON.stringify(newState.localSelectedGenres) !== JSON.stringify(oldState.stateSelectedGenres)
    ) {
      const newGenre = [...newState.localSelectedGenres].sort().join(',');
      const oldGenre = [...oldState.stateSelectedGenres].sort().join(',');
      Analytics.logEventWithProperties(Events.preferences_genre_filters_changed, {
        old_filter: oldGenre,
        new_filter: newGenre,
      } as any);
      genresChanged = {
        newFilterGenre: newGenre,
        oldFilterGenre: oldGenre,
      };
    }

    // track NEL changes
    if (JSON.stringify(newState.localSelectedNELs) !== JSON.stringify(oldState.stateSelectedNELs)) {
      const oldNel = oldState.stateSelectedNELs.slice().sort().join(',');
      const newNel = newState.localSelectedNELs.slice().sort().join(',');
      Analytics.logEventWithProperties(Events.preferences_nel_filters_changed, {
        old_filter: oldNel,
        new_filter: newNel,
      } as any);
      nelChanged = {
        newFilterNel: newNel,
        oldFilterNel: oldNel,
      };
    }

    if (
      newState.localSelectedActivity?.displayValue !== oldState.stateSelectedActivity?.displayValue
    ) {
      // track activity changes
      Analytics.logEventWithProperties(Events.preferences_activity_filters_changed, {
        old_filter: oldState.stateSelectedActivity?.displayValue,
        new_filter: newState.localSelectedActivity?.displayValue,
      } as any);
    }

    if (session && (nelChanged || genresChanged)) {
      trackFilterPreferencesChanged({
        filterType: FilterTypes.Consolidated,
        mentalState: session?.mentalState.id as MentalStates,
        ...nelChanged,
        ...genresChanged,
      });
    }
  };

  const onNelReadMoreClick = useCallback(() => {
    window.open(NEL_LEARN_MORE_LINK, '_blank');
    Analytics.logEventWithProperties(Events.learn_more_button_clicked, {
      learn_more_button_location: 'nel_filter',
    });
  }, []);

  const handleGenreSelect = (genreName: string) => {
    if (localSelectedGenres.includes(genreName)) {
      setLocalGenres(localSelectedGenres.filter(item => item !== genreName));
    } else {
      setLocalGenres([...localSelectedGenres, genreName]);
    }
  };

  const handleNELSelect = (NEL: string) => {
    const isAdhdModeCurrentlyEnabled = getIsAdhdModeActive(localSelectedNELs);
    let newNels: string[];

    if (localSelectedNELs.includes(NEL)) {
      newNels = localSelectedNELs.filter(item => item !== NEL);
      setLocalNELs(newNels);
    } else {
      newNels = [...localSelectedNELs, NEL];
      setLocalNELs(newNels);
    }
    const isAdhdModeEnabling = getIsAdhdModeActive(newNels);
    if (isAdhdModeCurrentlyEnabled && !isAdhdModeEnabling && adhdModeEnabled) {
      showToast({
        message: 'ADHD Mode turned off when using Low or Medium Neural Effect Levels',
        containerId: MAIN_TOAST,
      });
    }
  };

  const handleAdhdModeClick = useCallback(() => {
    Analytics.logEventWithProperties(
      isAdhdModeActive ? 'player_settings_adhd_mode_disabled' : 'player_settings_adhd_mode_enabled',
      {},
    );
    setLocalNELs(isAdhdModeActive ? [] : ['High']);
  }, [isAdhdModeActive, setLocalNELs]);

  const handleApplyChanges = () => {
    let payload: {
      genreNames?: string[];
      neuralEffectLevels?: string[];
    } = {};

    if (JSON.stringify(stateSelectedGenres) !== JSON.stringify(localSelectedGenres)) {
      payload.genreNames = localSelectedGenres;
    }
    if (JSON.stringify(stateSelectedNELs) !== JSON.stringify(localSelectedNELs)) {
      payload.neuralEffectLevels = localSelectedNELs;
    }
    dispatch(dynamicReplaceTracksWithUpdatedPreferences(payload));
    handleAnalytics(
      {
        localSelectedGenres,
        localSelectedNELs,
        localSelectedActivity: sessionDynamicActivity!,
      },
      {
        stateSelectedActivity: sessionDynamicActivity!,
        stateSelectedGenres,
        stateSelectedNELs,
      },
    );

    props.onClose();
    showToast({ message: 'Your preferences have been updated!', containerId: MAIN_TOAST });
    localStorage.setItem(GENRE_TOGGLE_OPTION_STORAGE_KEY, genreToggleOption);
  };

  const handleGenreToggle = useCallback(
    (option: GenreToggleOption) => {
      setGenreToggleOption(option);
    },
    [setGenreToggleOption],
  );

  const handlePreselectedGenreSelect = (presetId: PresetGenresTypes) => {
    const newSelectedGenres = getUpdatedGenresForPreset(presetId, localSelectedGenres);
    setLocalGenres(newSelectedGenres);
  };

  const handleSelectAllGenres = (genres: string[]) => {
    setLocalGenres(state => [...state, ...genres]);
  };

  const handleSelectAllNatureGenres = (genres: string[]) => {
    setLocalGenres(state => [...state, ...genres]);
  };

  const renderNELPanel = () => {
    return (
      <>
        <S.NelHeadingContainer isSideDeckOpen={isSideDeckOpen}>
          <S.HeadingContainerWithIcon>
            <S.HeaderTitle>Neural Effect Level</S.HeaderTitle>
            <S.InformationIcon className="nel-information-tooltip" />
            <Tooltip
              anchorSelect=".nel-information-tooltip"
              clickable
              style={{
                backgroundColor: 'black',
                opacity: 0.9,
                width: '20rem',
                borderRadius: '8px',
                padding: '1.5rem',
                zIndex: 10,
              }}
            >
              <NELInfo onClick={onNelReadMoreClick} />
            </Tooltip>
          </S.HeadingContainerWithIcon>
          <S.HeaderDescription>
            Select the neural effect level(s) you'd like us to incorporate into your session.
          </S.HeaderDescription>
        </S.NelHeadingContainer>
        {adhdModeEnabled && (
          <S.AdhdModeContainer>
            <S.AdhdTextContainer>
              <S.AdhdTitle>
                <img alt="" src={Assets.icons.lightning.url} />
                ADHD Mode
              </S.AdhdTitle>
              <S.AdhdDescription>
                Turn this on for music designed to help ADHD brains focus best.
              </S.AdhdDescription>
            </S.AdhdTextContainer>
            <AdhdSwitch isActive={isAdhdModeActive} onClick={handleAdhdModeClick} />
          </S.AdhdModeContainer>
        )}
        <S.SectionWrapper>
          <NeuralEffectLevelSelection
            mentalStateId={sessionDynamicActivity?.mentalState.id}
            selected={localSelectedNELs}
            onSelect={handleNELSelect}
          />
        </S.SectionWrapper>
      </>
    );
  };

  const isAnythingChanged =
    JSON.stringify(localSelectedGenres) !== JSON.stringify(stateSelectedGenres) ||
    JSON.stringify(localSelectedNELs) !== JSON.stringify(stateSelectedNELs);

  return (
    <S.Wrapper ref={outsideClickRef} data-testid="preferencesPanel">
      <S.Container>
        <S.PanelMobile>{renderNELPanel()}</S.PanelMobile>

        <S.Panel>
          <S.GenresHeadingContainer isSideDeckOpen={isSideDeckOpen}>
            <S.HeaderLeft>
              <S.HeaderTitle>Genre</S.HeaderTitle>
              <S.HeaderDescription>
                Select the genres you would like to hear while listening.
              </S.HeaderDescription>
            </S.HeaderLeft>
            {presetFocusGenresEnabled && (
              <GenreToggle
                option={genreToggleOption}
                selectedGenresCount={localSelectedGenres.length}
                onClick={handleGenreToggle}
              />
            )}
          </S.GenresHeadingContainer>
          <S.SectionWrapper>
            {isSimilarDynamicActivity ||
            isFavoritesDynamicActivity ||
            isGenresDynamicActivity ||
            isMoodDynamicActivity ? (
              <PredefinedGenres />
            ) : (
              <>
                {genreToggleOption === GenreToggleOption.Presets && presetFocusGenresEnabled ? (
                  <PresetGenres
                    activityId={sessionDynamicActivity?.id}
                    selectedPresets={selectedPresetGenres}
                    onGenreClick={handlePreselectedGenreSelect}
                  />
                ) : (
                  <Genres
                    activityId={sessionDynamicActivity?.id}
                    selected={localSelectedGenres}
                    onSelect={handleGenreSelect}
                    onSelectAllGenres={handleSelectAllGenres}
                    onSelectAllNatureGenres={handleSelectAllNatureGenres}
                  />
                )}
              </>
            )}
          </S.SectionWrapper>
        </S.Panel>

        <S.PanelDesktop>{renderNELPanel()}</S.PanelDesktop>
      </S.Container>

      <S.Bottom>
        <Button
          data-testid="preferences-discard-changes"
          isFullWidth
          keepTextCase
          style={{ fontSize: '0.75rem', height: '48px' }}
          variant={ButtonVariants.Base}
          onClick={props.onClose}
        >
          CANCEL
        </Button>
        <Button
          data-testid="preferences-apply-changes"
          disabled={!isAnythingChanged}
          isFullWidth
          keepTextCase
          style={{ fontSize: '0.75rem', height: '48px' }}
          variant={ButtonVariants.Secondary}
          onClick={handleApplyChanges}
        >
          APPLY
        </Button>
      </S.Bottom>
    </S.Wrapper>
  );
}
