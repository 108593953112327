import styled from 'styled-components';
import { Assets } from '../../../../utils/assets';
import { Button } from '@Cortex';

export const CallToActionWidget = styled.div`
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  z-index: 101;
`;

export const SVGImage = styled.img`
  position: absolute;
  align-self: center;
  left: 0;
`;

export const Body = styled.div`
  display: flex;
  gap: 0.5rem;
  align-items: center;
  position: relative;
  z-index: 1;
`;

export const Icon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const Highlight = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  position: relative;
  display: inline-block;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};

  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 7px;
    bottom: -7px;
    width: 100%;
    background-image: url(${Assets.images.highlight.url});
    background-repeat: no-repeat;
    background-position: center bottom;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CloseButtonContainer = styled.div`
  opacity: 0.75;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export const GradientButton = styled(Button).attrs({
  keepTextCase: true,
})`
  color: white;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-size: 0.75rem;
  background: linear-gradient(
    117deg,
    rgba(67, 82, 133, 1) 0%,
    rgba(67, 62, 89, 1) 32%,
    rgba(67, 62, 89, 1) 59%,
    rgba(122, 74, 108, 1) 100%
  );
  border: 1px solid rgba(255, 255, 255, 0.1);
  box-shadow: none;
  background-origin: border-box;
`;
