import {
  MembershipSources,
  useGetAvailableStripeCouponPromotion,
  useGetAvailableRedemptionCodePromotion,
  useApplyRedemptionCode,
} from '@Memberships';
import { useIsPaymentProcessing } from '@Payment';
import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootReducerType } from '../../../../../../reducers';
import { useIsExpired } from '../../../../lenses/isExpired';
import { useMembershipPeriodTimeLeft } from '../../hooks/useMembershipPeriodTimeLeft';
import { MembershipPanelWrapper } from '../MembershipPanelWrapper/MembershipPanelWrapper';
import { getCouponCallToActionText } from '../../utils/getCouponCallToActionText';
import { TrialCallToActionPresentation } from './TrialCallToAction.presentation';
import { getSubtitle } from './utils/getSubtitle';
import { useIsMobileView } from '../../../../../../hooks/useIsMobileView';

export const TrialCallToAction = () => {
  const navigate = useNavigate();
  const isPaymentProcessing = useIsPaymentProcessing();
  const applyRedemptionCode = useApplyRedemptionCode();
  const { timeLeftInDays, timeLeftInHours } = useMembershipPeriodTimeLeft();
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const availableRedemption = useGetAvailableRedemptionCodePromotion();
  const isExpired = useIsExpired();
  const membershipSource = useSelector(
    (state: Pick<RootReducerType, 'membership'>) => state.membership.source,
  );
  const isLoading = membershipSource === MembershipSources.Loading || isPaymentProcessing;
  const isMobileView = useIsMobileView();

  const subtitle = getSubtitle({
    isExpired,
    timeLeftInHours,
    timeLeftInDays,
  });

  const ctaText = availableRedemption
    ? 'Apply Redemption Code!'
    : getCouponCallToActionText(availablePromotion);

  const onCtaClick = useCallback(() => {
    if (availableRedemption) {
      applyRedemptionCode();
      return;
    }

    navigate(
      {
        pathname: '/payment',
      },
      {
        state: { from: 'Membership Panel TrialCallToAction' },
      },
    );
  }, [navigate, availableRedemption, applyRedemptionCode]);

  return (
    <MembershipPanelWrapper
      isExpired={isExpired}
      isLoading={isLoading}
      subtitle={subtitle}
      title="Brain.fm Trial"
    >
      <TrialCallToActionPresentation
        callToActionText={ctaText}
        isMobileView={isMobileView}
        onCTAClick={onCtaClick}
      />
    </MembershipPanelWrapper>
  );
};
