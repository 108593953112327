import { useMutation } from '@tanstack/react-query';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../reducers';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { TMarkRewardsSeenResponse } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useMarkRewardsSeen = () => {
  const postMarkRewardsSeen = useRequestHandler();
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);

  return useMutation<TMarkRewardsSeenResponse, Error>(
    Queries.setAllRewardsSeen(userId!),
    () => postMarkRewardsSeen(),
    {
      onError: () => {
        Logger.error(new Error('Error marking rewards seen'));
      },
      onSuccess: data => {},
    },
  );
};
