import { useEffect } from 'react';
import { Analytics } from '../../../../utils/analytics';
import { CoreAnalyticsEventTypes } from '../../../../domains/Analytics/coreAnalytics.types';
import { getOnboardingIntroCardFeatureFlag } from '@Utils';
import { IntroCardV1 } from './IntroCardV1';
import { IntroCardV2 } from './IntroCardV2';

type Props = {
  onContinue: () => void;
};

export const IntroCard = ({ onContinue }: Props) => {
  const onboardingIntroCardFeatureFlag = getOnboardingIntroCardFeatureFlag();

  useEffect(() => {
    Analytics.logEventWithProperties(CoreAnalyticsEventTypes.OnboardingIntroCardImpression, {
      intro_card_variation: onboardingIntroCardFeatureFlag,
    });
  }, []);

  return (
    <>
      {onboardingIntroCardFeatureFlag === 'v2' ? (
        <IntroCardV2 onContinue={onContinue} />
      ) : (
        <IntroCardV1 onContinue={onContinue} />
      )}
    </>
  );
};
