import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Container = styled.div<{ animationState: TransitionStatus }>`
  animation: ${({ theme }) => theme.animations.fadeIn} 0.3s ease-in-out;
  visibility: ${({ animationState }) => (animationState == 'entered' ? 'visible' : 'hidden')};
  margin: auto;
`;

export const Image = styled.img`
  object-fit: contain;
  margin-bottom: 1rem;
`;

export const SubmitButton = styled.div`
  margin-bottom: 1rem;
`;

export const Blur = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  width: 100%;
  z-index: -1;
  border-radius: 1.2rem;
  object-fit: fill;
`;
