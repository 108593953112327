import { Assets } from '../../../../utils/assets';

export const getSessionTimeImage = (mentalState: string | null) => {
  if (!mentalState) {
    return {
      url: Assets.images.clock30.url,
      alt: Assets.images.clock30.alt,
    };
  }

  switch (mentalState) {
    case 'focus':
      return {
        url: Assets.images.clock30.url,
        alt: Assets.images.clock30.alt,
      };
    case 'relax':
      return {
        url: Assets.images.clock15.url,
        alt: Assets.images.clock15.alt,
      };
    case 'sleep':
      return {
        url: Assets.images.clock10.url,
        alt: Assets.images.clock10.alt,
      };
    case 'meditate':
      return {
        url: Assets.images.clock15.url,
        alt: Assets.images.clock15.alt,
      };
    default:
      return {
        url: Assets.images.clock30.url,
        alt: Assets.images.clock30.alt,
      };
  }
};
