import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModal } from '../../../actions/ui';
import { RootReducerType } from '../../../reducers';
import { Analytics } from '../../../utils/analytics';
import { OnboardingEvents } from '../../../utils/analytics/events';
import { getDeviceType } from '../../../utils/getDeviceType';
import { ANDROID_DOWNLOAD_LINK, IOS_DOWNLOAD_LINK } from '@Globals';
import { trackAppDownloadModalExperimentExposure } from '../../Utils/useAmplitudeExperiments';

import { AppDownloadModalDisplay } from './AppDownloadModal.display';

export function AppDownloadModal() {
  const modalType = useSelector((state: RootReducerType) => state.ui.modalType);
  const dispatch = useDispatch();
  const { isIos } = getDeviceType();

  const onClose = useCallback(() => {
    dispatch(setModal(null));
    Analytics.logEventWithProperties(OnboardingEvents.core_mobile_prompt_dismiss, {});
  }, [dispatch, setModal]);

  const onCtaClick = useCallback(() => {
    const downloadLink = isIos ? IOS_DOWNLOAD_LINK : ANDROID_DOWNLOAD_LINK;
    window.open(downloadLink, '_blank');
    dispatch(setModal(null));
    Analytics.logEventWithProperties(OnboardingEvents.core_mobile_prompt_download, {});
  }, [dispatch]);

  const isOpen = modalType === 'appDownload';

  useEffect(() => {
    if (!isOpen) return;
    trackAppDownloadModalExperimentExposure();
    Analytics.logEventWithProperties(OnboardingEvents.core_mobile_prompt_impression, {});
  }, [isOpen]);

  return (
    <AppDownloadModalDisplay
      isIos={isIos}
      isOpen={isOpen}
      primaryButtonAction={onCtaClick}
      primaryButtonText="DOWNLOAD MOBILE APP"
      secondaryButtonText="NOT RIGHT NOW"
      onClose={onClose}
    />
  );
}
