import {
  MenuItem,
  MenuItemIcons,
  Text,
  ButtonVariants,
  ButtonVariantSizes,
  FontFamilies,
  FontColors,
} from '@Cortex';
import { Assets } from '../../../../utils/assets';
import * as S from './styles';
import AuraSVGImage from './assets/aura.svg';

export type Props = {
  icon?: MenuItemIcons;
  isMobileView: boolean;
  onClickButton: () => void;
  onClickClose?: () => void;
};

export const ReferAFriendRewardsWidgetPresentation = (props: Props) => {
  const { onClickButton, isMobileView } = props;

  const bannerText = isMobileView
    ? 'Earn free access for sharing Brain.fm!'
    : 'Earn free access for sharing Brain.fm!';
  const buttonText = isMobileView ? 'EARN REWARDS' : 'START EARNING REWARDS';

  return (
    <S.CallToActionWidget>
      <S.Body data-testid="referAFriendWidgetBody">
        <S.IconContainer>
          <S.Icon src={Assets.images.congratulations.url} />
        </S.IconContainer>
        <S.TextContainer>
          <Text family={FontFamilies.SemiBold} size={14}>
            {bannerText}
          </Text>
          <Text color={FontColors.WhiteTransparent} size={12}>
            Refer friends and unlock free months.
          </Text>
        </S.TextContainer>
      </S.Body>
      <S.Row>
        <S.WhiteButton
          data-testid="referAFriendWidgetDesktop"
          keepTextCase={true}
          size={ButtonVariantSizes.Small}
          variant={ButtonVariants.Secondary}
          onClick={onClickButton}
        >
          <S.ButtonContent>{buttonText}</S.ButtonContent>
        </S.WhiteButton>
        <S.CloseButtonContainer>
          <MenuItem
            icon={props.icon || MenuItemIcons.Alert}
            isActive={true}
            onClickIcon={props.onClickClose}
          />
        </S.CloseButtonContainer>
      </S.Row>
      <S.SVGImage src={Assets.images.allGradientCircles.url} />
      <S.SVGImageSecond src={Assets.images.allGradientCircles.url} />
    </S.CallToActionWidget>
  );
};
