import { membershipActions } from '@Memberships';
import { paymentActions as paymentDomainActions } from '@Payment';
import { Action } from '@reduxjs/toolkit';
import { takeLatest, put, select, call } from 'redux-saga/effects';

import * as paymentActions from '../actions/payments';
import * as uiActions from '../actions/ui';
import { RequestMethods } from '../api/client/types';
import {
  trackSubscriptionPlanChangeSuccess,
  trackSubscriptionPlanChangeError,
  trackSubscriptionPlanChangeAttempt,
} from '../domains/Analytics/coreAnalytics';
import { PlanTypes } from '../domains/Analytics/coreAnalytics.types';
import { RootReducerType } from '../reducers';
import { userSliceActions } from '../reducers/user';
import { getErrorMessage } from '../utils/getErrorMessage';
import { Logger } from '../utils/logger';
import { requestSaga } from './httpRequest';
import { getMonthlySubscriptionPriceFromPlans } from '../utils/getMonthlySubscriptionPriceFromPlan';

function* updateMembershipSaga(action: Action) {
  try {
    if (paymentActions.updateMembership.match(action)) {
      const user: RootReducerType['user'] = yield select((state: RootReducerType) => state.user);

      trackSubscriptionPlanChangeAttempt({
        oldPlanType: user.membership?.title as PlanTypes,
        newPlanType: action.payload.planType,
      });

      yield put(paymentDomainActions.receiveUpgradeProcessing());

      const { result } = yield requestSaga(RequestMethods.POST, '/payments/update-subscription', {
        userId: user.info?.id,
        newMembershipId: action.payload.membershipId,
      });

      yield put(userSliceActions.setMembershipInfo(result));
      yield put(paymentDomainActions.resetState());
      yield put(membershipActions.fetchMembership());

      const membershipPlans: RootReducerType['membershipPlans'] = yield select(
        (state: RootReducerType) => state.membershipPlans,
      );
      const monthlySubscriptionPrice = getMonthlySubscriptionPriceFromPlans(membershipPlans);

      yield put(
        uiActions.successModalOpen({
          title: 'Success!',
          description:
            action.payload.planType === PlanTypes.Monthly
              ? `Your Brain.fm subscription has been updated to the Monthly Plan at ${monthlySubscriptionPrice}/month.`
              : 'Your account has been upgraded!',
          actions: [{ type: 'primary', text: 'CLOSE', action: uiActions.successModalClose() }],
        }),
      );

      trackSubscriptionPlanChangeSuccess({
        newPlanType: action.payload.planType,
        oldPlanType: user.membership?.title as PlanTypes,
      });
    }
  } catch (error) {
    trackSubscriptionPlanChangeError({
      errorMessage: getErrorMessage(error),
    });
    yield put(
      uiActions.successModalOpen({
        iconSrc: null,
        title: 'Something went wrong!',
        description:
          'Please check your payment information and try again! If you continue to receive this message use the "Contact Support" option for assistance!',
        actions: [{ type: 'primary', text: 'CLOSE', action: uiActions.successModalClose() }],
      }),
    );
    Logger.error(error);
  } finally {
    yield put(paymentDomainActions.receiveUpgradeError());
  }
}

export default function* watchPaymentsSaga() {
  yield takeLatest(paymentActions.updateMembership.type, updateMembershipSaga);
}
