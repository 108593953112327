export const getSessionTypeTitle = (mentalState: string | null): string => {
  if (!mentalState) {
    return 'Start Working'; // Fallback to focus if no mental state is provided
  }

  switch (mentalState) {
    case 'focus':
      return 'Start Working';
    case 'relax':
      return 'Get Comfortable';
    case 'sleep':
      return 'Fall Asleep';
    case 'meditate':
      return 'Start Meditating';
    default:
      return 'Start Working';
  }
};
