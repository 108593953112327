import { ReviewSource } from '../../../../domains/Onboarding/types';

export const PROMO_QUERY_PARAM_KEY = 'extended_promo';
export const PROMO_QUERY_PARAM_VALUES = ['0', '30', '60', '90'];
export const DEFAULT_EXTENDED_YEARLY_TRIAL_PERIOD = '30';

export const DEFAULT_BENEFITS = [
  'Find your focus any time, any place',
  'Get more done and have fun doing it',
  'Diverse genres tailored for you',
  'Unlimited listening on web and mobile',
  'New sounds added every week',
];

export const DEFAULT_TRIAL_LENGTH = {
  yearly: 14,
  monthly: 7,
};

export const REVIEWS = [
  {
    id: 1,
    text: 'I’m a big fan of the *@‌brainfmapp*. I don’t think I’ve found a playlist on Spotify that rivals Brain.fm’s ability to keep me in the zone.',
    author: 'Steve Fink',
    imageUrl: 'https://cdn.brain.fm/images/testimonials/steve_fink.webp',
    subtitle: '@finksta',
    source: {
      type: ReviewSource.TWITTER,
      verified: true,
    },
  },
  {
    id: 2,
    text: '*@brainfmapp* is one of the best investments I make each year. I use it for several hours a day. 10x better than all the “brain music” playlists on Spotify.',
    author: 'Zac Garside',
    imageUrl: 'https://cdn.brain.fm/images/testimonials/zac_garside.webp',
    subtitle: '@zacgarside',
    source: {
      type: ReviewSource.TWITTER,
      verified: false,
    },
  },
  {
    id: 3,
    text: 'Thank you, guys! Won’t be going anywhere. The app has completely changed my life. No more Spotify, YT music or Calm.',
    author: 'Zac Magee',
    imageUrl: 'https://cdn.brain.fm/images/testimonials/zac_magee.webp',
    subtitle: '@zacjohnm',
    source: {
      type: ReviewSource.TWITTER,
      verified: true,
    },
  },
  {
    id: 4,
    text: 'I use *Brain.fm* for deep work music and have for almost ten years. Highly recommend!',
    imageUrl: 'https://cdn.brain.fm/images/testimonials/carrie_murphy.webp',
    author: 'Carrie Murphy',
    subtitle: 'Writer | Doula | Mother | Content & copywriter for...',
    source: {
      type: ReviewSource.OTHER,
      verified: false,
    },
  },
];

export const ERROR_DISPLAY_VALUES: { [key: number]: string } = {
  409: 'You already have an active subscription.',
};
