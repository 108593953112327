import { CoreAnalytics } from '@Analytics';
import { SignUpForm } from '@Authentication';
import { CredentialResponse } from 'google-one-tap';
import { useCallback, useMemo, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useSearchParams, createSearchParams } from 'react-router-dom';
import * as Yup from 'yup';

import * as authActions from '../../actions/authentication';
import { usePublicTeamData } from '../../api/modules/PublicTeamData';
import { TeamsSignUpForm } from '../../domains/Authentication/components/TeamsSignUpForm';
import { useAnalyticsPageView } from '../../hooks';
import { useInitializeGoogleAuth } from '../../hooks/useInitializeGoogleAuth';
import { RootReducerType } from '../../reducers';
import { FacebookLoginResponse } from '../../types/FacebookLogin';
import { getGAEvent } from '../../utils/analytics/ga';
import { userSliceActions } from '@User';

export type SignUpFormValues = { name: string; email: string; password: string };
export type TeamsSignUpFormValues = {
  name: string;
  domain: string;
  username: string;
  password: string;
};

const validationSchema = Yup.object({
  name: Yup.string().min(1).required(),
  email: Yup.string().email().required(),
  password: Yup.string().min(3).required(),
});

const teamsValidationSchema = Yup.object({
  name: Yup.string().min(1).required(),
  username: Yup.string().min(1).required(),
  domain: Yup.string().min(3).required(),
  password: Yup.string().min(3).required(),
});

export const SignupForm = () => {
  useAnalyticsPageView('sign_up_page_view');

  const [searchParams] = useSearchParams();

  const teamId = searchParams.get('join');
  const isJoinTeamIntention = Boolean(teamId);
  const queryParams = createSearchParams(searchParams).toString();
  const successRedirectUrl = isJoinTeamIntention
    ? `/joined/${searchParams.get('join')}`
    : `/welcome${queryParams ? `?${queryParams}` : ''}`;

  const rid = searchParams.get('rid');

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { signup } = useSelector((state: RootReducerType) => state.auth);
  const {
    data: teamInfo,
    isFetched: isFetchedTeamData,
    isLoading: isLoadingTeamData,
  } = usePublicTeamData(teamId);

  useEffect(() => {
    searchParams.forEach((value, key) => {
      // fpRefId is first promoter reference id
      if (key.startsWith('utm_') || key === 'fpRefId') {
        dispatch(
          userSliceActions.addAttributionData({
            [key]: value,
          }),
        );
      }
    });
  }, [searchParams]);

  useEffect(() => {
    // if no domains are associated with the team, redirect to regular signup page
    const hasNoTeamDomains =
      isJoinTeamIntention && isFetchedTeamData && !(teamInfo?.domains || []).length;
    if (hasNoTeamDomains) {
      navigate('/signup');
    }
  }, [isJoinTeamIntention, isFetchedTeamData, teamInfo, navigate]);

  const handleFacebookSignup = ({ authResponse }: FacebookLoginResponse) => {
    if (authResponse) {
      dispatch(
        authActions.facebookSignupRequest({
          accessToken: authResponse.accessToken,
          userID: authResponse.userID,
          navigate,
          successRedirectUrl,
          rid: rid || undefined,
        }),
      );
    } else {
      CoreAnalytics.trackSignUpError({
        error: 'user cancelled facebook sign up',
        method: 'facebook',
      });
    }
  };

  const handleAppleSignup = async () => {
    CoreAnalytics.trackSignUpAttempt({ method: 'apple' });
    dispatch(
      authActions.appleSignupRequest({ navigate, successRedirectUrl, rid: rid || undefined }),
    );
  };

  const trackGoogleSignUp = useCallback(() => {
    getGAEvent('Signin', 'sign_up_google');
  }, []);

  const handleGoogleSignUp = useCallback((response: CredentialResponse) => {
    if (response.credential) {
      dispatch(
        authActions.googleSignupRequest({
          accessToken: response.credential,
          navigate,
          successRedirectUrl,
          rid: rid || undefined,
        }),
      );
    } else {
      CoreAnalytics.trackSignUpError({
        error: 'Something went wrong during google sign up',
        method: 'google',
      });
    }
  }, []);

  useInitializeGoogleAuth({
    callback: handleGoogleSignUp,
    clickListener: trackGoogleSignUp,
    context: 'signup',
  });

  const teamDomainOptions = useMemo(
    () => (teamInfo?.domains || []).map(domain => ({ value: domain, displayName: `@${domain}` })),
    [teamInfo],
  );

  if (isJoinTeamIntention) {
    return (
      <TeamsSignUpForm<TeamsSignUpFormValues>
        formError={signup.error || null}
        formInitialValues={{
          name: '',
          domain: teamDomainOptions[0]?.value || '',
          username: '',
          password: '',
        }}
        formValidationSchema={teamsValidationSchema}
        isFetchedTeamData={isFetchedTeamData}
        isLoading={signup.inProgress || signup.emailLoading}
        isLoadingTeamData={isLoadingTeamData}
        teamDomains={teamDomainOptions}
        onClickSignIn={() =>
          navigate({
            pathname: '/signin',
            search: createSearchParams(searchParams).toString(),
          })
        }
        onClickSignInAnalyticsEvent={getGAEvent('Signin', 'sign_in')}
        onFormChange={() => dispatch(authActions.clearErrors())}
        onFormSubmit={values => {
          CoreAnalytics.trackSignUpAttempt({ method: 'email' });
          dispatch(
            authActions.emailSignUpRequest({
              values: {
                password: values.password,
                name: values.name,
                email: `${values.username}@${values.domain}`,
              },
              navigate,
              successRedirectUrl,
              rid: rid || undefined,
            }),
          );
        }}
        onFormSubmitAnalyticsEvent={getGAEvent('Signin', 'sign_up_email')}
      />
    );
  }

  return (
    <SignUpForm<SignUpFormValues>
      formError={signup.error || null}
      formInitialValues={{ name: '', email: '', password: '' }}
      formInputs={[
        {
          label: 'Name',
          name: 'name',
          placeholder: '',
          type: 'text',
        },
        {
          label: 'Email',
          name: 'email',
          placeholder: '',
          type: 'email',
        },
        {
          label: 'Password',
          name: 'password',
          placeholder: '',
          type: 'password',
        },
      ]}
      formValidationSchema={validationSchema}
      isLoading={signup.inProgress || signup.emailLoading}
      onClickSignIn={() =>
        navigate({
          pathname: '/signin',
          search: createSearchParams(searchParams).toString(),
        })
      }
      onClickSignInAnalyticsEvent={getGAEvent('Signin', 'sign_in')}
      onClickSignUpWithApple={() => handleAppleSignup()}
      onClickSignUpWithAppleAnalyticsEvent={getGAEvent('Signin', 'sign_up_apple')}
      onClickSignUpWithFacebook={() => {
        FB.login(handleFacebookSignup, { scope: 'public_profile,email' });
        CoreAnalytics.trackSignUpAttempt({ method: 'facebook' });
      }}
      onClickSignUpWithFacebookAnalyticsEvent={getGAEvent('Signin', 'sign_up_facebook')}
      onFormChange={() => dispatch(authActions.clearErrors())}
      onFormSubmit={values => {
        CoreAnalytics.trackSignUpAttempt({ method: 'email' });
        dispatch(
          authActions.emailSignUpRequest({
            values,
            navigate,
            successRedirectUrl,
            rid: rid || undefined,
          }),
        );
      }}
      onFormSubmitAnalyticsEvent={getGAEvent('Signin', 'sign_up_email')}
    />
  );
};
