import { TextBold } from '@Cortex';
import styled from 'styled-components';

export const CardsContainer = styled.div`
  margin-bottom: 2rem;
`;

export const CardContainer = styled.div`
  margin-bottom: 0.5rem;
  margin-right: 0.25rem;
`;

export const ShowAllTracksButton = styled.button`
  display: flex;
  padding: 10px 0px;
  justify-content: center;
  align-items: center;
  align-self: stretch;
  border-radius: 90px;
  background: rgba(255, 255, 255, 0.1);
  cursor: pointer;
  margin-top: 1rem;
  border: none;
  width: 100%;
  color: #fff;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: 20px;
  transition: background 0.3s ease;

  &:hover {
    background: rgba(255, 255, 255, 0.2);
  }
`;

export const Loading = styled.div`
  display: flex;
  height: 50%;
  width: 100%;
  justify-content: center;
  align-items: center;

  &:after {
    animation: rotation 0.8s linear infinite;
    border-radius: 100%;
    border-right: 3px solid transparent;
    border-top: 3px solid rgba(255, 255, 255, 0.8);
    content: '';
    height: 2rem;
    position: absolute;
    width: 2rem;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const Title = styled(TextBold)`
  font-size: 1.25rem;
  text-transform: capitalize;
  margin-bottom: 0.75rem;
`;
