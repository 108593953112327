import { Icon, MarqueeText } from '@Cortex';

import LoaderIcon from '../../../../assets/images/loader.svg';
import { ActivityDataSingle } from '../../../../types';
import { Assets } from '../../../../utils/assets';
import * as S from './ActivitySelector.styles';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../reducers';

export interface Props {
  isOpen: boolean;
  selectedActivity: ActivityDataSingle['display'];
  onClick: () => void;
}

export const ActivitySelectorDisplay = ({ isOpen, selectedActivity, onClick }: Props) => {
  const sessionStatus = useSelector((state: RootReducerType) => state.sessionManager.sessionStatus);
  const isSessionLoading = sessionStatus === 'loading';

  return (
    <S.Panel>
      <S.Wrapper data-testid="activitySelector" onClick={onClick}>
        <S.Skeleton isLoading={isSessionLoading}>
          <S.Selection>
            <S.Header>Activity:</S.Header>
            <S.TextContainer style={{ opacity: isSessionLoading ? 0 : 1 }}>
              <MarqueeText key={selectedActivity}>
                <S.Text data-testid="selectedActivity">{selectedActivity}</S.Text>
              </MarqueeText>
            </S.TextContainer>
          </S.Selection>

          {isSessionLoading ? (
            <S.LoaderIconContainer>
              <Icon size={20} src={LoaderIcon} />
            </S.LoaderIconContainer>
          ) : (
            <S.ChevronIconContainer>
              <Icon size={20} src={Assets.icons.dropdownArrow.url} />
            </S.ChevronIconContainer>
          )}
        </S.Skeleton>
      </S.Wrapper>
    </S.Panel>
  );
};
