import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Label = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-style: normal;
  font-size: 0.75rem;
  align-items: center;
  color: #d4d2ea;
  margin-bottom: 0.5rem;
  text-transform: uppercase;
  letter-spacing: 0.1rem;
`;

export const Text = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;

  align-items: center;
  color: #ffffff;
  max-width: 100%;
  text-overflow: ellipsis;
  overflow: hidden;
`;
export const ValueWrapper = styled.div`
  display: flex;
  gap: 10px;
`;

export const Icon = styled.img`
  height: 100%;
  width: 20px;
  object-fit: contain;
`;
