export function getGenrePreferenceDescription(genres: string[]): string {
  if (genres.length <= 2 && (genres.includes('Electronic') || genres.includes('Atmospheric'))) {
    return 'Ambient Soundscapes';
  }
  if (genres.includes('Electronic')) return 'Electronic, Lofi, and Grooves';
  if (genres.includes('Atmospheric')) return 'Atmospheric, Drone, Post-Rock, Cinematic';
  if (genres.includes('Acoustic')) return 'Acoustic, Piano, Classical';
  if (genres.includes('Rain')) return 'Rain, Beach, Thunder, Forest, and more!';
  return 'Electronic, Lofi, and Grooves';
}
