import styled, { css } from 'styled-components';

import { ButtonVariantSizes, VariantStates } from './Button';

type ButtonProps = {
  backgroundColor?: string;
  isFullWidth?: boolean;
  variantState?: VariantStates;
  size?: ButtonVariantSizes;
  keepTextCase: boolean;
};

const SharedButtonStyles = styled.button<ButtonProps>`
  background: none;
  border: none;
  color: #ffffff;
  cursor: pointer;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 0.875rem;
  text-transform: ${({ keepTextCase }) => (keepTextCase ? 'unset' : 'lowercase')};
  outline: none;

  &:disabled {
    cursor: not-allowed;
  }

  ${props => (props.isFullWidth ? 'width: 100%;' : null)}
`;

export const BaseButton = styled(SharedButtonStyles)<ButtonProps>`
  align-items: center;
  border-radius: 100px;
  color: #fff;
  display: flex;
  justify-content: center;
  padding: 0.7rem 2rem;
  transition:
    background 0.2s ease-in-out,
    color 0.15s ease-in-out;

  ${props => (props.backgroundColor ? `background-color: ${props.backgroundColor};` : null)}

  &:hover {
    background: rgba(255, 255, 255, 0.05);
  }
`;

export const PrimaryButton = styled(SharedButtonStyles)<ButtonProps>`
  align-items: center;
  border-radius: 100px;
  display: flex;
  font-size: 1rem;
  flex-direction: column;
  justify-content: center;
  transition:
    box-shadow 0.2s linear,
    background 0.3s ease-in-out,
    color 0.15s ease-in-out,
    opacity 0.15s ease-in-out;

  background: ${props => {
    switch (props.variantState) {
      case VariantStates.Success:
        return '#4CD867';
      case VariantStates.Error:
        return 'red';
      default:
        return 'linear-gradient(100.23deg, #4915f8 -26.81%, #ff496b 134.77%)';
    }
  }};

  padding: ${({ size }) => {
    switch (size) {
      case ButtonVariantSizes.XSmall:
        return '0.25rem 0.75rem';
      case ButtonVariantSizes.Small:
        return '0.65rem 1.5rem';
      case ButtonVariantSizes.Regular:
        return '1.125rem 3rem';
      default:
        return '1.125rem 3rem';
    }
  }};

  &:hover:enabled,
  &:focus:enabled {
    box-shadow:
      0px 2.54251px 21.44px rgba(147, 35, 102, 0.7),
      inset 0px 0px 6.35627px rgba(255, 220, 220, 0.65);
  }

  &:disabled {
    color: ${({ variantState }) =>
      variantState === VariantStates.Loading ? 'transparent' : '#ffffff'};
    background: #c0c0c0;
    opacity: 0.7;
  }

  ${({ variantState }) => {
    return variantState === VariantStates.Loading
      ? css`
          &:after {
            animation: rotation 0.8s linear infinite;
            border-radius: 100%;
            border-right: 3px solid transparent;
            border-top: 3px solid rgba(255, 255, 255, 0.8);
            content: '';
            height: 2rem;
            position: absolute;
            width: 2rem;

            @keyframes rotation {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          }
        `
      : null;
  }}
`;

export const SecondaryButton = styled(SharedButtonStyles)<ButtonProps>`
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: #fff;
  border-radius: 100px;
  border: 1px solid #fff;
  box-shadow: 0 2.5px 11.5px rgba(147, 35, 102, 0.4);
  transition:
    background 0.2s ease-in-out,
    color 0.15s ease-in-out;
  width: ${({ isFullWidth }) => (isFullWidth ? '100%' : `fit-content`)};
  font-size: 1rem;

  color: ${props => {
    switch (props.variantState) {
      case VariantStates.Error:
        return 'white';
      default:
        return '#191736';
    }
  }};

  padding: ${({ size }) => {
    switch (size) {
      case ButtonVariantSizes.XSmall:
        return 'calc(0.25rem - 2px) calc(0.75rem - 2px)';
      case ButtonVariantSizes.Small:
        return 'calc(0.65rem - 2px) calc(1.5rem - 2px)';
      case ButtonVariantSizes.Regular:
        return 'calc(1.125rem - 2px) calc(3rem - 2px)';
      default:
        return 'calc(1.125rem - 2px) calc(3rem - 2px)';
    }
  }};

  &:disabled {
    color: ${({ variantState }) =>
      variantState === VariantStates.Loading ? 'transparent' : '#ffffff'};
    background: #c0c0c0;
    opacity: 0.7;
    border-color: #c0c0c0;
  }

  ${({ variantState }) => {
    return variantState === VariantStates.Loading
      ? css`
          &:after {
            animation: rotation 0.8s linear infinite;
            border-radius: 100%;
            border-right: 3px solid transparent;
            border-top: 3px solid rgba(255, 255, 255, 0.8);
            content: '';
            height: 2rem;
            position: absolute;
            width: 2rem;

            @keyframes rotation {
              from {
                transform: rotate(0deg);
              }
              to {
                transform: rotate(360deg);
              }
            }
          }
        `
      : null;
  }}
`;

export const TertiaryButton = styled(SharedButtonStyles)<ButtonProps>`
  justify-content: center;
  background: rgba(255, 255, 255, 0.05);
  border-radius: 100px;
  border: 1px solid #fff;
  color: #191736;
  display: flex;
  font-size: 1rem;
  padding: 0.7rem 2rem;
  transition:
    background 0.2s ease-in-out,
    color 0.15s ease-in-out;

  :hover {
    background: transparent;
    color: #fff;
  }
`;

export const OutlineButton = styled(SharedButtonStyles)<ButtonProps>`
  justify-content: center;
  background: transparent;
  border-radius: 100px;
  border: 1px solid #fff;
  color: #fff;
  display: flex;
  font-size: 1rem;
  transition:
    background 0.2s ease-in-out,
    color 0.15s ease-in-out;

  padding: ${({ size }) => {
    switch (size) {
      case ButtonVariantSizes.XSmall:
        return 'calc(0.25rem - 2px) calc(0.75rem - 2px)';
      case ButtonVariantSizes.Small:
        return 'calc(0.65rem - 2px) calc(1.5rem - 2px)';
      case ButtonVariantSizes.Regular:
        return 'calc(1.125rem - 2px) calc(3rem - 2px)';
      default:
        return 'calc(1.125rem - 2px) calc(3rem - 2px)';
    }
  }};

  :hover {
    background: #fff;
    color: #191736;
  }
`;

export const DestructiveButton = styled(SharedButtonStyles)<ButtonProps>`
  justify-content: center;
  background: rgba(255, 73, 107, 0.1);
  border-radius: 12px;
  color: #ff4949;
  display: flex;
  font-size: 1rem;
  padding: 1rem;
`;

export const TransparentButton = styled(SharedButtonStyles)<ButtonProps>`
  justify-content: center;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100px;
  color: #fff;
  display: flex;
  font-size: 1rem;
  padding: ${({ size }) => {
    switch (size) {
      case ButtonVariantSizes.XSmall:
        return '0.25rem 0.75rem';
      case ButtonVariantSizes.Small:
        return '0.65rem 1.5rem';
      case ButtonVariantSizes.Regular:
        return 'calc(1.125rem - 2px) calc(3rem - 2px)';
      default:
        return '1.125rem 3rem';
    }
  }};
`;

export const Image = styled.img`
  width: 20px;
  height: 20px;
  margin-right: 12px;
`;
