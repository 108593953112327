import React from 'react';
import * as S from './GenreToggle.styles';

type Props = {
  onClick: (option: GenreToggleOption) => void;
  option: GenreToggleOption;
  selectedGenresCount: number;
};

export enum GenreToggleOption {
  Presets = 'presets',
  Custom = 'custom',
}

export const GenreToggle = ({ option, selectedGenresCount, onClick }: Props) => {
  return (
    <S.ToggleContainer option={option}>
      <S.PresetsOption option={option} onClick={() => onClick(GenreToggleOption.Presets)}>
        Presets
      </S.PresetsOption>
      <S.CustomOption option={option} onClick={() => onClick(GenreToggleOption.Custom)}>
        Custom
      </S.CustomOption>
      <S.ToggleKnob option={option} />
    </S.ToggleContainer>
  );
};
