import { LoadingText } from '@Cortex';
import React from 'react';
import { Assets } from '../../../../../../utils/assets';

import * as S from './MembershipPanelWrapper.styles';

export type Props = {
  children?: React.ReactNode;
  iconSrc?: string;
  isLoading?: boolean;
  subtitle: string;
  tagTitle?: string | null;
  isExpired?: boolean;
  title: string;
};

export function MembershipPanelWrapper({
  children,
  isLoading = false,
  subtitle,
  title,
  isExpired = false,
}: Props) {
  return (
    <S.Container>
      <S.Header>
        <S.HeaderText>
          <S.HeaderTextTitle>{isLoading ? <LoadingText width="100%" /> : title}</S.HeaderTextTitle>
          {isExpired && (
            <S.Expired>
              <S.ExpiredIcon
                alt={Assets.icons.warningWhite.alt}
                src={Assets.icons.warningWhite.url}
              />
              <S.ExpiredText>EXPIRED</S.ExpiredText>{' '}
            </S.Expired>
          )}
        </S.HeaderText>
        {subtitle ? <S.HeaderTextSubtitle>{subtitle}</S.HeaderTextSubtitle> : null}
      </S.Header>
      <S.Divider />
      <S.Children>{isLoading ? <LoadingText /> : children}</S.Children>
    </S.Container>
  );
}
