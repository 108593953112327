import * as S from './Welcome.styles';
import { Button, ButtonVariants } from '@Cortex';
import { Assets } from '../../../utils/assets';
import ReactModal from 'react-modal';
import { HeaderWithLogoAndClose } from '../../shared/HeaderWithLogoAndClose';
import { getSessionHeaderTitle } from '../OnboardingResult/helpers/getSessionHeaderTitle';
import { getSessionListenTitle } from '../OnboardingResult/helpers/getSessionListenTitle';
import { getSessionListenDescription } from '../OnboardingResult/helpers/getSessionListenDescription';
import { getSessionTimeDescription } from '../OnboardingResult/helpers/getSessionTimeDescription';
import { getSessionTypeTitle } from '../OnboardingResult/helpers/getSessionTypeTitle';
import { getSessionTypeDescription } from '../OnboardingResult/helpers/getSessionTypeDescription';
import { getSessionTypeImage } from '../OnboardingResult/helpers/getSessionTypeImage';
import { getSessionTimeImage } from '../OnboardingResult/helpers/getSessionTimeImage';

export type Props = {
  isOpen: boolean;
  isPaymentSaved: boolean;
  mentalStateId: string | null;
  onClose: () => void;
};

export const WelcomeModalDisplay = ({ isOpen, isPaymentSaved, mentalStateId, onClose }: Props) => {
  return (
    <ReactModal isOpen={isOpen} style={{ overlay: { zIndex: 10000 } }}>
      <S.Container>
        <S.BackgroundBlur src={Assets.images.firstActivityModal.blur.url} />
        <HeaderWithLogoAndClose padding={'2rem 0'} />

        <WelcomeScreen mentalStateId={mentalStateId} onClose={onClose} />

        {isPaymentSaved && (
          <S.PaymentContainer>
            <S.CheckIcon
              alt={Assets.icons.greenCircleCheck.alt}
              src={Assets.icons.greenCircleCheck.url}
            />
            <S.PaymentText>Your payment information was saved.</S.PaymentText>
          </S.PaymentContainer>
        )}
      </S.Container>
    </ReactModal>
  );
};

export function WelcomeScreen(props: { onClose: () => void; mentalStateId: string | null }) {
  const sessionHeaderTitle = getSessionHeaderTitle(props.mentalStateId);
  const sessionListenTitle = getSessionListenTitle(props.mentalStateId);
  const sessionListenDescription = getSessionListenDescription(props.mentalStateId);
  const sessionTimeDescription = getSessionTimeDescription(props.mentalStateId);
  const sessionTypeTitle = getSessionTypeTitle(props.mentalStateId);
  const sessionTypeDescription = getSessionTypeDescription(props.mentalStateId);
  const sessionTimeImage = getSessionTimeImage(props.mentalStateId);
  const sessionTypeImage = getSessionTypeImage(props.mentalStateId);

  return (
    <>
      <S.HeaderText>{sessionHeaderTitle}</S.HeaderText>
      <S.HeaderSubtext>To get the most out of Brain.fm, follow these quick tips.</S.HeaderSubtext>

      <S.TipsContainer>
        <S.TipContainer>
          <S.TipContainerHeader>STEP 1</S.TipContainerHeader>
          <S.TipContainerHeaderSubtext>Time Matters</S.TipContainerHeaderSubtext>
          <S.StepImage alt={sessionTimeImage.alt} src={sessionTimeImage.url} />
          <S.Description>{sessionTimeDescription}</S.Description>
        </S.TipContainer>
        <S.Divider />

        <S.TipContainer>
          <S.TipContainerHeader>STEP 2</S.TipContainerHeader>
          <S.TipContainerHeaderSubtext>{sessionListenTitle}</S.TipContainerHeaderSubtext>
          <S.StepImage
            alt={Assets.images.headphoneEarbud.alt}
            src={Assets.images.headphoneEarbud.url}
          />
          <S.Description>{sessionListenDescription}</S.Description>
        </S.TipContainer>

        <S.Divider />

        <S.TipContainer>
          <S.TipContainerHeader>STEP 3</S.TipContainerHeader>
          <S.TipContainerHeaderSubtext>{sessionTypeTitle}</S.TipContainerHeaderSubtext>
          <S.StepImage alt={sessionTypeImage.alt} src={sessionTypeImage.url} />
          <S.Description>{sessionTypeDescription}</S.Description>
        </S.TipContainer>
      </S.TipsContainer>

      <S.ButtonContainer>
        <Button isFullWidth keepTextCase variant={ButtonVariants.Secondary} onClick={props.onClose}>
          START LISTENING
        </Button>
      </S.ButtonContainer>
    </>
  );
}
