import styled from 'styled-components';

export const Container = styled.div`
  align-items: center;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0.1) 0%, rgba(255, 255, 255, 0.01) 100%),
    rgb(0, 0, 0);
  border-bottom: 1px solid #3d3d3d;
  color: #fff;
  display: flex;
  flex-shrink: 0;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  height: 4.25rem;
  padding: 1rem;
  width: 100%;
  z-index: 100;
  position: relative;
  overflow: hidden;
`;
