import { Button, ButtonVariants } from '@Cortex';
import React from 'react';
import { Assets } from '../../../../utils/assets';
import * as S from './MilestonesWidget.styles';
import { MilestonesState } from 'src/reducers/milestones';

type Props = {
  firstActivity: MilestonesState['items']['firstActivity'];
  uninterruptedListening: MilestonesState['items']['uninterruptedListening'];
  favorites: MilestonesState['items']['favorites'];
  isModalOpen: boolean;
  modalData: MilestonesState['progressModal']['data'];
  onCloseModal: () => void;
};

export const MilestonesModalIcons = {
  stepsIcon: Assets.icons.steps.url,
};

export const MilestoneWidgetPresentation = (props: Props) => {
  const { firstActivity, uninterruptedListening, favorites, isModalOpen, modalData, onCloseModal } =
    props;

  return (
    <>
      {isModalOpen && <S.Overlay />}
      <S.Wrapper>
        {isModalOpen && <S.BgImg alt="" src={Assets.images.milestonesCirclesOfBlur.url} />}
        <S.WidgetContainer>
          <S.Title>GETTING STARTED</S.Title>
          <S.MilestoneList>
            <S.MilestoneItem completed={firstActivity.completed}>
              Start personalized activity
            </S.MilestoneItem>

            <S.MilestoneItem completed={uninterruptedListening.completed}>
              Listen for {uninterruptedListening.targetMinutes} minutes
            </S.MilestoneItem>

            <S.MilestoneItem completed={favorites.completed}>Favorite 3 tracks</S.MilestoneItem>
          </S.MilestoneList>
        </S.WidgetContainer>
        {isModalOpen && (
          <S.MilestoneModalContainer>
            {modalData.icon && (
              <S.MilestoneIcon>
                <img alt="" src={MilestonesModalIcons[modalData.icon]} />
              </S.MilestoneIcon>
            )}
            <S.MilestoneModalTitle>{modalData.title}</S.MilestoneModalTitle>
            <S.MilestoneModalDescription>{modalData.description}</S.MilestoneModalDescription>
            <Button
              data-testid="timerApply"
              isFullWidth
              keepTextCase
              style={{ fontSize: '0.75rem', height: '48px' }}
              variant={ButtonVariants.Secondary}
              onClick={onCloseModal}
            >
              {modalData.buttonText}
            </Button>
          </S.MilestoneModalContainer>
        )}
      </S.Wrapper>
    </>
  );
};
