import styled from 'styled-components';

export const Container = styled.div<{ isMobileView: boolean }>`
  width: 100%;
  height: ${({ isMobileView }) => (isMobileView ? '48px' : '170px')};
  border-radius: 8px;
  border: 1px solid;
  border-color: ${({ isMobileView }) => (isMobileView ? 'rgba(255, 255, 255, 0.20)' : '#3D3D3D')};
  background: #0d0c11;
  overflow: hidden;

  @media (max-width: 430px) {
    border-radius: 0px;
    border: none;
  }
`;
