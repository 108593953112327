import { CoreAnalytics } from '@Analytics';
import { useCallback, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';

import { OnboardingSpecification, OnboardingSteps } from '../../types';
import { OnboardingCardsPresentation } from './OnboardingCards.presentation';
import { Analytics } from '../../../../utils/analytics';
import { OnboardingEvents } from '../../../../utils/analytics/events';
import { OnboardingOrigins } from '../../../../domains/Analytics/coreAnalytics.types';

type Props = {
  isLoading: boolean;
  onboardingSpecification: OnboardingSpecification;
  onSetStep: (step: OnboardingSteps) => void;
  onCompleteStep: (neurotype_id?: string) => void;
  onSkip: () => void;
};

export const OnboardingCards = (props: Props) => {
  const { isLoading, onboardingSpecification, onSetStep, onCompleteStep, onSkip } = props;
  const { cards, quizSpecification, id } = onboardingSpecification || {};
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [mountedCardIndex, setMountedCardIndex] = useState(0);
  const { info } = useSelector((state: RootReducerType) => state.user);
  const cardsAmount = cards.length;

  useEffect(() => {
    CoreAnalytics.trackOnboardingInitiateEvent({
      variation: onboardingSpecification.id,
      origin: getAnalyticsOriginFromAppOrigin(origin),
    });

    Analytics.logEventWithProperties(OnboardingEvents.onboarding_initiate, {
      onboarding_variation: onboardingSpecification.id,
      origin: origin || '',
    });
  }, []);

  useEffect(() => {
    if (isLoading || cards.length) return;
    onSetStep(OnboardingSteps.Quiz);
  }, [isLoading, cards, onSetStep]);

  const handleMountNextCard = useCallback(() => {
    if (mountedCardIndex + 1 >= cardsAmount) return;
    setMountedCardIndex(mountedCardIndex + 1);
  }, [cardsAmount, setMountedCardIndex, mountedCardIndex]);

  const handleNextCard = useCallback(() => {
    if (currentCardIndex + 1 >= cardsAmount) {
      if (!(quizSpecification?.questions || []).length) {
        onCompleteStep();
      }
      onSetStep(OnboardingSteps.Quiz);

      return;
    }

    setCurrentCardIndex(currentCardIndex + 1);
  }, [setCurrentCardIndex, currentCardIndex, cardsAmount, onCompleteStep]);

  const handleSkipCard = () => {
    CoreAnalytics.trackOnboardingSkipEvent({
      variation: id,
      cardIndex: currentCardIndex,
      cardTitle: cards[currentCardIndex]?.title || '',
      module: 'info_card',
    });
    handleNextCard();
  };

  const handleSkipOnboarding = () => {
    CoreAnalytics.trackOnboardingSkipEvent({
      variation: id,
      cardIndex: currentCardIndex,
      cardTitle: cards[currentCardIndex]?.title || '',
      module: 'info_card',
    });
    onSkip();
  };

  const numberOfSteps = cards.length + (quizSpecification?.questions || []).length;
  return (
    <OnboardingCardsPresentation
      cards={cards}
      currentCardIndex={currentCardIndex}
      isLoading={isLoading}
      mountedCardIndex={mountedCardIndex}
      numberOfSteps={numberOfSteps}
      userFirstName={info?.firstName || null}
      onHandleMountNextCard={handleMountNextCard}
      onHandleNextCard={handleNextCard}
      onHandleSkipCard={handleSkipCard}
      onHandleSkipOnboarding={handleSkipOnboarding}
    />
  );
};

function getAnalyticsOriginFromAppOrigin(origin: string): OnboardingOrigins {
  if (origin === 'settings') return 'settings';
  if (['emailSignup', 'facebookSignup', 'appleSignup'].includes(origin)) return 'sign_up';
  return 'direct';
}
