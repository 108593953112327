import * as Braze from '@braze/web-sdk';
import { useCallback, useEffect } from 'react';
import Amplitude from 'amplitude-js';
import ReactPixel from 'react-facebook-pixel';
import { useLocation } from 'react-router-dom';

import { Analytics } from '../../utils/analytics';
import { Events } from '../../utils/analytics/events';
import { Logger } from '../../utils/logger';

export function useTrackPageView() {
  const location = useLocation();

  useEffect(() => {
    ReactPixel.pageView();
  }, [location.pathname]);
}

export function useTrackPwaInstall() {
  const trackPwaInstall = useCallback(() => {
    Analytics.logEventWithProperties(Events.pwa_app_install, {});
  }, []);

  useEffect(() => {
    window.addEventListener('appinstalled', trackPwaInstall);
    return () => window.removeEventListener('appinstalled', trackPwaInstall);
  }, []);
}

export async function trackSignUp(params: { fbEventId?: string; userId?: string } = {}) {
  try {
    // 'signUp' event is deprecated, remove on february 17, 2023
    trackGoogleTagManagerEvent({
      event: 'signUp',
      transactionId: params.userId,
    });
    trackGoogleTagManagerEvent({
      event: 'createdAccount',
      transactionId: params.userId,
    });

    ReactPixel.track('CompleteRegistration', {
      eventID: params.fbEventId,
      external_id: await getSHA256Hash(params.userId),
    });
  } catch (e) {
    Logger.error(new Error('useTrackSignUp(): unable to track analytics sign in'), { reason: e });
  }
}

export async function trackPurchase(
  params: {
    fbEventId?: string;
    now?: number;
    userId?: string;
    value?: number;
    planName?: string;
  } = {},
) {
  try {
    // 'purchase' event is deprecated, remove on february 17, 2023
    trackGoogleTagManagerEvent({
      event: 'purchase',
      transactionId: `${params.userId}__${params.now || Date.now()}`,
      conversionValue: params.value,
    });
    trackGoogleTagManagerEvent({
      event: 'startedNewSubscription',
      transactionId: `${params.userId}__${params.now || Date.now()}`,
      conversionValue: params.value,
    });
    Braze.logCustomEvent(`purchase__${params.planName}`);

    ReactPixel.track('Purchase', {
      eventID: params.fbEventId,
      external_id: await getSHA256Hash(params.userId),
      value: 24, // hard coded per kevin
      currency: 'USD',
    });
  } catch (e) {
    Logger.error(new Error('useTrackSignUp(): unable to track analytics purchase'), { reason: e });
  }
}

function trackGoogleTagManagerEvent(params: {
  event: 'createdAccount' | 'purchase' | 'signUp' | 'startedNewSubscription';
  transactionId?: string;
  conversionValue?: number;
}): void {
  (window as unknown as WindowWithGoogleTagManager).dataLayer.push(params);
}

export function trackSignInWithMagicLink(params: { campaignName: string }) {
  try {
    Braze.logCustomEvent(`sign_in_with_magic_link__${params.campaignName}`);
  } catch (e) {
    Logger.error(new Error('trackSignInWithMagicLink(): unable to track via braze'), { reason: e });
  }
}

export function trackListeningMilestone(params: { milestone: number }) {
  const eventName = `lifecycle__${params.milestone}_minutes_listened`;

  try {
    Amplitude.getInstance().logEvent(eventName);
  } catch (e) {
    Logger.error(new Error('trackListeningMilestone(): unable to track via amplitude'), {
      reason: e,
    });
  }

  try {
    Braze.logCustomEvent(eventName);
  } catch (e) {
    Logger.error(new Error('trackListeningMilestone(): unable to track via braze'), { reason: e });
  }
}

type WindowWithGoogleTagManager = {
  dataLayer: Datalayer;
};

type Datalayer = {
  push: (event: { event: string; transactionId?: string; conversionValue?: number }) => boolean;
};

export async function getSHA256Hash(input?: string | null): Promise<string> {
  if (!input) return '';

  try {
    const textAsBuffer = new TextEncoder().encode(input);
    const hashBuffer = await window.crypto.subtle.digest('SHA-256', textAsBuffer);
    const hashArray = Array.from(new Uint8Array(hashBuffer));
    const hash = hashArray.map(item => item.toString(16).padStart(2, '0')).join('');
    return hash;
  } catch (error) {
    return input;
  }
}
