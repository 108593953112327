import { FontColors, Icon, Text } from '@Cortex';
import { Assets } from '../../../../../../../../utils/assets';

import * as S from './Checkmark.styles';

interface Props {
  children: string;
  customIcon?: string;
}

export const Checkmark = ({ children, customIcon }: Props) => {
  return (
    <S.Container>
      {customIcon ? (
        <S.Icon>
          <Icon isSquare size={24} src={customIcon} />
        </S.Icon>
      ) : (
        <S.IconContainer>
          <S.Icon>
            <Icon isSquare size={24} src={Assets.icons.checkmark.url} />
          </S.Icon>
        </S.IconContainer>
      )}
      <S.TextContainer>
        <Text color={FontColors.WhiteSecondary} size="1rem">
          {children}
        </Text>
      </S.TextContainer>
    </S.Container>
  );
};
