import { pluralize } from '@Utils';

type Props = {
  isExpired: boolean;
  timeLeftInDays: number;
  timeLeftInHours: number;
};

export const getSubtitle = ({ isExpired, timeLeftInHours, timeLeftInDays }: Props) => {
  if (isExpired) {
    return 'Oh no! It looks like you’re out of trial days.';
  }

  return timeLeftInDays
    ? `${pluralize('days', timeLeftInDays)} remaining`
    : `${pluralize('hours', timeLeftInHours)} remaining`;
};
