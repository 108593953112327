import { Button, ButtonVariants, ButtonVariantSizes } from '@Cortex';
import { WidgetBody } from './components/WidgetBody';
import * as S from './MembershipWidget.styles';
import iIcon from './assets/i_icon.svg';

export type Props = {
  buttonText: string;
  isTrial: boolean;
  isExpired: boolean;
  isInGracePeriod?: boolean | null;
  timeLeftInDays: number;
  testid?: string;
  timeLeftInHours: number;
  onClickButton: () => void;
};

export const MembershipWidgetPresentation = (props: Props) => {
  const {
    buttonText,
    isTrial,
    isExpired,
    isInGracePeriod,
    timeLeftInDays,
    timeLeftInHours,
    testid,
    onClickButton,
  } = props;

  if (!isTrial && !isExpired) return null;

  return (
    <S.CallToActionWidget>
      <S.Body data-testid="membershipWidgetBody">
        <S.Image src={iIcon} />
        <WidgetBody
          isExpired={isExpired}
          isInGracePeriod={isInGracePeriod}
          isTrial={isTrial}
          timeLeftInDays={timeLeftInDays}
          timeLeftInHours={timeLeftInHours}
        />
      </S.Body>
      <Button
        data-testid={testid}
        keepTextCase={true}
        size={ButtonVariantSizes.Small}
        style={{ fontSize: '0.75rem', letterSpacing: '1.2px' }}
        variant={ButtonVariants.Secondary}
        onClick={onClickButton}
      >
        {buttonText}
      </Button>
    </S.CallToActionWidget>
  );
};
