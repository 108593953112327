import { AdhdSwitchDisplay } from './AdhdSwitch.display';

type AdhdSwitchProps = {
  isActive: boolean;
  onClick: () => void;
};

export const AdhdSwitch = ({ isActive, onClick }: AdhdSwitchProps) => {
  return <AdhdSwitchDisplay isActive={isActive} onClick={onClick} />;
};
