import { DynamicActivity, EffectLevels } from '@Model';
import { NeuralEffectLevelIcon, getTrackName } from '@Music';
import { SyntheticEvent } from 'react';
import { useSelector } from 'react-redux';
import Lottie, { LottieProps } from 'react-lottie';

import { MultiNelIcon } from '../MultiNelIcon';
import * as S from './TrackInformationCard.styles';
import { HeartIcon } from './components/HeartIcon';
import { FALLBACK_TRACK_IMAGE } from '@Globals';

import { RootReducerType } from '../../../../reducers';
import soundWave from '../../../../assets/lottie/sound_wave.json';
import { MarqueeText } from '@Cortex';
import { MoreInfo } from './components/MoreInfo';

const lottieOptions: LottieProps['options'] = {
  loop: true,
  autoplay: true,
  animationData: soundWave,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
};

export type Props = {
  favoritesButtonTestId?: string;
  imageUrl: string;
  hasMultipleNELs?: boolean;
  isFavorited?: boolean;
  isNewlyCreated?: boolean;
  neuralEffectLevel: EffectLevels;
  relatedActivities?: DynamicActivity[];
  onAddToFavorites?: () => void;
  onClick?: () => void;
  onClickMore?: () => void;
  onRemoveFromFavorites?: () => void;
  subtitle: string;
  title: string;
  moreIconTestId?: string;
  isInSideDeck?: boolean;
};

export function TrackInformationCard(props: Props) {
  const currentTrack = useSelector((state: RootReducerType) => state.music.currentTrack);
  const isSideDeckOpen = useSelector((state: RootReducerType) => state.ui.sideDeck.isOpen);
  const currentTrackName = getTrackName(currentTrack);
  // Block marquee animation from starting when side deck is closed
  const marqueeAnimationBlocked = props.isInSideDeck ? !isSideDeckOpen : false;
  return (
    <S.Container data-testid="trackInformationCard" onClick={createHandleClick(props.onClick)}>
      <S.InnerContainer>
        <S.Image
          isClickable={Boolean(props.onClick)}
          src={props.imageUrl}
          onClick={createHandleClick(props.onClick)}
          onError={evt => {
            evt.currentTarget.src = FALLBACK_TRACK_IMAGE;
          }}
        />
        {currentTrackName === props.title ? (
          <S.LottieContainer>
            <Lottie height={24} options={lottieOptions} width={24} />
          </S.LottieContainer>
        ) : null}

        <S.ContentContainer>
          <S.InformationContainer>
            <S.TitleContainer>
              <MarqueeText key={props.title} animationBlocked={marqueeAnimationBlocked}>
                <S.Title data-testid="trackTitle">
                  {props.title}{' '}
                  {props.isNewlyCreated && <S.TrackLabel data-testid="newLabel">new</S.TrackLabel>}
                </S.Title>
              </MarqueeText>
            </S.TitleContainer>
            <MarqueeText key={props.subtitle} animationBlocked={marqueeAnimationBlocked}>
              <S.Subtitle data-testid="trackSubtitle">{props.subtitle}</S.Subtitle>
            </MarqueeText>
            {props.relatedActivities && props?.relatedActivities.length > 0 && (
              <S.Activities color={props.relatedActivities[0].mentalState.primaryColor}>
                <S.MainActivity color={props.relatedActivities[0].mentalState.primaryColor}>
                  {props.relatedActivities[0].displayValue}
                </S.MainActivity>
                {props.relatedActivities.length > 1
                  ? `+${props.relatedActivities.length - 1} more`
                  : ''}
              </S.Activities>
            )}
          </S.InformationContainer>
          <S.TrackActions>
            <S.TrackActionButton>
              <S.NeuralEffectLevelIcon>
                {props.hasMultipleNELs ? (
                  <MultiNelIcon />
                ) : (
                  <NeuralEffectLevelIcon effectLevel={props.neuralEffectLevel} />
                )}
              </S.NeuralEffectLevelIcon>
            </S.TrackActionButton>
            <S.TrackActionButton
              data-cy="addToFavoritesButton"
              data-testid={props.favoritesButtonTestId ?? 'addToFavoritesButton'}
              onClick={createHandleClick(
                props.isFavorited ? props.onRemoveFromFavorites : props.onAddToFavorites,
              )}
            >
              <HeartIcon fillOpacity={props.isFavorited ? 1 : 0} />
            </S.TrackActionButton>
            <S.TrackActionButton
              data-testid={props.moreIconTestId ?? 'moreIcon'}
              onClick={createHandleClick(props.onClickMore)}
            >
              <MoreInfo />
            </S.TrackActionButton>
          </S.TrackActions>
        </S.ContentContainer>
      </S.InnerContainer>
    </S.Container>
  );
}

function createHandleClick(onClick?: () => void): (event: SyntheticEvent) => void {
  return function handleClick(event: SyntheticEvent): void {
    if (onClick) {
      event.preventDefault();
      event.stopPropagation();
      onClick();
    }
  };
}
