import { CoreAnalytics } from '@Analytics';
import { ButtonVariantStates } from '@Cortex';
import { REFER_A_FRIEND_LINK, REFER_A_FRIEND_MARKETING_LINK } from '@Globals';
import { forwardRef, useEffect, useState } from 'react';

import { Analytics } from '../../../utils/analytics';
import { Logger } from '../../../utils/logger';
import { ReferFriendsDisplay } from './components/ReferFriends';
import { ReferRewardsDisplay } from './components/ReferRewards';

import { useUserReferralInfo } from '../../../api/modules/UserReferral';
import { useIsEligibleForReferralRewards } from '../../../components/widgetBanner/hooks';

export const Refer = forwardRef<HTMLDivElement>((_, ref) => {
  const [buttonState, setButtonState] = useState({
    text: 'COPY REFERRAL LINK',
    state: ButtonVariantStates.Idle,
  });

  const [referRewardsButtonState, setReferRewardsButtonState] = useState({
    text: 'SHARE YOUR REFERRAL LINK',
    state: ButtonVariantStates.Idle,
  });

  const isEligibleForReferralRewards = useIsEligibleForReferralRewards();

  const referral = useUserReferralInfo();

  useEffect(() => {
    if (isEligibleForReferralRewards && referral.isLoading) {
      setButtonState({
        text: 'COPY REFERRAL LINK',
        state: referral.isLoading ? ButtonVariantStates.Loading : ButtonVariantStates.Idle,
      });
    }
  }, [referral.isLoading, isEligibleForReferralRewards]);

  const handleCopyLink = async () => {
    try {
      if (window.navigator?.clipboard) {
        await window.navigator.clipboard.writeText(REFER_A_FRIEND_LINK as string);
        Analytics.logEvent('profile_refer_a_friend_copy_link');
        CoreAnalytics.trackReferralLinkCopied();
        setButtonState({ text: 'COPIED', state: ButtonVariantStates.Success });
      } else {
        Logger.error(new Error('Clipboard API not supported'));
      }
    } catch (error) {
      Logger.error(new Error('Failed to copy refer link to clipboard'), { reason: error });
      setButtonState({ text: 'ERROR', state: ButtonVariantStates.Error });
    } finally {
      setTimeout(() => {
        setButtonState({ text: 'COPY REFERRAL LINK', state: ButtonVariantStates.Idle });
      }, 1500);
    }
  };

  const handleReferRewardsCopyLink = async () => {
    try {
      if (window.navigator?.clipboard) {
        const url = new URL(REFER_A_FRIEND_MARKETING_LINK);

        if (referral.data?.referralCode) {
          url.searchParams.set('rid', referral.data.referralCode);
        }

        await window.navigator.clipboard.writeText(url.toString());
        Analytics.logEvent('profile_refer_a_friend_copy_link');
        CoreAnalytics.trackReferralLinkCopied();
        setReferRewardsButtonState({
          text: 'COPIED',
          state: ButtonVariantStates.Success,
        });
      } else {
        Logger.error(new Error('Clipboard API not supported'));
      }
    } catch (error) {
      Logger.error(new Error('Failed to copy refer link to clipboard'), { reason: error });
      setReferRewardsButtonState({
        text: 'ERROR',
        state: ButtonVariantStates.Error,
      });
    } finally {
      setTimeout(() => {
        setReferRewardsButtonState({
          text: 'SHARE YOUR REFERRAL LINK',
          state: ButtonVariantStates.Idle,
        });
      }, 1500);
    }
  };

  if (isEligibleForReferralRewards) {
    return (
      <ReferRewardsDisplay
        buttonState={referRewardsButtonState}
        referralCount={referral.data?.referralCount || 0}
        rewardsCount={referral.data?.rewardsCount || 0}
        onButtonClick={handleReferRewardsCopyLink}
      />
    );
  }

  return <ReferFriendsDisplay buttonState={buttonState} onButtonClick={handleCopyLink} />;
});
