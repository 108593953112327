import { Assets } from '../../../../../../utils/assets';

import * as S from './UpgradeToYearly.styles';

type UpgradeToYearlyProps = {
  onClick: () => void;
};

export function UpgradeToYearly({ onClick }: UpgradeToYearlyProps) {
  return (
    <S.Container>
      <S.SaveBadgeImage
        alt={Assets.images.subscriptionSaveBadge.alt}
        src={Assets.images.subscriptionSaveBadge.url}
      />
      <S.InnerContainer>
        <S.BackgroundBlur
          alt={Assets.images.subscriptionSaveBlur.alt}
          src={Assets.images.subscriptionSaveBlur.url}
        />
        <S.Title>Upgrade to Save $$</S.Title>
        <S.Description>Save 40% when you upgrade to the Yearly plan</S.Description>
        <S.ButtonWrapper onClick={onClick}>
          <S.CircleFadeUp alt="Circle Fade Up" src={Assets.icons.circleFadeUp.url} />
          <S.ButtonText>UPGRADE TO YEARLY</S.ButtonText>
        </S.ButtonWrapper>
      </S.InnerContainer>
    </S.Container>
  );
}
