export const getSessionListenTitle = (mentalState: string | null): string => {
  if (!mentalState) {
    return 'Use Headphones'; // Fallback to focus if no mental state is provided
  }

  if (mentalState === 'sleep') {
    return 'Use Headphones or Speakers';
  }

  return 'Use Headphones';
};
