import { createAction } from '@reduxjs/toolkit';

import { UserInteractionType } from '../types/user';

export const setInteraction = createAction<UserInteractionType>('USER_SET_INTERACTION');
export const getInfo = createAction('USER_INFO_FETCH');
export const getStreaks = createAction<{ source: string }>('USER_STREAKS');
export const getStreaksThrottled = createAction<{ source: string }>('USER_STREAKS_THROTTLED');

export const favoriteTrack = createAction<string>('CURRENT_TRACK_FAVORITE');
export const unfavoriteTrack = createAction<string>('CURRENT_TRACK_UNFAVORITE');
export const fetchFavorites = createAction('USER_FAVORITES_FETCH');
export const userInfoInitiallyFetched = createAction('USER_INFO_INITIALLY_FETCHED');
