import { Icon } from '@Cortex';
import { ActivitySelector } from '@Session';
import { useUser } from '@User';
import { getAdhdModeFeatureFlag } from '@Utils';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Tooltip } from 'react-tooltip';
import { dynamicReplaceTracksWithUpdatedPreferences } from '../../../../actions/sessionManager';

import ArrowLeftIcon from '../../../../assets/images/arrow_left_icon.svg';
import { useDynamicSession } from '../../../../domains/Session/lenses/useDynamicSession';
import { MentalStates } from '../../../../types';
import { Analytics } from '../../../../utils/analytics';
import { Assets } from '../../../../utils/assets';
import { trackPlayerArrowNavigateBack } from '../../../../domains/Analytics/coreAnalytics';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { uiSliceActions } from '../../../../reducers/uiReducer';
import { RootReducerType } from '../../../../reducers';
import SideBarClosedIcon from '../../../../assets/images/side_bar_closed_icon.svg';
import SideBarClosedIconRedDot from '../../../../assets/images/side_bar_closed_icon_red_dot.svg';
import SideBarOpenIcon from '../../../../assets/images/side_bar_open_icon.svg';
import { getIsAdhdModeActive } from '../../../../utils/getIsAdhdModeActive';
import { AdhdModeTooltip } from './components/AdhdModeTooltip';
import SideBarOpenIconRedDot from '../../../../assets/images/side_bar_open_icon_red_dot.svg';

import * as S from './SessionHeader.styles';
import { useMemo } from 'react';
import { useAnnouncementWidget } from '../../../../hooks/useAnnouncementWidget';
import { AdhdSwitch } from '../../../../domains/Session/components/AdhdSwitch/AdhdSwitch.container';
import { useCallback, useState, useEffect } from 'react';

type Props = {
  onClickPreferences?: () => void;
  onClickTimerSettings?: () => void;
};

export const SessionHeader = (props: Props) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isMobileView = useIsMobileView();
  const isSideDeckOpen = useSelector((state: RootReducerType) => state.ui.sideDeck.isOpen);
  const session = useDynamicSession();
  const user = useUser();
  const selectedNELs = session
    ? user.mentalStatePreferences[session.mentalState.id as MentalStates].neuralEffectLevels
    : [];
  const [isAdhdModeActive, setIsAdhdModeActive] = useState(getIsAdhdModeActive(selectedNELs));
  const adhdModeEnabled = getAdhdModeFeatureFlag() === 'on' && session?.mentalState.id === 'focus';
  useEffect(() => {
    setIsAdhdModeActive(getIsAdhdModeActive(selectedNELs));
  }, [selectedNELs, setIsAdhdModeActive]);
  const { announcements } = useAnnouncementWidget();

  const handleBackArrowClick = () => {
    trackPlayerArrowNavigateBack();
    navigate('/', { replace: true });
  };

  const handleSideDeckToggle = () => {
    dispatch(uiSliceActions.setSideDeckOpen(!isSideDeckOpen));

    if (isSideDeckOpen) {
      dispatch(uiSliceActions.setSideDeckModal(null));
    }
  };

  const handleAdhdModeClick = useCallback(() => {
    const newIsActive = !isAdhdModeActive;
    setIsAdhdModeActive(newIsActive);

    if (newIsActive) {
      if (adhdModeEnabled) {
        Analytics.logEventWithProperties('player_adhd_mode_enabled', {});
      }
      dispatch(dynamicReplaceTracksWithUpdatedPreferences({ neuralEffectLevels: ['High'] }));
    } else {
      if (adhdModeEnabled) {
        Analytics.logEventWithProperties('player_adhd_mode_disabled', {});
      }
      dispatch(dynamicReplaceTracksWithUpdatedPreferences({ neuralEffectLevels: [] }));
    }
  }, [isAdhdModeActive, dispatch]);

  const headerIcon = useMemo(() => {
    if (announcements.length > 0) {
      return isSideDeckOpen ? SideBarClosedIconRedDot : SideBarOpenIconRedDot;
    }
    return isSideDeckOpen ? SideBarClosedIcon : SideBarOpenIcon;
  }, [announcements.length, isSideDeckOpen]);

  return (
    <S.Header>
      <S.LeftContainer>
        <S.BackButtonContainer>
          <S.HeaderIconContainer onClick={handleBackArrowClick}>
            <Icon isSquare size={24} src={ArrowLeftIcon} />
            <S.HeaderIconLabel>home</S.HeaderIconLabel>
          </S.HeaderIconContainer>
        </S.BackButtonContainer>
        <S.MobileLogoContainer>
          <Icon size={24} src={ArrowLeftIcon} onClick={handleBackArrowClick} />
        </S.MobileLogoContainer>

        <ActivitySelector />
      </S.LeftContainer>

      <S.RightContainer>
        {adhdModeEnabled && (
          <S.AdhdModeWrapper>
            <S.Text>ADHD Mode</S.Text>
            <S.QuestionIcon id="adhd-mode-tooltip" />
            <Tooltip
              anchorSelect="#adhd-mode-tooltip"
              clickable
              style={{
                backgroundColor: 'black',
                opacity: 0.9,
                width: '20rem',
                borderRadius: '8px',
                padding: '1.5rem',
                zIndex: 10,
              }}
            >
              <AdhdModeTooltip />
            </Tooltip>
            <AdhdSwitch isActive={isAdhdModeActive} onClick={handleAdhdModeClick} />
          </S.AdhdModeWrapper>
        )}
        <S.HeaderIconContainer data-testid="preferencesButton" onClick={props.onClickPreferences}>
          {adhdModeEnabled && isAdhdModeActive ? (
            <Icon isSquare size={32} src={Assets.icons.musicPreferencesAdhdMode.url} />
          ) : (
            <Icon isSquare size={32} src={Assets.icons.musicPreferences.url} />
          )}
          <S.HeaderIconLabel>Preferences</S.HeaderIconLabel>
        </S.HeaderIconContainer>
        {!isMobileView && (
          <S.HeaderIconContainer onClick={handleSideDeckToggle}>
            <Icon size={32} src={headerIcon} />
            <S.HeaderIconLabel>{isSideDeckOpen ? 'Close' : 'Open'}</S.HeaderIconLabel>
          </S.HeaderIconContainer>
        )}
      </S.RightContainer>
    </S.Header>
  );
};
