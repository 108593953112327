import { useCallback } from 'react';

import { useGet } from '../../client/client';
import { Endpoints } from '../../common/endpoints';
import { UserReferralResponseType } from './types';

export const useRequestHandler = () => {
  const get = useGet<UserReferralResponseType>();

  return useCallback(
    async (userId: string) => {
      const data = await get({
        path: Endpoints.getUserReferralInfo(userId),
        options: { apiVersion: 3 },
      });

      return data.result;
    },
    [get],
  );
};
