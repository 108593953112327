import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../reducers';
import { ALL_MILESTONES_COMPLETED_MODAL_DATA } from '../constants';
import { useIsMilestonesWidgetEnabled } from './useIsMilestonesWidgetEnabled';
import { useMilestonesProgressCompleted } from './useMilestonesProgressCompleted';
import { useMilestonesWidgetModalHandler } from './useMilestonesWidgetModalHandler';

export const useMilestonesProgressObserver = () => {
  const isEnabled = useIsMilestonesWidgetEnabled();
  const allMilestonesCompleted = useMilestonesProgressCompleted();
  const milestonesWidgetModalHandler = useMilestonesWidgetModalHandler();
  const { isDismissed } = useSelector((state: RootReducerType) => state.milestones);

  useEffect(() => {
    if (allMilestonesCompleted && !isDismissed && isEnabled) {
      milestonesWidgetModalHandler(ALL_MILESTONES_COMPLETED_MODAL_DATA);
    }
  }, [allMilestonesCompleted, milestonesWidgetModalHandler]);
};
