import styled, { keyframes } from 'styled-components';

export const Container = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
`;

export const Content = styled.div`
  height: 100%;
  width: 100%;
  padding: 2rem 3rem 3rem 3rem;
  display: flex;
  flex-direction: column;
  overflow-y: auto;
`;

export const LoaderContainer = styled.div`
  display: flex;
  justify-content: center;
`;

export const HeaderContainer = styled.div`
  display: flex;
  align-items: center;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: fit-content;
    align-self: center;
  }
`;

export const ContentWrapper = styled.div`
  display: flex;
  height: 100%;
  flex-direction: column;
  align-items: center;
  padding-top: 2.5rem;
`;

export const Logo = styled.img`
  height: 3.5rem;
  width: 3.5rem;
`;

export const TitleWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
  justify-content: center;
  max-width: 53rem;
`;

export const Title = styled.div`
  font-size: 3.5rem;
  font-weight: 700;
  line-height: 130%;
  text-align: center;
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    font-size: 2.5rem;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    font-size: 32px;
    line-height: 120%;
    margin-bottom: 2rem;
  }
`;
export const Description = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1.5rem;
  margin-bottom: 0.75rem;
  line-height: 2rem;
  text-align: center;
  color: rgba(255, 255, 255, 0.75);
`;

export const TimerImage = styled.img`
  max-width: min(450px, 100vw);
  top: -80px;
  position: relative;
`;
export const TimerImageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  width: 100%;
  justify-content: center;
  align-items: center;
`;

export const Answers = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  max-width: 35.75rem;
  width: 100%;
  margin-top: 1rem;
  margin-bottom: 2rem;
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    margin-top: 1rem;
  }
`;

export const HoldButtonContainer = styled.div<{ shifted: boolean }>`
  position: relative;
  width: 180px;
  height: 180px;
  margin: ${({ shifted }) => (shifted ? '-120px' : '0')} auto 0;
`;

export const GradientRing = styled.div<{ progress: number }>`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  border-radius: 50%;
  background: ${props =>
    props.progress > 0
      ? `conic-gradient(
        from 0deg,
        rgba(255, 82, 146, 0.9) 0%,
        rgba(162, 82, 255, 0.9) ${props.progress}%,
        rgba(200, 200, 210, 0.15) ${props.progress}%,
        rgba(200, 200, 210, 0.15) 100%
      )`
      : 'rgba(200, 200, 210, 0.15)'};
`;

export const HoldButton = styled.button<{ isNumber: boolean }>`
  width: calc(100% - 24px);
  height: calc(100% - 24px);
  border-radius: 50%;
  border: none;
  background: radial-gradient(
    circle at center,
    #ffffff 0%,
    #f5f5f5 30%,
    #e0e0e0 60%,
    #cccccc 80%,
    #bbbbbb 100%
  );
  cursor: pointer;
  position: absolute;
  top: 12px;
  left: 12px;
  font-size: ${props => (props.isNumber ? '48px' : '20px')};
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  color: #000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 24px;
  text-align: center;
  line-height: 1.2;
  transition: transform 0.2s ease;
  user-select: none;
  -webkit-user-select: none;
  -moz-user-select: none;
  -webkit-touch-callout: none;
  -webkit-tap-highlight-color: transparent;
  outline: none;

  &:active {
    transform: scale(0.98);
  }

  &:focus {
    outline: none;
  }
`;
