import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  background: rgba(0, 0, 0, 0.6);
  z-index: 999;
`;

export const Container = styled.div`
  margin: 8px;
  display: flex;
  width: 327px;
  height: fit-content;
  padding: 32px 24px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 32px;
  background: url('https://cdn.brain.fm/images/download_desktop_app_background_small_modal_bg.webp'),
    linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1);
  background-repeat: no-repeat;
  background-size: 105% 105%;
  background-position: center;
  border-radius: 12px;
  overflow: hidden;

  /* Modal */
  box-shadow: 0px 8px 24px 0px rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
`;

export const ImageContainer = styled.div`
  width: 254px;
  height: 148px;
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`;

export const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  text-align: center;
`;

export const ButtonContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  align-self: stretch;
`;

export const ButtonWrapper = styled.a`
  text-decoration: none;
  width: 100%;
`;
