import { AMPLITUDE_KEY } from '@Globals';
import Amplitude from 'amplitude-js';

import { Logger } from '../../utils/logger';

const AMPLITUDE_DEVICE_ID_QUERY_PARAM = 'adi';

export function initializeAmplitudeSDK(params?: {
  amplitudeSDK?: typeof Amplitude;
  amplitudeKey?: string;
}) {
  const { amplitudeSDK = Amplitude, amplitudeKey = AMPLITUDE_KEY } = params || {};

  let deviceId;

  try {
    const urlParams = new URLSearchParams(window.location.search);
    deviceId = urlParams.get(AMPLITUDE_DEVICE_ID_QUERY_PARAM) || undefined;
  } catch (e) {}

  try {
    amplitudeSDK.getInstance().init(amplitudeKey, undefined, { includeUtm: true, deviceId });
  } catch (e) {
    Logger.error(new Error('Failed to initialize amplitude sdk.'), { reason: e });
  }
}
