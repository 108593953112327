import { useSelector } from 'react-redux';
import { useWebMilestonesExperiment } from '../../../../../domains/Utils/useAmplitudeExperiments';
import { RootReducerType } from '../../../../../reducers';

export const useIsMilestonesWidgetEnabled = () => {
  const webMilestonesExperimentData = useWebMilestonesExperiment();
  const { hasTakenOnboardingWhileInNonControlTest } = useSelector(
    (state: RootReducerType) => state.milestones,
  );

  return (
    hasTakenOnboardingWhileInNonControlTest &&
    webMilestonesExperimentData?.isInTestGroup &&
    webMilestonesExperimentData?.value !== 'control'
  );
};
