export const getSessionListenDescription = (mentalState: string | null): string => {
  if (!mentalState) {
    return 'Wearing headphones will enhance the brain’s response to the music.'; // Fallback to focus if no mental state is provided
  }

  switch (mentalState) {
    case 'focus':
    case 'relax':
    case 'meditate':
      return 'Wearing headphones will enhance the brain’s response to the music.';
    case 'sleep':
      return 'Sleep phones or a speaker near the bed will enhance the brain’s response to the music.';
    default:
      return 'Wearing headphones will enhance the brain’s response to the music.';
  }
};
