import { Button, ButtonVariants } from '@Cortex';

import * as S from './IntroCardV1.styles';
import BrandLogo from '../../../../assets/images/brand_logo.svg';

export type Props = {
  onContinue: () => void;
};

export const Body = ({ text }: { text: string }) => {
  const parts = text.split(/\*([^*]+)\*/g);

  return (
    <S.Body>
      {parts.map((part, index) => {
        if (index % 2 === 1) {
          return <S.BodyTextBold key={part + index}>{part}</S.BodyTextBold>;
        } else {
          return part;
        }
      })}
    </S.Body>
  );
};

export function IntroCardV1({ onContinue }: Props) {
  return (
    <S.Container>
      <S.Header>
        <S.Logo src={BrandLogo} />
        <S.LogoPlaceholder src={BrandLogo} />
      </S.Header>
      <S.ContentWrapper>
        <S.Content>
          <S.OnboardingCardTextColumn>
            <S.Title>Let's set up your first focus session</S.Title>
            <S.Body>Answer just a few questions easy questions so we can...</S.Body>
            <S.BulletContainer>
              <S.Body>• Pick tracks purpose-built for your tasks</S.Body>
              <S.Body>• Adjust the modulation level for your brain</S.Body>
              <S.Body>• Match your favorite genres</S.Body>
            </S.BulletContainer>
          </S.OnboardingCardTextColumn>
          <S.OnboardingCardImageColumn>
            <S.Image src="https://cdn.brain.fm/images/focusintro.webp" />
          </S.OnboardingCardImageColumn>
        </S.Content>
      </S.ContentWrapper>

      <S.Buttons>
        <S.ButtonContainer>
          <Button isFullWidth keepTextCase variant={ButtonVariants.Secondary} onClick={onContinue}>
            Continue
          </Button>
        </S.ButtonContainer>
      </S.Buttons>
    </S.Container>
  );
}
