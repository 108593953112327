import { MenuItemIcons } from '@Cortex';
import { useDispatch } from 'react-redux';

import { uiSliceActions } from '../../../../reducers/uiReducer';
import { Analytics } from '../../../../utils/analytics';

import { ReferAFriendRewardsWidgetPresentation } from './presentation';
import { ProfileSection, SideDeckTab } from '../../../../types';
import { useIsMobileView } from '../../../../hooks/useIsMobileView';
import { useEffect } from 'react';
import { BfmAnalytics } from '@brainfm/analytics';
import { useIsEligibleForReferralRewards } from '../../../../components/widgetBanner/hooks';

export function ReferAFriendRewardsWidget() {
  const dispatch = useDispatch();
  const shouldRender = useIsEligibleForReferralRewards();
  const isMobileView = useIsMobileView();

  useEffect(() => {
    if (shouldRender) {
      Analytics.logEvent('test_player_refer_a_friend_impression');

      // Extra logging for BrainFM Analytics
      BfmAnalytics.getInstance().logEvent({
        name: 'test_player_refer_a_friend_impression',
      });
    }
  }, []);

  function handleClickClose() {
    Analytics.logEvent('test_player_refer_a_friend_dismiss');
    dispatch(uiSliceActions.setIsReferAFriendDismissed(true));
  }

  function handleClickRefer() {
    dispatch(uiSliceActions.setSideDeckOpen(true));
    dispatch(
      uiSliceActions.setSideDeckTab({
        tab: SideDeckTab.Profile,
        profileSection: ProfileSection.Refer,
      }),
    );
    Analytics.logEvent('test_player_refer_a_friend_click');
  }

  if (!shouldRender) return null;

  return (
    <ReferAFriendRewardsWidgetPresentation
      icon={MenuItemIcons.Close}
      isMobileView={isMobileView}
      onClickButton={handleClickRefer}
      onClickClose={handleClickClose}
    />
  );
}
