import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { uiSliceActions } from '../../../../../../../reducers/uiReducer';
import { SideDeckModalType } from '../../../../../../../types';

export function useOpenUpgradeStripeSubscriptionModal() {
  const dispatch = useDispatch();

  return useCallback(() => {
    dispatch(uiSliceActions.setSideDeckModal(SideDeckModalType.UpgradeStripeSubscription));
  }, [dispatch]);
}
