import { CoreAnalytics, TrackActionOrigins } from '@Analytics';
import { TextBold } from '@Cortex';
import { Serving, Track } from '@Model';
import {
  getTrackId,
  getTrackName,
  addToFavorites,
  removeFromFavorites,
  getTrackHasMultipleNELs,
  getTrackImageUrl,
  getTrackIsNewlyCreated,
  TrackInformationCard,
  getTrackNeuralEffectLevel,
  getTrackGenreName,
} from '@Music';
import { getTrackVariationId } from '@Music';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { useRecentTracksAllMentalStates } from '../../../../../../api/modules/RecentTracksAllMentalStates';
import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { ExploreEvents } from '../../../../../../utils/analytics/events';
import { getDynamicSimilarActivityPlayerPath } from '../../../../../../utils/getDynamicSimilarActivityPlayerPath';
import * as S from './RecentTracks.styles';
import { getTrackRelatedActivities } from '../../../../../../domains/Music/lenses/getTrackRelatedActivities';

const MAX_RECENT_TRACKS = 10;

type Props = {
  isFromJumpBackIn?: boolean;
  onTrackClickMore: (track: Track | Serving) => void;
};

export function RecentTracks({ isFromJumpBackIn = false, onTrackClickMore }: Props) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { isLoading, error, data } = useRecentTracksAllMentalStates();

  const favorites = useSelector((state: RootReducerType) => state.music.favorites);

  const handleVariationClickPlay = useCallback(
    (track: Track | Serving) => {
      Analytics.logEventWithProperties(ExploreEvents.PlayTrackFromRecents, {
        trackId: getTrackId(track),
        trackVariationId: getTrackVariationId(track),
        trackName: getTrackName(track),
      } as any);

      navigate(getDynamicSimilarActivityPlayerPath(getTrackId(track)), {
        state: {
          startedFrom: isFromJumpBackIn ? 'Jump back in recent track' : undefined,
          useSavedTimer: true,
        },
      });

      CoreAnalytics.trackSelectTrackEvent({
        origin: TrackActionOrigins.Recents,
        trackName: getTrackName(track),
      });
    },
    [navigate],
  );

  if (isLoading) {
    return <S.Loading />;
  }

  if (error) {
    return <TextBold>{error.message}</TextBold>;
  }

  return (
    <S.CardsContainer aria-label="Recent tracks list" role="list">
      {(data || []).slice(0, MAX_RECENT_TRACKS).map(track => {
        const isFavorited = favorites.some(favorite => {
          return getTrackId(favorite) === getTrackId(track);
        });

        const trackId = getTrackId(track);

        return (
          <S.CardContainer
            key={getTrackVariationId(track) || trackId}
            aria-label={`Play ${getTrackName(track)}`}
            data-testid="recentTrackItem"
            role="button"
            tabIndex={0}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                handleVariationClickPlay(track);
              }
            }}
          >
            <TrackInformationCard
              favoritesButtonTestId={`trackFavoriteButton__${trackId}`}
              hasMultipleNELs={getTrackHasMultipleNELs(track)}
              imageUrl={getTrackImageUrl(track)}
              isFavorited={isFavorited}
              isNewlyCreated={getTrackIsNewlyCreated(track)}
              moreIconTestId={`trackCardMoreInfo__${getTrackId(track)}`}
              neuralEffectLevel={getTrackNeuralEffectLevel(track)}
              relatedActivities={getTrackRelatedActivities(track)}
              subtitle={getTrackGenreName(track)}
              title={getTrackName(track)}
              onAddToFavorites={() => {
                dispatch(addToFavorites({ origin: TrackActionOrigins.Recents, track }));
              }}
              onClick={() => handleVariationClickPlay(track)}
              onClickMore={() => onTrackClickMore(track)}
              onRemoveFromFavorites={() =>
                dispatch(
                  removeFromFavorites({
                    track,
                  }),
                )
              }
            />
          </S.CardContainer>
        );
      })}
    </S.CardsContainer>
  );
}
