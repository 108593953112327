import styled from 'styled-components';
import { FontColors, Text, TextBold } from '@Cortex';
import { Assets } from '../../../../utils/assets';

export const Container = styled.div`
  background-attachment: fixed;
  background-color: #0d0c11;
  background-image: url(${Assets.images.blurBg.url});
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  width: 100%;
  height: 100%;
  flex-direction: column;
  overflow: hidden;
  justify-content: space-between;
  align-items: center;
  overflow-y: auto;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  color: ${FontColors.White};
  padding: 2rem 3rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    align-items: center;
  }
`;

export const Logo = styled.img`
  height: 3.5rem;
  width: 3.5rem;
`;

export const LogoPlaceholder = styled(Logo)`
  opacity: 0;
  display: block;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    display: none;
  }
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 1.25rem 5rem;
  flex-grow: 1;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
  }
`;

export const HeaderText = styled(TextBold)`
  font-size: 2.5rem;
  margin-bottom: 1rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 2rem;
  }
`;

export const HeaderSubtext = styled(Text)`
  color: ${FontColors.WhiteSecondary};
  font-size: 1.25rem;
  margin-bottom: 1.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    font-size: 1rem;
    margin-bottom: 1rem;
  }
`;

export const TipsContainer = styled.div`
  background: rgba(13, 12, 17, 0.5);
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.2);
  display: flex;
  flex-direction: row;
  padding: 3rem 0;
  margin-bottom: 3.5rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    gap: 1.25rem;
    padding: 1rem;
    margin-bottom: 1rem;
  }
`;

export const TipContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  padding: 0 2rem;
  width: 33.3%;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    width: 100%;
  }
`;

export const TipContainerHeader = styled(TextBold)`
  color: ${FontColors.WhiteTransparent};
  font-size: 0.75rem;
  letter-spacing: 1.2px;
  margin-bottom: 0.5rem;
`;

export const TipContainerHeaderSubtext = styled(TextBold)`
  font-size: 1.25rem;
  margin-bottom: 1.25rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    font-size: 1rem;
  }
`;

export const StepImage = styled.img`
  border-radius: 8px;
  height: 13.25rem;
  width: 100%;
  max-width: 354px;
  object-fit: contain;
  margin-bottom: 0.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.xxl} {
    height: 10rem;
    object-fit: contain;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    display: none;
  }
`;

export const Description = styled(Text)`
  color: ${FontColors.WhiteSecondary};
  font-size: 1rem;
  font-style: normal;
  text-align: center;
`;

export const Buttons = styled.div`
  display: flex;
  justify-content: center;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-bottom: 3rem;
  width: 100%;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    padding: 1rem;
    align-items: center;
    flex-direction: column;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    background-color: #000000;
    padding: 1rem;
  }
`;

export const ButtonContainer = styled.div`
  width: 20.75rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    width: 100%;
  }
`;

export const Divider = styled.div`
  width: 2px;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.5);
`;
