import { NOOP } from '@Globals';
import { useFeatureFlag, FeatureFlags } from '@Utils';
import { useEffect, useState, useRef } from 'react';
import { useLocation } from 'react-router-dom';
import { DEFAULT_BENEFITS, REVIEWS } from '../../constants';
import { useCommonPaywallData } from '../../hooks/useCommonPaywallData';
import { OnboardingTestimonials } from '../../../../../Onboarding/types';

import { SingleScreenSlideUpPaywallDisplay } from './display';
import { useIsExtendedPromoAvailable } from '../../../PaywallModal/hooks/useIsExtendedPromoAvailable';
import { getHasDiscount } from '../SingleScreenSlideUpPaywall/lib/getHasDiscount';
import { getDiscountPercentage } from '../SingleScreenSlideUpPaywall/lib/getDiscountPercentage';
import { getPaymentButtonState } from '../SingleScreenSlideUpPaywall/lib/getPaymentButtonState';
import { getFootnote } from '../SingleScreenSlideUpPaywall/lib/getFootnote';

interface Props {
  paywallBenefits?: string[];
  paywallTestimonials?: OnboardingTestimonials[];
  onPaymentComplete?: () => void;
  onClose?: () => void;
}

export enum Steps {
  PlanSelection = 'PlanSelection',
  PaymentForm = 'PaymentForm',
}

export const ADHDExperimentThree = ({
  onPaymentComplete = NOOP,
  onClose = NOOP,
  paywallBenefits = DEFAULT_BENEFITS,
  paywallTestimonials = REVIEWS,
}: Props) => {
  const location = useLocation();
  const [currentStep, setCurrentStep] = useState<Steps>(Steps.PlanSelection);
  const isStripeCheckoutEnabled = useFeatureFlag(FeatureFlags.StripeCheckout);
  const onSetStep = (step: Steps) => () => setCurrentStep(step);
  const isFromOnboarding = location.state?.from === 'Onboarding';

  const isCloseButtonAvailable = !isFromOnboarding;

  const {
    errorMessage,
    selectedPlan,
    setSelectedPlan,
    plans,
    handlePayment,
    handleStripeCheckout,
    paymentStatus,
    defaultBillingName,
  } = useCommonPaywallData({ onPaymentComplete });

  useEffect(() => {
    if (isStripeCheckoutEnabled) return;

    const handleBeforeUnload = (event: BeforeUnloadEvent) => {
      event.preventDefault();
      const message = 'Are you sure you want to leave?';
      event.returnValue = message; // This message is not actually returned, but for chrome to show dialog
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, [isStripeCheckoutEnabled]);

  const hasDiscount = getHasDiscount(selectedPlan);
  const discountPercentage = getDiscountPercentage(selectedPlan);
  const variantState = getPaymentButtonState(paymentStatus);
  const footnote = getFootnote(hasDiscount, selectedPlan?.description);
  const paymentRef = useRef<HTMLDivElement>(null);
  const isExtendedPromoAvailable = useIsExtendedPromoAvailable();

  const handleNavigateToPayment = () => {
    onSetStep(Steps.PaymentForm)();
    paymentRef.current?.scrollIntoView({ behavior: 'smooth' });
  };

  return (
    <SingleScreenSlideUpPaywallDisplay
      billingName={defaultBillingName}
      buttonSubtitle={'Cancel anytime'}
      currentStep={currentStep}
      discountPercentage={discountPercentage}
      errorMessage={errorMessage}
      fontSize={16}
      footnote={footnote}
      hasDiscount={hasDiscount}
      isCloseButtonAvailable={isCloseButtonAvailable}
      isExtendedPromo={isExtendedPromoAvailable}
      isStripeCheckoutEnabled={isStripeCheckoutEnabled}
      paymentRef={paymentRef}
      paywallBenefits={paywallBenefits}
      paywallTestimonials={paywallTestimonials}
      plans={plans}
      selectedPlan={selectedPlan}
      variantState={variantState}
      onBack={onSetStep(Steps.PlanSelection)}
      onClose={onClose}
      onHandlePayment={handlePayment}
      onNavigateToPayment={handleNavigateToPayment}
      onPlanSelect={setSelectedPlan}
      onSubmitStripeCheckout={handleStripeCheckout}
    />
  );
};
