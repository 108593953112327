import React from 'react';
import { Assets } from '../../../../../../utils/assets';
import * as S from './LifetimePanel.styles';
import { MembershipPanelWrapper } from '../MembershipPanelWrapper/MembershipPanelWrapper';
import { Icon } from '@Cortex';
import CalendarIcon from '../../../../../../assets/images/calendar_icon.svg';

export const LifetimeMembershipPanel = () => {
  return (
    <MembershipPanelWrapper
      isLoading={false}
      subtitle="Unlimited days remaining"
      title="Lifetime Plan"
    >
      <S.PaymentInfo>
        <S.PaymentRow>
          <S.PaymentInfoLeft>
            <Icon size={24} src={CalendarIcon} />
            <S.PaymentDescription>Next Payment Date</S.PaymentDescription>
          </S.PaymentInfoLeft>
          <S.PaymentDescriptionMedium>Never</S.PaymentDescriptionMedium>
        </S.PaymentRow>
        <S.PaymentRow>
          <S.PaymentInfoLeft>
            <Icon size={24} src={CalendarIcon} />
            <S.PaymentDescription>Total Due</S.PaymentDescription>
          </S.PaymentInfoLeft>
          <S.PaymentDescriptionMedium>$0.00</S.PaymentDescriptionMedium>
        </S.PaymentRow>
      </S.PaymentInfo>
    </MembershipPanelWrapper>
  );
};
