import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';
import { milestonesSliceActions } from '../../../../../reducers/milestones';
import { useMilestonesWidgetModalHandler } from './useMilestonesWidgetModalHandler';

export const useFirstActivityHandler = () => {
  const dispatch = useDispatch();
  const milestonesWidgetModalHandler = useMilestonesWidgetModalHandler();
  const firstActivity = useSelector(
    (state: RootReducerType) => state.milestones.items.firstActivity,
  );

  useEffect(() => {
    if (firstActivity.completed) return;
    dispatch(milestonesSliceActions.completeFirstActivity());
    milestonesWidgetModalHandler({
      title: 'Just 3 steps to feel the difference',
      description:
        'Users who complete these steps are 61% more likely to see improvement in their mental\n' +
        'state',
      buttonText: 'GOT IT',
      icon: 'stepsIcon',
    });
  }, [firstActivity.completed, dispatch, milestonesWidgetModalHandler]);
};
