import { PresetGenresTypes } from '../types';
import { PRESET_GENRES } from '../constants';

export const getSelectedPresets = (selectedGenres: string[]): PresetGenresTypes[] => {
  // Array to store fully matching presets
  const matchingPresets: PresetGenresTypes[] = [];

  // Track remaining genres that haven't been matched
  const remainingGenres = new Set(selectedGenres);

  // Check each preset
  Object.entries(PRESET_GENRES).forEach(([presetKey, presetGenres]) => {
    const preset = presetKey as PresetGenresTypes;

    // Check if all genres from this preset are selected
    const allPresetGenresSelected = presetGenres.every(genre => selectedGenres.includes(genre));

    // Check if the number of genres in this preset matches the selected ones
    const presetFullySelected =
      allPresetGenresSelected &&
      presetGenres.length === presetGenres.filter(genre => selectedGenres.includes(genre)).length;

    if (presetFullySelected) {
      // Add this preset to the matching presets
      matchingPresets.push(preset);

      // Remove these genres from the remaining set
      presetGenres.forEach(genre => remainingGenres.delete(genre));
    }
  });

  // If there are remaining genres that weren't fully matched to any preset, return empty array
  if (remainingGenres.size > 0) {
    return [];
  }

  return matchingPresets;
};
