import * as S from './OnboardingResultModal.styles';
import { Button, ButtonVariants, Loading } from '@Cortex';
import { Assets } from '../../../utils/assets';
import ReactModal from 'react-modal';
import { useUser } from '@User';
import { Image, MentalStates } from '../../../types';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../reducers';
import { formatGenrePreferences } from './helpers/formatGenrePreferences';
import { getActivityImage } from './helpers/getActivityImage';
import { HeaderWithLogoAndClose } from '../../shared/HeaderWithLogoAndClose';
import { StepIndicator } from '../components/StepIndicator/StepIndicator';
import { getNeuralEffectLevelImage } from './helpers/getNeuralEffectLevelImage';
import { getGenrePreferenceImage } from './helpers/getGenrePreferenceImage';
import { getNeuralEffectLevelDescription } from './helpers/getNeuralEffectLevelDescription';
import { getGenrePreferenceDescription } from './helpers/getGenrePreferenceDescription';
import { DynamicActivity } from '../../Model';
import { WelcomeScreen } from '../WelcomeModal/WelcomeModal.display';
import { useState } from 'react';
import { getActivityDescription } from './helpers/getActivityDescription';
import { getActivityHeaderDescription } from './helpers/getActivityHeaderDescription';

export type Props = {
  isOpen: boolean;
  isPaymentSaved: boolean;
  mentalStateId: string | null;
  onClose: () => void;
};

export const OnboardingResultDisplay = ({
  isOpen,
  isPaymentSaved,
  mentalStateId,
  onClose,
}: Props) => {
  const user = useUser();
  const preferences =
    user.mentalStatePreferences?.[(mentalStateId as MentalStates) || MentalStates.Focus];
  const selectedNEL = preferences.neuralEffectLevels[0];

  const sessionDynamicActivity = useSelector(
    (state: RootReducerType) => state.sessionManager.sessionDynamicActivity,
  );

  const activityImage = getActivityImage(sessionDynamicActivity);
  const neuralEffectLevelImage = getNeuralEffectLevelImage(selectedNEL);
  const genrePreferenceImage = getGenrePreferenceImage(preferences.genreNames);
  const [activeScreenIndex, setActiveScreenIndex] = useState(0);

  return (
    <ReactModal isOpen={isOpen} style={{ overlay: { zIndex: 10000 } }}>
      <S.Container>
        <S.BackgroundBlur src={Assets.images.firstActivityModal.blur.url} />
        <HeaderWithLogoAndClose
          padding="2rem 0"
          style={{ position: 'relative', alignItems: 'center' }}
        >
          <StepIndicator
            activeIndex={activeScreenIndex}
            style={{ position: 'absolute' }}
            totalSteps={2}
          />
        </HeaderWithLogoAndClose>

        <S.Screens isActive={activeScreenIndex === 1}>
          <S.Screen>
            <ResultScreen
              activityImage={activityImage}
              genrePreferenceImage={genrePreferenceImage}
              mentalStateId={mentalStateId}
              neuralEffectLevelImage={neuralEffectLevelImage}
              preferences={preferences}
              selectedNEL={selectedNEL}
              sessionDynamicActivity={sessionDynamicActivity}
              onClose={() => setActiveScreenIndex(1)}
            />
          </S.Screen>
          <S.Screen>
            <WelcomeScreen mentalStateId={mentalStateId} onClose={onClose} />
          </S.Screen>
        </S.Screens>

        {isPaymentSaved && (
          <S.PaymentContainer>
            <S.CheckIcon
              alt={Assets.icons.greenCircleCheck.alt}
              src={Assets.icons.greenCircleCheck.url}
            />
            <S.PaymentText>Your payment information was saved.</S.PaymentText>
          </S.PaymentContainer>
        )}
      </S.Container>
    </ReactModal>
  );
};

function ResultScreen(props: {
  onClose: () => void;
  sessionDynamicActivity: DynamicActivity | null;
  mentalStateId: string | null;
  activityImage: Image | null;
  selectedNEL: string;
  preferences: { genreNames: string[] };
  neuralEffectLevelImage: Image;
  genrePreferenceImage: Image;
}) {
  return (
    <>
      <S.HeaderText>
        Welcome to your personalized{' '}
        {props.sessionDynamicActivity?.mentalState.displayValue.toLowerCase()} experience
      </S.HeaderText>
      <S.HeaderSubtext>{getActivityHeaderDescription(props.mentalStateId)}</S.HeaderSubtext>

      <S.TipsContainer>
        <S.TipContainer>
          {props.sessionDynamicActivity ? (
            <>
              <S.TipContainerHeader>ACTIVITY</S.TipContainerHeader>
              <S.TipContainerHeaderSubtext>
                {props.sessionDynamicActivity?.displayValue}
              </S.TipContainerHeaderSubtext>
              <S.StepImage
                alt={props.activityImage?.alt || Assets.images.clock10.alt}
                src={props.activityImage?.url || Assets.images.clock10.url}
              />
              <S.Description>{getActivityDescription(props.sessionDynamicActivity)}</S.Description>
            </>
          ) : (
            <Loading uniqueId="firstActivityModal" />
          )}
        </S.TipContainer>

        <S.Divider />

        <S.TipContainer>
          <S.TipContainerHeader>MODULATION</S.TipContainerHeader>
          <S.TipContainerHeaderSubtext>
            {props.selectedNEL} Neural Effect Level
          </S.TipContainerHeaderSubtext>
          <S.StepImage
            alt={props.neuralEffectLevelImage.alt}
            src={props.neuralEffectLevelImage.url}
          />
          <S.Description>
            {getNeuralEffectLevelDescription(props.selectedNEL, props.mentalStateId)}
          </S.Description>
        </S.TipContainer>

        <S.Divider />

        <S.TipContainer>
          <S.TipContainerHeader>GENRES</S.TipContainerHeader>
          <S.TipContainerHeaderSubtext>
            {formatGenrePreferences(props.preferences.genreNames)}
          </S.TipContainerHeaderSubtext>
          <S.StepImage alt={props.genrePreferenceImage.alt} src={props.genrePreferenceImage.url} />
          <S.Description>
            {getGenrePreferenceDescription(props.preferences.genreNames)}
          </S.Description>
        </S.TipContainer>
      </S.TipsContainer>

      <S.ButtonContainer>
        <Button isFullWidth keepTextCase variant={ButtonVariants.Secondary} onClick={props.onClose}>
          CONTINUE
        </Button>
      </S.ButtonContainer>
    </>
  );
}
