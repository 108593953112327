import { DynamicActivity } from '@Model';
import { Events } from '../../../../../utils/analytics/events';
import { Analytics } from '../../../../../utils/analytics';

// Activity Selection State Types
export interface ActivitySelectionState {
  currentOnTheFlyActivity: DynamicActivity | null;
  isCurrentOnTheFlyActivityHidden: boolean;
  correspondingActivity: DynamicActivity | undefined;
  initialSelectedActivity: DynamicActivity | undefined;
  activitiesList: DynamicActivity[];
}

// Internal helper functions
const isActivityOnTheFly = (
  dynamicActivities: DynamicActivity[] | undefined,
  sessionDynamicActivity: DynamicActivity | null,
): boolean => {
  return Boolean(!dynamicActivities?.find(a => a.id === sessionDynamicActivity?.id));
};

const isOnTheFlyActivityHidden = (currentOnTheFlyActivity: DynamicActivity | null): boolean => {
  return Boolean(
    currentOnTheFlyActivity &&
      (currentOnTheFlyActivity.id.startsWith('yourFirstSession') ||
        currentOnTheFlyActivity.id.startsWith('mackOne') ||
        currentOnTheFlyActivity.id.startsWith('smartOne')),
  );
};

const findCorrespondingActivity = (
  isCurrentOnTheFlyActivityHidden: boolean,
  currentOnTheFlyActivity: DynamicActivity | null,
  dynamicActivities: DynamicActivity[] | undefined,
): DynamicActivity | undefined => {
  if (!isCurrentOnTheFlyActivityHidden) {
    return undefined;
  }
  return dynamicActivities?.find(a => a.displayValue === currentOnTheFlyActivity?.displayValue);
};

const getInitialSelectedActivity = (
  correspondingActivity: DynamicActivity | undefined,
  sessionDynamicActivity: DynamicActivity | null,
  dynamicActivities: DynamicActivity[] | undefined,
): DynamicActivity | undefined => {
  return correspondingActivity || sessionDynamicActivity || dynamicActivities?.[0];
};

const getActivitiesList = (
  currentOnTheFlyActivity: DynamicActivity | null,
  isCurrentOnTheFlyActivityHidden: boolean,
  dynamicActivities: DynamicActivity[] | undefined,
): DynamicActivity[] => {
  return [
    ...(currentOnTheFlyActivity && !isCurrentOnTheFlyActivityHidden
      ? [currentOnTheFlyActivity]
      : []),
    ...(dynamicActivities || []),
  ];
};

// Main public function for activity selection state
export const getActivitySelectionState = (
  dynamicActivities: DynamicActivity[] | undefined,
  sessionDynamicActivity: DynamicActivity | null,
): ActivitySelectionState => {
  const isCurrentActivityOnTheFly = isActivityOnTheFly(dynamicActivities, sessionDynamicActivity);
  const currentOnTheFlyActivity = isCurrentActivityOnTheFly ? sessionDynamicActivity : null;
  const isCurrentOnTheFlyActivityHidden = isOnTheFlyActivityHidden(currentOnTheFlyActivity);

  const correspondingActivity = findCorrespondingActivity(
    isCurrentOnTheFlyActivityHidden,
    currentOnTheFlyActivity,
    dynamicActivities,
  );

  const initialSelectedActivity = getInitialSelectedActivity(
    correspondingActivity,
    sessionDynamicActivity,
    dynamicActivities,
  );

  const activitiesList = getActivitiesList(
    currentOnTheFlyActivity,
    isCurrentOnTheFlyActivityHidden,
    dynamicActivities,
  );

  return {
    currentOnTheFlyActivity,
    isCurrentOnTheFlyActivityHidden,
    correspondingActivity,
    initialSelectedActivity,
    activitiesList,
  };
};

// Analytics Types and Functions
export interface ActivityAnalyticsPayload {
  localSelectedActivity: Omit<DynamicActivity, 'mentalState'>;
  stateSelectedActivity: Omit<DynamicActivity, 'mentalState'>;
}

export const handleActivityAnalytics = (payload: ActivityAnalyticsPayload): void => {
  const { localSelectedActivity, stateSelectedActivity } = payload;

  if (localSelectedActivity?.id !== stateSelectedActivity?.id) {
    Analytics.logEventWithProperties(Events.preferences_activity_filters_changed, {
      old_filter: stateSelectedActivity?.displayValue,
      new_filter: localSelectedActivity?.displayValue,
    });
  }
};
