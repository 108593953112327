import { memo } from 'react';
import { DesktopAnnouncementContainer } from '../../../../domains/Memberships/components/DesktopAnnouncementBanner/container';
import * as S from './styles';
import { DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID } from '../../../../domains/Memberships/components/DesktopAnnouncementBanner/constants';
import { useAnnouncementWidget } from '../../../../hooks/useAnnouncementWidget';

type Props = {
  isMobileView: boolean;
};

export const SideDeckWidget = memo(({ isMobileView }: Props) => {
  const { announcements, dismissAnnouncement } = useAnnouncementWidget();

  if (announcements.length === 0) {
    return null;
  }

  return (
    <S.Container isMobileView={isMobileView}>
      {announcements.map(announcement => {
        if (announcement.id === DESKTOP_ANNOUNCEMENT_EXPERIMENT_ID) {
          return (
            <DesktopAnnouncementContainer
              key={announcement.id}
              isMobileView={isMobileView}
              isVisible={true}
              onClose={() => dismissAnnouncement(announcement.id)}
              onDownload={() => dismissAnnouncement(announcement.id)}
            />
          );
        }
      })}
    </S.Container>
  );
});
