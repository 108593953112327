import { useQuery } from '@tanstack/react-query';
import { isOfflineError } from '../../../utils/isOfflineError';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { useRequestHandler } from './useRequestHandler';
import { UserReferralResponseType } from './types';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../reducers';

export const useUserReferralInfo = () => {
  const getUserReferralInfo = useRequestHandler();
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);

  return useQuery<UserReferralResponseType['result']>(
    Queries.getUserReferralInfo(userId!),
    () => getUserReferralInfo(userId!),
    {
      staleTime: 0,
      cacheTime: 0,
      refetchOnMount: true,
      onError: err => {
        if (isOfflineError(err)) return;
        Logger.error(new Error('Error getting user referral code'), {
          err,
        });
      },
    },
  );
};
