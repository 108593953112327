import { Button, ButtonVariants } from '@Cortex';
import ReactModal from 'react-modal';

import BrandLogoWithExclamation from '../../../../assets/images/brand_logo_exclamation.svg';
import { baseStyles, CLOSE_TIMEOUT_MS } from '../../../../modals/modalStyles';
import { DemoTrack } from './components/DemoTrack';
import * as S from './PaywallModal.styles';

export type Props = {
  description: string;
  isOpen: boolean;
  primaryButtonText: string;
  title: string;
  ctaDescription: string;
  onPrimaryButtonClick?: () => void;
};

export function PaywallModalDisplay(props: Props) {
  return (
    <ReactModal closeTimeoutMS={CLOSE_TIMEOUT_MS} isOpen={props.isOpen} style={baseStyles}>
      <S.Wrapper>
        <S.ContainerWithIcon>
          <S.Container
            onClick={evt => {
              evt.stopPropagation();
            }}
          >
            <S.ImageContainer>
              <S.Image src={BrandLogoWithExclamation} />
            </S.ImageContainer>
            <S.TextContainer>
              <S.Title>{props.title}</S.Title>
              <S.Description>{props.description}</S.Description>
            </S.TextContainer>
            <DemoTrack />
            <S.TextContainer>
              <S.Description>{props.ctaDescription}</S.Description>
            </S.TextContainer>
            <S.ButtonContainer>
              <S.ButtonWrapper>
                <Button
                  data-testid="successButtonPrimary"
                  isFullWidth
                  keepTextCase
                  style={{ fontSize: '0.75rem' }}
                  variant={ButtonVariants.Secondary}
                  onClick={props.onPrimaryButtonClick}
                >
                  {props.primaryButtonText}
                </Button>
              </S.ButtonWrapper>
            </S.ButtonContainer>
          </S.Container>
        </S.ContainerWithIcon>
      </S.Wrapper>
    </ReactModal>
  );
}
