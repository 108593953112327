import { Text, TextBold } from '@Cortex';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
`;

export const TopTextContainer = styled.div`
  margin-top: 20px;
  margin-bottom: 32px;
`;

export const DescriptionContainer = styled.div`
  margin: 24px 0;
`;

export const HeaderText = styled(TextBold)`
  text-align: center;
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.4px;
`;

export const Image = styled.img`
  width: 100%;
  transform: scale(1.07);
  height: auto;
  object-fit: contain;
`;

export const Description = styled(Text)`
  color: rgba(255, 255, 255, 0.9);
  text-align: center;
  font-size: 16px;
  line-height: 24px;
`;

export const ButtonWrapper = styled.div``;
