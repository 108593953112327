import { Button, ButtonVariants, LoadingText } from '@Cortex';
import React from 'react';

import * as S from './BillingPanel.styles';
import { Tooltip } from 'react-tooltip';

export type Props = {
  cardInformation: string;
  isLoading?: boolean;
  onClickCancel?: (() => void) | null;
  onClickUpdatePaymentMethod?: (() => void) | null;
  updatePaymentButtonText?: string;
  externalPaymentImageSrc?: string;
  tooltipText?: string;
};

export function BillingPanel({
  cardInformation,
  isLoading = false,
  tooltipText,
  onClickCancel = null,
  onClickUpdatePaymentMethod = null,
  updatePaymentButtonText = 'Edit',
  externalPaymentImageSrc,
}: Props) {
  return (
    <S.Container>
      <S.PaymentMethod>
        <S.TextMedium>Payment Method</S.TextMedium>
        <S.RightPayment>
          {externalPaymentImageSrc ? (
            <S.ExternalPaymentImage src={externalPaymentImageSrc} />
          ) : null}
          <S.Text>
            {isLoading ? <LoadingText>{cardInformation}</LoadingText> : cardInformation}
          </S.Text>
          {onClickUpdatePaymentMethod ? (
            <S.EditButton
              data-testid="updatePaymentButton"
              onClick={() => onClickUpdatePaymentMethod()}
            >
              {updatePaymentButtonText}
            </S.EditButton>
          ) : null}
          {tooltipText ? (
            <>
              <S.InformationIcon id="my-anchor-element-id" />
              <Tooltip
                anchorSelect="#my-anchor-element-id"
                style={{
                  backgroundColor: 'black',
                  opacity: 0.9,
                  width: '20rem',
                  borderRadius: '8px',
                  padding: '1.5rem',
                  zIndex: 10,
                  fontSize: '14px',
                  whiteSpace: 'pre-wrap',
                }}
              >
                {tooltipText}
              </Tooltip>
            </>
          ) : null}
        </S.RightPayment>
      </S.PaymentMethod>
      {onClickCancel ? (
        <S.CancelWrapper>
          <S.CancelButton data-testid="cancelSubscriptionButton" onClick={onClickCancel}>
            Cancel Renewal
          </S.CancelButton>
        </S.CancelWrapper>
      ) : null}
    </S.Container>
  );
}
