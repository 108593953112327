import { Assets } from '../../../../utils/assets';
import { Image } from '../../../../types/utils/assets';

export function getGenrePreferenceImage(genres: string[]): Image {
  if (genres.length === 1 && genres.includes('Atmospheric')) {
    return Assets.images.firstActivityModal.genres.atmospheric;
  }
  if (genres.length === 2 && genres.includes('Atmospheric') && genres.includes('Electronic')) {
    return Assets.images.firstActivityModal.genres.atmosphericElectronic;
  }
  if (genres.includes('Electronic')) return Assets.images.firstActivityModal.genres.modernBeats;
  if (genres.includes('Atmospheric'))
    return Assets.images.firstActivityModal.genres.epicAmbientSpacey;
  if (genres.includes('Acoustic')) return Assets.images.firstActivityModal.genres.unplugged;
  if (genres.includes('Rain')) return Assets.images.firstActivityModal.genres.natureSoundscapes;
  return Assets.images.firstActivityModal.genres.modernBeats; // fallback to default image
}
