import { NOOP } from '@Globals';
import { cancelStripeSubscription } from '@Payment';
import { useRef, useState } from 'react';
import { forwardRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Transition } from 'react-transition-group';

import * as S from './CancelMembership.styles';
import { Cancellation } from './components/Cancellation';
import { Confirmation } from './components/Confirmation';
import { RootReducerType } from 'src/reducers';
import { useIsTrialUser } from '../../lenses';
import { MonthlyDowngrade } from './components/MonthlyDowngrade/MonthlyDowngrade';

export type CancellationOption = 'confirmation' | 'cancellation' | 'monthlyDowngrade';

interface Props {
  state: CancellationOption;
  isUpdatingMembership: boolean;
  onContinue: () => void;
  onSwitchToMonthlyPlan: () => void;
  onProceedToCancellation: (reason: string) => void;
  onClose: () => void;
  onBack: () => void;
}

export const CancelMembershipDisplay = forwardRef<HTMLDivElement, Props>(
  (
    {
      isUpdatingMembership,
      state,
      onClose = NOOP,
      onContinue = NOOP,
      onSwitchToMonthlyPlan = NOOP,
      onProceedToCancellation = NOOP,
      onBack = NOOP,
    },
    ref,
  ) => {
    const confirmationRef = useRef<HTMLDivElement>(null);
    const cancellationRef = useRef<HTMLDivElement>(null);
    const monthlyDowngradeRef = useRef<HTMLDivElement>(null);

    return (
      <S.Container ref={ref}>
        <Transition
          in={state === 'confirmation'}
          mountOnEnter
          nodeRef={confirmationRef}
          timeout={0}
          unmountOnExit
        >
          {state => {
            return (
              <Confirmation
                ref={confirmationRef}
                animationState={state}
                onClose={onClose}
                onContinue={onContinue}
              />
            );
          }}
        </Transition>

        <Transition
          in={state === 'monthlyDowngrade'}
          mountOnEnter
          nodeRef={monthlyDowngradeRef}
          timeout={0}
          unmountOnExit
        >
          {state => {
            return (
              <MonthlyDowngrade
                ref={monthlyDowngradeRef}
                animationState={state}
                isLoading={isUpdatingMembership}
                onBack={onBack}
                onClose={onClose}
                onProceedToCancellation={onContinue}
                onSwitchToMonthlyPlan={onSwitchToMonthlyPlan}
              />
            );
          }}
        </Transition>

        <Transition
          in={state === 'cancellation'}
          mountOnEnter
          nodeRef={cancellationRef}
          timeout={0}
          unmountOnExit
        >
          {state => {
            return (
              <Cancellation
                ref={cancellationRef}
                animationState={state}
                onBack={onBack}
                onClose={onClose}
                onContinue={onProceedToCancellation}
              />
            );
          }}
        </Transition>
      </S.Container>
    );
  },
);
