import styled from 'styled-components';

export const ContentContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border-radius: 12px;
  background: rgba(255, 255, 255, 0.1);
  padding: 24px;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    flex-direction: column;
  }
`;

export const UserPropertyContainer = styled.div`
  width: 100%;
  margin-bottom: 1.5rem;
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 1rem;
  flex-direction: column;
`;
