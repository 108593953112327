import styled, { css } from 'styled-components';
import InformationIconSVG from '../../../../assets/images/information_icon.svg?react';

export const Wrapper = styled.div`
  display: flex;
  height: 100%;
  width: 100%;
  flex-direction: column;
`;

export const Container = styled.div`
  display: flex;
  overflow: hidden;
  height: 100%;
  width: 100%;
  flex-direction: row;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    overflow: hidden auto;
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    height: calc(100vh - 4rem - 2rem); // deduct Paper padding and parent padding
  }
`;

export const Bottom = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  padding: 0px 40px 32px 40px;
  gap: 8px;
`;

export const GenresHeadingContainer = styled.div<{ isSideDeckOpen?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  margin-bottom: 1rem;
  gap: 1rem;

  ${({ theme, isSideDeckOpen }) => css`
    ${theme.mediaQuery.maxWidth.md} {
      flex-direction: column;
    }

    ${isSideDeckOpen && theme.mediaQuery.maxWidth.xxl} {
      flex-direction: column;
    }
  `}
`;

export const NelHeadingContainer = styled.div<{ isSideDeckOpen?: boolean }>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 1rem;
`;

export const HeaderLeft = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeadingContainerWithIcon = styled.div`
  display: flex;
  align-items: flex-start;
`;

export const HeaderTitle = styled.h2`
  color: #ffffff;
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: 1.25rem;
  line-height: 1.5rem;
  margin-bottom: 4px;
  padding: 0;
`;

export const HeaderDescription = styled.p`
  color: rgba(255, 255, 255, 0.75);
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 0.875rem;
  font-weight: 400;
  line-height: 1.25rem;
`;

export const InformationIcon = styled(InformationIconSVG)`
  margin-left: 0.5rem;
`;

export const Panel = styled.div`
  padding: 0 2.5rem;
  margin-top: 2.5rem;
  margin-bottom: 1rem;
  overflow: hidden auto;
  width: 50%;

  &::-webkit-scrollbar {
    width: 8px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(255, 255, 255, 0.1);
    border-radius: 6px;
    height: 10px;
  }

  &::-webkit-scrollbar-track {
    border-radius: 40px;
    background-color: rgba(255, 255, 255, 0.1);
  }

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    border-left: none;
    border-right: none;
    overflow: unset;
    padding-bottom: 0;
    width: 100%;
  }
`;

export const PanelMobile = styled(Panel)`
  display: none;
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    display: block;
  }
`;

export const PanelDesktop = styled(Panel)`
  display: block;
  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    display: none;
  }
`;

export const SectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  flex-grow: 1;
`;

export const AdhdModeContainer = styled.div`
  display: flex;
  gap: 8px;
  flex-direction: row;
  justify-content: space-between;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: rgba(13, 12, 17, 0.5);
  padding: 1rem;
  margin-bottom: 1rem;
  border-radius: 12px;
  align-items: center;
`;

export const AdhdTextContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const AdhdTitle = styled.h2`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: 1rem;
  line-height: 1.5rem;
  margin-bottom: 4px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

export const AdhdDescription = styled.p`
  color: rgba(255, 255, 255, 0.75);
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 0.875rem;
  line-height: 1.25rem;
`;
