import { createAction } from '@reduxjs/toolkit';
import { NavigateFunction } from 'react-router-dom';

export const setToken = createAction<string>('AUTH_TOKEN_SET');
export const tokenCheck = createAction('AUTH_TOKEN_CHECK');

// Log out action creators
export const signOut = createAction<{ shouldSkipConfirmation?: boolean } | undefined>(
  'AUTH_SIGNOUT',
);
export const signOutSuccess = createAction('AUTH_SIGNOUT_SUCCESS');
export const signOutFailed = createAction('AUTH_SIGNOUT_FAILED');

export interface EmailLoginPayload {
  values: {
    email: string;
    password: string;
  };
  navigate: NavigateFunction;
  successRedirectUrl: string;
}

export interface EmailSignupPayload {
  values: EmailLoginPayload['values'] & { name: string };
  navigate: NavigateFunction;
  successRedirectUrl: string;
  rid?: string;
}

export interface AppleSignupPayload {
  navigate: NavigateFunction;
  successRedirectUrl: string;
  rid?: string;
}
export interface AppleSigninPayload {
  navigate: NavigateFunction;
  successRedirectUrl: string;
}

export interface FacebookLoginPayload {
  userID: string;
  accessToken: string;
  navigate: NavigateFunction;
  successRedirectUrl: string;
  rid?: string;
}

export interface GoogleLoginPayload {
  accessToken: string;
  navigate: NavigateFunction;
  successRedirectUrl: string;
  rid?: string;
}

// Email/Password
export const emailLoginRequest = createAction<EmailLoginPayload>('AUTH_EMAIL_SIGNIN');
export const emailSignUpRequest = createAction<EmailSignupPayload>('AUTH_EMAIL_SIGNUP');

// Apple
export const appleLoginRequest = createAction<AppleSigninPayload>('AUTH_APPLE_LOGIN');
export const appleSignupRequest = createAction<AppleSignupPayload>('AUTH_APPLE_SIGNUP');

// Facebook
export const facebookLoginRequest = createAction<FacebookLoginPayload>('AUTH_FACEBOOK_LOGIN');
export const facebookSignupRequest = createAction<FacebookLoginPayload>('AUTH_FACEBOOK_SIGNUP');

// Google
export const googleLoginRequest = createAction<GoogleLoginPayload>('AUTH_GOOGLE_LOGIN');
export const googleSignupRequest = createAction<GoogleLoginPayload>('AUTH_GOOGLE_SIGNUP');

export const clearErrors = createAction('AUTH_CLEAR_ERRORS');
