import { FontColors } from '@Cortex';
import styled, { css, keyframes } from 'styled-components';

const WIDTH_OF_CURRENT_ACTIVITY_NAME = '9rem';
const HEIGHT_OF_CURRENT_ACTIVITY_NAME = '3rem';

const customProperties = `
  @property --gradient1 {
    syntax: '<color>';
    initial-value: rgba(255, 255, 255, 0.1);
    inherits: false;
  }

  @property --gradient2 {
    syntax: '<color>';
    initial-value: rgba(255, 255, 255, 0.01);
    inherits: false;
  }
`;

export const Panel = styled.div`
  ${customProperties};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  gap: 8px;
  border-radius: 12px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(180deg, var(--gradient1), var(--gradient2)) padding-box;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(25px);
  transition:
    --gradient1 0.3s ease-in-out,
    --gradient2 0.3s ease-in-out,
    border-color 0.3s ease-in-out;

  &:hover {
    --gradient1: rgba(255, 255, 255, 0.2);
    --gradient2: rgba(255, 255, 255, 0.08);
    border-color: rgba(255, 255, 255, 0.15);
  }
`;

export const Header = styled.span`
  color: ${FontColors.WhiteTransparent};
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
`;

export const Text = styled.span`
  color: white;
  flex: 1;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  margin-right: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  width: 100%;
`;

const FadeInAnimation = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;

export const ChevronIconContainer = styled.div<{ isDown?: boolean }>`
  transition: transform 0.3s ease-in-out;
  animation: ${FadeInAnimation} 0.2s ease-in-out;
`;

export const LoaderIconContainer = styled.div`
  opacity: 0.5;
  animation:
    rotate 1.2s infinite linear,
    ${FadeInAnimation} 0.2s ease-in-out;

  @keyframes rotate {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(359deg);
    }
  }
`;

export const TextContainer = styled.div`
  display: flex;
  transition: opacity 0.2s ease-in-out;
`;

export const Wrapper = styled.div`
  display: flex;
  cursor: pointer;
  height: 100%;
  width: 100%;
  min-height: ${HEIGHT_OF_CURRENT_ACTIVITY_NAME};
  overflow: hidden;
  border-radius: inherit;

  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    min-width: auto;
  }
`;

export const Selection = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  gap: 8px;
  flex: 1;
  min-width: ${WIDTH_OF_CURRENT_ACTIVITY_NAME};
`;

export const Skeleton = styled.div<{ isLoading?: boolean }>`
  display: flex;
  align-items: center;
  flex: 1;
  padding: 8px 16px 8px 16px;
  position: relative;
  overflow: hidden;

  ${({ isLoading }) => {
    if (isLoading) {
      return css`
        &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transform: translateX(-100%);
          background-image: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0,
            rgba(255, 255, 255, 0.2) 20%,
            rgba(255, 255, 255, 0.3) 60%,
            rgba(255, 255, 255, 0)
          );
          animation: shimmer 2s infinite;
          content: '';
        }

        @keyframes shimmer {
          100% {
            transform: translateX(100%);
          }
        }
      `;
    }
  }}
`;
