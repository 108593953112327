import { CoreAnalytics } from '@Analytics';
import { useEffect, useState, useRef } from 'react';

import BrandLogo from '../../../../assets/images/brand_logo.svg';
import { Assets } from '../../../../utils/assets';
import { getCommitmentExperienceFeatureFlag } from '../../../Utils/useAmplitudeExperiments';
import { OnboardingSteps } from '../../types';
import { Answer } from '../Answer';

import { CardWrapperTwo } from '../CardWrapperTwo';
import * as S from './CommitmentCard.styles';

export type Props = {
  onSetStep: (step: OnboardingSteps) => void;
};

export function CommitmentCard({ onSetStep }: Props) {
  const [holdProgress, setHoldProgress] = useState(0);
  const [selectedAnswer, setSelectedAnswer] = useState('30');
  const [buttonText, setButtonText] = useState('Click & Hold to Commit');
  const holdTimerRef = useRef<NodeJS.Timeout | null>(null);
  const startTimeRef = useRef<number>(0);

  const { value, payload } = getCommitmentExperienceFeatureFlag();

  const handleMouseDown = () => {
    startTimeRef.current = Date.now();

    holdTimerRef.current = setInterval(() => {
      const elapsed = Date.now() - startTimeRef.current;
      const progress = Math.min((elapsed / 3000) * 100, 100);
      setHoldProgress(progress);

      const elapsedSeconds = Math.ceil(elapsed / 1000);
      if (elapsedSeconds > 0) {
        setButtonText((elapsedSeconds - 1).toString());
      }

      if (progress >= 100) {
        handleComplete();
      }
    }, 10);
  };

  const handleMouseUp = () => {
    if (holdTimerRef.current) {
      clearInterval(holdTimerRef.current);
      holdTimerRef.current = null;
    }
    setHoldProgress(0);
    setButtonText('Click & Hold to Commit');
  };

  const handleComplete = () => {
    if (holdTimerRef.current) {
      clearInterval(holdTimerRef.current);
      holdTimerRef.current = null;
    }
    onSetStep(OnboardingSteps.Funbars);
    CoreAnalytics.trackOnboardingCommitmentCompleteEvent({
      variant: value,
      goal: selectedAnswer,
    });
  };

  useEffect(() => {
    return () => {
      if (holdTimerRef.current) {
        clearInterval(holdTimerRef.current);
      }
    };
  }, []);

  const title = value === '30-minutes' ? 'Commit to better focus' : "What's your daily focus goal?";
  const description =
    value === '30-minutes'
      ? 'Start with 30 minutes of uninterrupted focus for the best results.'
      : 'A small daily commitment will lead to big improvements over time';

  useEffect(() => {
    CoreAnalytics.trackOnboardingCommitmentImpressionEvent({
      variant: value,
    });
  }, []);

  return (
    <S.Container>
      <S.Content>
        <S.HeaderContainer>
          <S.Logo src={BrandLogo} />
        </S.HeaderContainer>
        <CardWrapperTwo height="auto">
          <S.ContentWrapper>
            <S.TitleWrapper>
              <S.Title>{title}</S.Title>
              <S.Description>{description}</S.Description>
            </S.TitleWrapper>

            {value === '30-minutes' ? (
              <S.TimerImageWrapper>
                <S.TimerImage alt="" src={Assets.images.timer30mins.url} />
              </S.TimerImageWrapper>
            ) : (
              <S.Answers>
                {(payload?.options || []).map(({ label, value }) => (
                  <Answer
                    key={value}
                    selected={value === selectedAnswer}
                    title={label}
                    onClick={() => setSelectedAnswer(value)}
                  />
                ))}
              </S.Answers>
            )}

            <S.HoldButtonContainer shifted={value === '30-minutes'}>
              <S.GradientRing progress={holdProgress} />
              <S.HoldButton
                isNumber={buttonText.length === 1}
                onContextMenu={e => e.preventDefault()}
                onMouseDown={handleMouseDown}
                onMouseLeave={handleMouseUp}
                onMouseUp={handleMouseUp}
                onTouchEnd={handleMouseUp}
                onTouchStart={handleMouseDown}
              >
                {buttonText}
              </S.HoldButton>
            </S.HoldButtonContainer>
          </S.ContentWrapper>
        </CardWrapperTwo>
      </S.Content>
    </S.Container>
  );
}
