import styled, { keyframes } from 'styled-components';

import { FontColors } from '@Cortex';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  isolation: isolate;
  padding: 2rem;
  justify-content: center;
  display: flex;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

export const ModalWrapper = styled.div`
  display: flex;
  align-self: center;
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  background-position-x: center;
  background-position-y: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 2rem;
`;

export const Image = styled.img`
  position: absolute;
  height: auto;
  align-self: center;
  z-index: 3;
  top: -12.5rem;
  width: 20rem;
`;

export const AuraImage = styled.img`
  position: absolute;
  border-radius: 10px;
  top: 0;
  width: 100%;
  height: 100%;
  align-self: center;
  z-index: 0;
  pointer-events: none;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 0.5rem;
  z-index: 1;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 20px;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1rem;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 16px;
  color: ${FontColors.WhiteTransparent};
  text-align: center;
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
  flex-direction: column;
  gap: 15px;
  max-width: 350px;
  margin: 2rem auto 0;
`;

export const ButtonContent = styled.div`
  display: flex;
  gap: 10px;
  justify-content: center;
  align-items: center;
`;

export const PlatformIcon = styled.img``;
