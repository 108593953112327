import styled, { css } from 'styled-components';

export const Container = styled.div``;

export const GenresContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  column-gap: 8px;
  margin-bottom: 2rem;
`;

export const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 0.75rem;
  text-transform: capitalize;
`;

export const GenreWrapper = styled.div`
  margin: 0.25rem 0;
  height: fit-content;
`;

export const Loading = styled.div`
  display: flex;
  width: 100%;
  height: fit-content;
  margin: 0.75rem 0 0.25rem;
  justify-content: center;
  align-items: center;

  &:after {
    animation: rotation 0.8s linear infinite;
    border-radius: 100%;
    border-right: 3px solid transparent;
    border-top: 3px solid rgba(255, 255, 255, 0.8);
    content: '';
    height: 2rem;
    position: relative;
    width: 2rem;
    display: inline-block;

    @keyframes rotation {
      from {
        transform: rotate(0deg);
      }
      to {
        transform: rotate(360deg);
      }
    }
  }
`;

export const GenreFilterContainer = styled.div`
  display: flex;
  gap: 8px;
`;

export const GenreFilter = styled.div<{ isActive: boolean }>`
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: 10px;
  letter-spacing: 0.1em;
  text-transform: uppercase;
  cursor: pointer;
  padding: 6px 8px;
  border-radius: 1rem;
  transition:
    color 0.2s,
    box-shadow 0.2s,
    background-color 0.2s;

  ${({ isActive }) => {
    if (isActive) {
      return css`
        color: #ffffff;
        box-shadow: 0px 0px 0px 1px rgba(255, 255, 255, 0.1) inset;
        background-color: rgba(255, 255, 255, 0.1);
      `;
    } else {
      return css`
        color: rgba(255, 255, 255, 0.5);
      `;
    }
  }}

  &:hover {
    color: #ffffff;
  }
`;
