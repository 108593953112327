import { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { milestonesSliceActions } from '../../../../../reducers/milestones';
import { useMilestonesProgressCompleted } from './useMilestonesProgressCompleted';

export const useCloseMilestonesProgressModal = () => {
  const dispatch = useDispatch();
  const allMilestonesCompleted = useMilestonesProgressCompleted();

  return useCallback(() => {
    dispatch(milestonesSliceActions.resetModal());

    if (allMilestonesCompleted) {
      dispatch(milestonesSliceActions.dismissWidget());
    }
  }, [dispatch, allMilestonesCompleted]);
};
