import { DynamicActivity } from '@Model';

export const getActivityDescription = (activity?: DynamicActivity | null) => {
  if (!activity) {
    return 'Designed for sustained periods of intense focus';
  }

  switch (activity.displayValue) {
    case 'Deep Work':
      return 'Designed for sustained periods of intense focus.';
    case 'Learning':
      return 'Designed for knowledge retention.';
    case 'Creativity':
      return 'Designed for creative design, ideation, or expression.';
    case 'Light Work':
      return 'Designed for completing multiple tasks in quick succession.';
    case 'Deep Sleep':
      return 'Designed to promote healthy and prolonged rest.';
    case 'Power Nap':
      return 'Designed for restorative naps that recharge your energy.';
    case 'Destress':
      return 'Helps you shift from tension to relaxation.';
    case 'Unwind':
      return 'Enter into relaxation after a period of work or intense focus.';
    case 'Recharge':
      return 'Designed for you to refocus, recenter, and gain energy.';
    case 'Chill':
      return 'Designed to engage you and accompany many relaxing activities';
    case 'Unguided':
      return 'Reach deep levels of meditation without guided narration.';
    case 'Guided':
      return 'Meditate in response to guidance by trained practitioners.';
    default:
      return activity.description;
  }
};
