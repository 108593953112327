import { forwardRef } from 'react';
import * as S from './styles';
import { Button, ButtonVariants } from '@Cortex';
import AppleIconBlack from './assets/apple_icon_black.svg';

export const DownloadMacApp = forwardRef<HTMLDivElement>((_, ref) => {
  return (
    <S.Container ref={ref}>
      <S.TopTextContainer>
        <S.HeaderText>Try our macOS</S.HeaderText>
        <S.HeaderText>Desktop App</S.HeaderText>
      </S.TopTextContainer>

      <S.Image src="https://cdn.brain.fm/images/desktop_menubar_app.webp" />

      <S.DescriptionContainer>
        <S.Description>
          Get the full Brain.fm experience with our easy-to-use desktop app—quick access from your
          Mac menu bar for effortless focus control.
        </S.Description>
      </S.DescriptionContainer>

      <S.ButtonWrapper>
        <Button
          iconSrc={AppleIconBlack}
          iconStyle={{ marginTop: -2 }}
          isFullWidth={true}
          keepTextCase={true}
          style={{ fontSize: '0.75rem' }}
          variant={ButtonVariants.Secondary}
          onClick={() => {
            window.open('https://www.brain.fm/download', '_blank')?.focus();
          }}
        >
          DOWNLOAD BRAIN.FM FOR MACOS
        </Button>
      </S.ButtonWrapper>
    </S.Container>
  );
});
