import { useToken } from '@Authentication';
import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { ProfileSection, SideDeckTab } from '../../../types';

export function WebUpgradeIntentionsHandler() {
  const navigate = useNavigate();
  const token = useToken();

  useEffect(() => {
    navigate(token ? '/' : '/signin', {
      replace: true,
      state: token
        ? { sideDeckTab: SideDeckTab.Profile, profileSection: ProfileSection.Subscription }
        : { intentionDestination: '/intentions/webUpgrade' },
    });
  }, []);

  return null;
}
