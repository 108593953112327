import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';
import { milestonesSliceActions } from '../../../../../reducers/milestones';
import { Analytics } from '../../../../../utils/analytics';
import { useIsMilestonesWidgetEnabled } from './useIsMilestonesWidgetEnabled';
import { useMilestonesWidgetModalHandler } from './useMilestonesWidgetModalHandler';
import { getTrackId, getTrackName, getTrackDynamicMentalStateDisplayValue } from '@Music';

export const useFavoritesHandler = () => {
  const isEnabled = useIsMilestonesWidgetEnabled();
  const dispatch = useDispatch();
  const milestonesWidgetModalHandler = useMilestonesWidgetModalHandler();
  const favoritesLength = useSelector((state: RootReducerType) => state.music.favorites.length);
  const favorites = useSelector((state: RootReducerType) => state.milestones.items.favorites);
  const favoriteTracks = useSelector((state: RootReducerType) => state.music.favorites);
  const uninterruptedListening = useSelector(
    (state: RootReducerType) => state.milestones.items.uninterruptedListening,
  );

  useEffect(() => {
    if (favorites.completed || !isEnabled) return;
    if (favoritesLength >= favorites.target) {
      dispatch(milestonesSliceActions.completeFavorites());
      milestonesWidgetModalHandler({
        title: `You've favorited ${favorites.target} tracks!`,
        description: `Keep up the momentum and complete your ${uninterruptedListening.targetMinutes} minutes of listening.`,
        buttonText: 'KEEP LISTENING',
        icon: undefined,
      });

      // Log completion event
      Analytics.logEventWithProperties('test_checklist_favoriting_complete', {
        completed: true,
        target: favorites.target,
      });

      // Set user properties for each favorited track
      favoriteTracks.forEach((track, index) => {
        Analytics.setUserProperty(`lifecycle_favorite_track_name_${index}`, getTrackName(track));
        Analytics.setUserProperty(
          `lifecycle_favorite_track_mental_state_${index}`,
          getTrackDynamicMentalStateDisplayValue(track),
        );
        Analytics.setUserProperty(
          `lifecycle_favorite_track_link_${index}`,
          `https://my.brain.fm/player/similar_${getTrackId(track)}`,
        );
      });
    }
  }, [
    favoritesLength,
    favorites.completed,
    favorites.target,
    favoriteTracks,
    milestonesWidgetModalHandler,
    dispatch,
    isEnabled,
  ]);
};
