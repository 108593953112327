import { CoreAnalytics } from '@Analytics';
import { useCallback, useEffect, useRef, useState, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { useIsMilestonesWidgetEnabled } from '../../components/session/components/MilestonesWidget/hooks/useIsMilestonesWidgetEnabled';
import { milestonesSliceActions } from '../../reducers/milestones';

import { Analytics } from '../../utils/analytics';
import { OnboardingEvents } from '../../utils/analytics/events';
import { useWebMilestonesExperiment } from '../Utils/useAmplitudeExperiments';
import { CommitmentCard } from './components/CommitmentCard';
import { OnboardingCards } from './containers/OnboardingCards';
import { OnboardingMentalStates } from './containers/OnboardingMentalStates';
import { QuizCards } from './containers/QuizCards';
import { ResultCards } from './containers/ResultCards';
import { useOnboardingProcessedData } from './hooks/useOnboardingProcessedData';
import { FunBarsCard } from './components/FunBarsCard';
import {
  ONBOARDING_MENTAL_STATE_ID_KEY,
  ONBOARDING_DYNAMIC_ACTIVITY_ID_KEY,
  ONBOARDING_CUSTOM_PROPERTY_KEY,
  ONBOARDING_VARIATION_ID_KEY,
} from './constants';
import { useDispatch } from 'react-redux';
import {
  OnboardingQuizAnswer,
  OnboardingSpecification,
  OnboardingSteps,
  OnboardingTestimonials,
} from './types';
import { processOnboardingSpecification } from './utils/processOnboardingSpecification';
import { getOnboardingSpecs } from './utils/getOnboardingSpecs';
import { IntroCard } from './components/IntroCard';

export const OnboardingContainer = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const [step, setStep] = useState<OnboardingSteps>(OnboardingSteps.MentalStates);
  const [onboardingMusicUrl, setOnboardingMusicUrl] = useState<string | null>(null);
  const [onboardingSpecification, setOnboardingSpecification] = useState<OnboardingSpecification>({
    cards: [],
    id: '',
  });
  const webMilestonesExperimentData = useWebMilestonesExperiment();
  const isMilestonesWidgetEnabled = useIsMilestonesWidgetEnabled();

  const quizAnswers = useRef<number[]>([]);
  const quizTestimonials = useRef<OnboardingTestimonials[]>([]);
  const quizBenefits = useRef<string[]>([]);
  const { isLoading, onboardingSpecification: onboardingSpecificationRaw } =
    useOnboardingProcessedData();
  const isInitialOnboarding = location.state?.isInitial;

  useEffect(() => {
    // setting user property here for faster data processing on braze & amplitude side
    if (isInitialOnboarding && webMilestonesExperimentData?.isInTestGroup) {
      Analytics.setUserProperty('ab_checklist', webMilestonesExperimentData?.value !== 'control');

      // setting hasTakenOnboardingWhileInNonControlTest only when the user is in the test group (non-control) during the initial onboarding
      if (webMilestonesExperimentData?.value !== 'control') {
        dispatch(milestonesSliceActions.setOnboardingTaken());
      }
    }
  }, [dispatch, webMilestonesExperimentData, isInitialOnboarding]);

  const handleSetStep = useCallback(
    (step: OnboardingSteps) => {
      setStep(step);
    },
    [setStep],
  );

  const handleSelectAnswer = useCallback(
    (
      questionIndex: number,
      answerIndex: number,
      testimonials?: OnboardingQuizAnswer['testimonials'],
      benefits?: OnboardingQuizAnswer['benefits'],
      dynamicActivityId?: OnboardingQuizAnswer['dynamicActivityId'],
      userPropertyToSet?: OnboardingQuizAnswer['userPropertyToSet'],
    ) => {
      quizAnswers.current[questionIndex] = answerIndex;

      if (testimonials) {
        quizTestimonials.current = [...quizTestimonials.current, ...testimonials];
      }

      if (benefits) {
        quizBenefits.current = [...quizBenefits.current, ...benefits];
      }

      if (dynamicActivityId) {
        sessionStorage.setItem(ONBOARDING_DYNAMIC_ACTIVITY_ID_KEY, dynamicActivityId);
      }

      // we set user properties only during the initial onboarding
      if (userPropertyToSet && isInitialOnboarding) {
        Analytics.setUserProperties({
          [userPropertyToSet.key]: userPropertyToSet.value,
        });

        if (
          userPropertyToSet.key === ONBOARDING_CUSTOM_PROPERTY_KEY &&
          userPropertyToSet.value === true
        ) {
          localStorage.setItem(ONBOARDING_CUSTOM_PROPERTY_KEY, `${userPropertyToSet.value}`);
        }
      }
    },
    [quizAnswers, isInitialOnboarding],
  );

  const handleSkipStep = () => {
    setStep(OnboardingSteps.Funbars);
  };

  const onCompleteStep = useCallback(
    (neurotype_id?: string) => {
      switch (step) {
        case OnboardingSteps.Funbars: {
          CoreAnalytics.trackOnboardingCompleteEvent({
            variation: onboardingSpecification.id,
            neurotypeId: neurotype_id || '',
          });
          Analytics.logEventWithProperties(OnboardingEvents.onboarding_complete, {
            onboarding_variation: onboardingSpecification.id,
            neurotype_id: neurotype_id || '',
          });
          break;
        }
        default:
          break;
      }
    },
    [onboardingSpecification, step],
  );

  const handleMentalStateSelect = (mentalStateId: string) => () => {
    sessionStorage.setItem(ONBOARDING_MENTAL_STATE_ID_KEY, mentalStateId);
    CoreAnalytics.trackOnboardingMentalStateSelectEvent({ mentalState: mentalStateId });

    if (isInitialOnboarding) {
      Analytics.setUserProperties({
        core_onboarding_first_session: mentalStateId,
      });
    }

    const onboardingSpecs = getOnboardingSpecs(mentalStateId, onboardingSpecificationRaw);
    setOnboardingSpecification(processOnboardingSpecification(onboardingSpecs.specification));
    sessionStorage.setItem(ONBOARDING_VARIATION_ID_KEY, onboardingSpecs.specification.id);

    if (mentalStateId === 'focus') {
      setStep(OnboardingSteps.Intro);
    } else {
      setStep(OnboardingSteps.Cards);
    }
  };

  const handleIntroContinue = () => {
    setStep(OnboardingSteps.Cards);
  };
  return (
    <>
      {step === OnboardingSteps.MentalStates && (
        <OnboardingMentalStates onSelect={handleMentalStateSelect} />
      )}
      {step === OnboardingSteps.Intro && <IntroCard onContinue={handleIntroContinue} />}
      {step === OnboardingSteps.Cards && (
        <OnboardingCards
          isLoading={isLoading}
          onboardingSpecification={onboardingSpecification}
          onCompleteStep={onCompleteStep}
          onSetStep={handleSetStep}
          onSkip={handleSkipStep}
        />
      )}
      {step === OnboardingSteps.Quiz && (
        <QuizCards
          isLoading={isLoading}
          onboardingSpecification={onboardingSpecification}
          onSelectAnswer={handleSelectAnswer}
          onSetStep={handleSetStep}
          onSkip={handleSkipStep}
        />
      )}
      {step === OnboardingSteps.Results && (
        <ResultCards
          onboardingSpecification={onboardingSpecification}
          quizAnswers={quizAnswers.current}
          onMusicUrlReceived={setOnboardingMusicUrl}
          onSetStep={handleSetStep}
        />
      )}
      {step === OnboardingSteps.Commitment && <CommitmentCard onSetStep={handleSetStep} />}
      {step === OnboardingSteps.Funbars && (
        <FunBarsCard
          onboardingMusicUrl={onboardingMusicUrl}
          paywallBenefits={quizBenefits.current.length !== 0 ? quizBenefits.current : undefined}
          paywallTestimonials={
            quizTestimonials.current.length !== 0 ? quizTestimonials.current : undefined
          }
          onCompleteStep={onCompleteStep}
        />
      )}
    </>
  );
};
