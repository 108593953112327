import { EffectLevels } from '@Model';

export function getNeuralEffectLevelSelectionDescription(
  nel: EffectLevels,
  mentalStateId?: string,
): string {
  switch (nel) {
    case EffectLevels.Low:
      return 'Use this effect level if you are generally sensitive to sounds, or if the higher effect levels feel too intense.';
    case EffectLevels.Medium:
      return 'Our standard level of neural phase locking is a great place to start. Be sure to try the other levels to find what works best for you!';
    case EffectLevels.High:
      switch (mentalStateId) {
        case 'focus':
          return 'Use the strongest level of our neural phase locking technology if you need extra stimulation, or have attentional challenges (ADHD or similar).';
        case 'relax':
          return 'Use the strongest level for deep relaxation, easing stress and tension.';
        case 'sleep':
          return 'Use the strongest level for deeper sleep, quieting a racing mind for uninterrupted rest.';
        case 'meditate':
          return 'Use the strongest level for immersive meditation, enhancing focus and stillness.';
        default:
          return 'Use the strongest level of our neural phase locking technology if you need extra stimulation, or have attentional challenges (ADHD or similar).';
      }
  }
}
