import { RootReducerType } from '../reducers';

export function getMonthlySubscriptionIdFromPlans(
  plans: RootReducerType['membershipPlans'],
): number | null {
  return (
    plans.plans.ids.find((id: number) => {
      return plans.plans.results[id]?.title === 'Monthly';
    }) || null
  );
}
