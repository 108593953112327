import { Elements } from '@stripe/react-stripe-js';

import stripe, { ELEMENT_OPTIONS } from '../../../../utils/stripe';
import {
  getPaywallVariationFeatureFlag,
  useADHDExperimentData,
} from '../../../Utils/useAmplitudeExperiments';
import { usePaywallGateway } from './hooks/usePaywallGateway';
import { OnboardingTestimonials } from '../../../Onboarding/types';
import { useTrackSubscriptionImpression } from './hooks/useTrackSubscriptionImpression';
import { useEffect } from 'react';
import { Analytics } from '../../../../utils/analytics';
import { SingleScreenSlideUpPaywall } from './variations/SingleScreenSlideUpPaywall';
import { ExpressCheckoutPaywall } from './variations/ExpressCheckoutPaywall';
import { ADHDExperimentOne } from './variations/ADHDExperimentOne/ExperimentOne.container';
import { ADHDExperimentTwo } from './variations/ADHDExperimentTwo/container';
import { ADHDExperimentThree } from './variations/ADHDExperimentThree/container';
import { ONBOARDING_CUSTOM_PROPERTY_KEY } from '../../../../domains/Onboarding/constants';

type Props = {
  paywallBenefits?: string[];
  paywallTestimonials?: OnboardingTestimonials[];
  onPaymentComplete: () => void;
  onClose: () => void;
};

export function PaywallManagerContainer(props: Props) {
  usePaywallGateway();
  useTrackSubscriptionImpression();
  const { data: adhdExperimentData, isLoading: isAdhdExperimentLoading } = useADHDExperimentData();
  const paywallVariationFlag = getPaywallVariationFeatureFlag();
  const customProperty = localStorage.getItem(ONBOARDING_CUSTOM_PROPERTY_KEY);

  useEffect(() => {
    Analytics.setUserProperty(paywallVariationFlag, true);
  }, []);

  if (paywallVariationFlag === 'express_checkout') {
    return (
      <Elements
        options={{ mode: 'setup', currency: 'usd', amount: 0, paymentMethodCreation: 'manual' }}
        stripe={stripe.getInstance()}
      >
        <ExpressCheckoutPaywall
          paywallBenefits={props.paywallBenefits}
          paywallTestimonials={props.paywallTestimonials}
          onClose={props.onClose}
          onPaymentComplete={props.onPaymentComplete}
        />
      </Elements>
    );
  }

  if (customProperty === 'true' && adhdExperimentData?.value === 'variant_one') {
    return (
      <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
        <ADHDExperimentOne
          paywallBenefits={props.paywallBenefits}
          paywallTestimonials={props.paywallTestimonials}
          onClose={props.onClose}
          onPaymentComplete={props.onPaymentComplete}
        />
      </Elements>
    );
  }

  if (customProperty === 'true' && adhdExperimentData?.value === 'variant_four') {
    return (
      <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
        <ADHDExperimentTwo
          paywallBenefits={props.paywallBenefits}
          paywallTestimonials={props.paywallTestimonials}
          onClose={props.onClose}
          onPaymentComplete={props.onPaymentComplete}
        />
      </Elements>
    );
  }

  if (customProperty === 'true' && adhdExperimentData?.value === 'variant_five') {
    return (
      <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
        <ADHDExperimentThree
          paywallBenefits={props.paywallBenefits}
          paywallTestimonials={props.paywallTestimonials}
          onClose={props.onClose}
          onPaymentComplete={props.onPaymentComplete}
        />
      </Elements>
    );
  }

  if (!isAdhdExperimentLoading) {
    return (
      <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
        <SingleScreenSlideUpPaywall
          paywallBenefits={props.paywallBenefits}
          paywallTestimonials={props.paywallTestimonials}
          onClose={props.onClose}
          onPaymentComplete={props.onPaymentComplete}
        />
      </Elements>
    );
  }

  return null;
}
