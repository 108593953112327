import { PresetGenresTypes } from '../types';
import { PRESET_GENRES } from '../constants';
import { getSelectedPresets } from './getSelectedPresets';

/**
 * Handles the selection of a genre preset.
 * - If the preset is already selected, removes its genres from selection
 * - If there are already fully selected presets, adds the genres from the new preset to the existing ones
 * - If there are no selected presets (no genres selected or only partially selected),
 *   replaces existing genres with the genres from the selected preset
 *
 * @param presetId ID of the preset to select
 * @param selectedGenres Current list of selected genres
 * @returns New list of selected genres
 */
export const getUpdatedGenresForPreset = (
  presetId: PresetGenresTypes,
  selectedGenres: string[],
): string[] => {
  // Get genres of the selected preset
  const presetGenres = PRESET_GENRES[presetId] || [];

  // Check if there are already fully selected presets
  const existingSelectedPresets = getSelectedPresets(selectedGenres);
  const hasSelectedPresets = existingSelectedPresets.length > 0;

  // Check if the clicked preset is already selected
  const isPresetAlreadySelected = existingSelectedPresets.includes(presetId);

  // If the preset is already selected, remove its genres
  if (isPresetAlreadySelected) {
    // Keep only genres that are not part of the clicked preset
    return selectedGenres.filter(genre => !presetGenres.includes(genre));
  }

  // If there are already selected presets, add new genres to existing ones
  if (hasSelectedPresets) {
    // Add only genres that don't already exist in the selected genres
    const newGenres = presetGenres.filter(genre => !selectedGenres.includes(genre));

    return [...selectedGenres, ...newGenres];
  }

  // If there are no selected presets, replace all genres with genres from the selected preset
  return [...presetGenres];
};
