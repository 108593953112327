import { DEFAULT_ONBOARDING } from '../constants';
import { OnboardingSpecification } from '../types';
import {
  useOnboardingFocusExperimentData,
  useOnboardingMeditateExperimentData,
  useOnboardingRelaxExperimentData,
  useOnboardingSleepExperimentData,
} from '../../../domains/Utils/useAmplitudeExperiments';

export const useOnboardingProcessedData = () => {
  const { data: focusData, isLoading: isFocusLoading } = useOnboardingFocusExperimentData();
  const { data: relaxData, isLoading: isRelaxLoading } = useOnboardingRelaxExperimentData();
  const { data: sleepData, isLoading: isSleepLoading } = useOnboardingSleepExperimentData();
  const { data: meditateData, isLoading: isMeditateLoading } =
    useOnboardingMeditateExperimentData();

  const isLoading = isFocusLoading || isRelaxLoading || isSleepLoading || isMeditateLoading;

  return {
    onboardingSpecification: {
      focus: focusData.cards.length || focusData.quizSpecification ? focusData : DEFAULT_ONBOARDING,
      relax: relaxData,
      sleep: sleepData,
      meditate: meditateData,
    },
    isLoading,
  };
};
