import { useSelector } from 'react-redux';
import { useGenreNamesByDynamicActivity } from '../../../../api/modules/GenreNamesByDynamicActivity';
import { RootReducerType } from '../../../../reducers';
import { Assets } from '../../../../utils/assets';
import { PresetGenresTypes } from '../../../Session/components/Preferences/types';
import { PRESET_GENRES } from '../../../Session/components/Preferences/constants';
import * as S from './PresetGenres.styles';

const presetsList = [
  {
    id: PresetGenresTypes.ModernBeats,
    title: 'Modern Beats',
    description: 'Electronic, Grooves, Lofi',
    image: Assets.images.preselectedGenres.modernBeats.url,
    mobileImage: Assets.images.preselectedGenres.modernBeatsMobile.url,
  },
  {
    id: PresetGenresTypes.EpicAmbientSpacey,
    title: 'Epic, Ambient, and Spacey',
    description: 'Atmospheric, Drone, Post-Rock, Cinematic',
    image: Assets.images.preselectedGenres.epicAmbientSpacey.url,
    mobileImage: Assets.images.preselectedGenres.epicAmbientSpaceyMobile.url,
  },
  {
    id: PresetGenresTypes.Unplugged,
    title: 'Unplugged',
    description: 'Acoustic, Piano, Classical',
    image: Assets.images.preselectedGenres.unplugged.url,
    mobileImage: Assets.images.preselectedGenres.unpluggedMobile.url,
  },
  {
    id: PresetGenresTypes.NatureSoundscapes,
    title: 'Nature Soundscapes',
    description: 'Rain, Beach, Thunder, Forest, and More',
    image: Assets.images.preselectedGenres.natureSoundscapes.url,
    mobileImage: Assets.images.preselectedGenres.natureSoundscapesMobile.url,
  },
];

type PresetGenresProps = {
  selectedPresets: PresetGenresTypes[];
  onGenreClick: (genreId: PresetGenresTypes) => void;
  activityId?: string;
};

export const PresetGenres = ({ activityId, selectedPresets, onGenreClick }: PresetGenresProps) => {
  const { data, isLoading } = useGenreNamesByDynamicActivity(activityId);
  const isSideDeckOpen = useSelector((state: RootReducerType) => state.ui.sideDeck.isOpen);

  // Create a Set of all available genres for the current activity
  const availableGenres = new Set([
    ...(data?.baseGenres?.map(genre => genre.name) || []),
    ...(data?.natureGenres?.map(genre => genre.name) || []),
  ]);

  // Filter presets to show only those where all genres are available
  const filteredPresets = presetsList.filter(preset => {
    // Get array of genres for this preset from PRESET_GENRES object
    const presetGenres = PRESET_GENRES[preset.id];
    // Check if all genres in the preset are available
    return presetGenres.every(genre => availableGenres.has(genre));
  });

  if (isLoading) return <S.Loading />;

  return (
    <S.Container isSideDeckOpen={isSideDeckOpen}>
      {filteredPresets.map(genre => (
        <S.Genre
          key={genre.id}
          isSideDeckOpen={isSideDeckOpen}
          selected={selectedPresets.includes(genre.id)}
          onClick={() => onGenreClick(genre.id)}
        >
          <S.Selected selected={selectedPresets.includes(genre.id)}>
            <S.CheckmarkIcon src={Assets.icons.checkmarkBlack.url} />
          </S.Selected>
          <S.MobileImage
            alt={genre.title}
            isSideDeckOpen={isSideDeckOpen}
            src={genre.mobileImage}
          />
          <S.Image alt={genre.title} isSideDeckOpen={isSideDeckOpen} src={genre.image} />
          <S.Title isSideDeckOpen={isSideDeckOpen}>{genre.title}</S.Title>
          <S.Description isSideDeckOpen={isSideDeckOpen}>{genre.description}</S.Description>
        </S.Genre>
      ))}
    </S.Container>
  );
};
