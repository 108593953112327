export const getSessionHeaderTitle = (mentalState: string | null): string => {
  if (!mentalState) {
    return 'Maximize your focus with Brain.fm'; // Fallback to focus if no mental state is provided
  }

  switch (mentalState) {
    case 'focus':
      return 'Maximize your focus with Brain.fm';
    case 'relax':
      return 'Deepen your relaxation with Brain.fm';
    case 'sleep':
      return 'Achieve better sleep with Brain.fm';
    case 'meditate':
      return 'Deepen your meditation with Brain.fm';
    default:
      return 'Maximize your focus with Brain.fm';
  }
};
