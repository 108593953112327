import {
  Button,
  ButtonVariants,
  ButtonVariantStates,
  Switch,
  Panel,
  ButtonVariantSizes,
} from '@Cortex';
import React, { forwardRef, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { RootReducerType } from 'src/reducers';

import { setPlayerAnimationState } from '../../../actions/ui';
import { Analytics } from '../../../utils/analytics';
import { Events } from '../../../utils/analytics/events';
import { updateUserPreferences } from '../actions';
import { StreakTypes } from '../reducer';
import * as S from './Settings.styles';

export const Settings = forwardRef<HTMLDivElement>((_, ref) => {
  const isAnimationEnabled = useSelector(
    (state: RootReducerType) => state.ui.isPlayerAnimationEnabled,
  );
  const isStreakCountDisplayType = useSelector(
    (state: RootReducerType) => state.userV2.preferences.streakType,
  );
  const isStreakCountEnabled =
    !isStreakCountDisplayType || isStreakCountDisplayType === StreakTypes.Weekly;
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleSwitchAnimation = () => {
    dispatch(setPlayerAnimationState(!isAnimationEnabled));
    if (isAnimationEnabled) {
      Analytics.logEvent('profile_settings_disable_animations');
    } else {
      Analytics.logEvent('profile_settings_enable_animations');
    }
  };

  const handleSwitchStreakDisplay = useCallback(() => {
    const streakType =
      isStreakCountDisplayType === StreakTypes.Weekly || !isStreakCountDisplayType
        ? StreakTypes.Hidden
        : StreakTypes.Weekly;

    dispatch(
      updateUserPreferences({
        streakType,
      }),
    );

    Analytics.logEventWithProperties(Events.core_streak_toggle, {
      core_streak_type: streakType === StreakTypes.Weekly ? 'weekly' : 'none',
    });
  }, [dispatch, updateUserPreferences, isStreakCountDisplayType]);

  const onInitiateOnboarding = useCallback(() => {
    Analytics.logEvent('core_settings_personalize_clicked');
    navigate('/welcome', {
      state: { origin: 'settings', isInitial: false },
    });
  }, [navigate]);

  return (
    <S.Container ref={ref}>
      <Panel>
        {/*<S.Content>*/}
        {/*  <S.ToggleContainer>*/}
        {/*    <S.Text>Enable Player Animations</S.Text>*/}
        {/*    <Switch isActive={isAnimationEnabled} size="large" onClick={handleSwitchAnimation} />*/}
        {/*  </S.ToggleContainer>*/}
        {/*</S.Content>*/}
        {/*<S.Divider />*/}
        <S.Content>
          <S.ToggleContainer>
            <S.Text>Display Streak Count</S.Text>
            <Switch
              data-testid="streaksSwitcher"
              isActive={isStreakCountEnabled}
              size="large"
              onClick={handleSwitchStreakDisplay}
            />
          </S.ToggleContainer>
        </S.Content>
        <S.Divider />
        <S.Content>
          <S.ToggleContainer>
            <S.Text>Personalize My Music</S.Text>
            <Button
              keepTextCase={true}
              size={ButtonVariantSizes.Small}
              style={{ fontSize: '0.75rem' }}
              variant={ButtonVariants.Secondary}
              variantState={ButtonVariantStates.Idle}
              onClick={onInitiateOnboarding}
            >
              PERSONALIZE
            </Button>
          </S.ToggleContainer>
        </S.Content>
      </Panel>
    </S.Container>
  );
});
