import { Text, TextBold } from '@Cortex';
import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const Content = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 400px;
  margin: 0 auto;
`;

export const BottomPart = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  text-align: center;
  margin-top: 280px;
  z-index: 1;
`;
export const Highlighted = styled.span`
  color: white;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
`;

export const Image = styled.img`
  position: absolute;
  width: 120%;
  top: -70px;
  left: 50%;
  transform: translateX(-50%);
  ${({ theme }) => theme.mediaQuery.maxWidth.sm} {
    top: -52px;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
`;
export const ButtonWrapper = styled.div`
  margin: 1rem 0 0;
  width: 100%;
`;

export const TitleText = styled(Text)`
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 40px;
  line-height: 44px;
  letter-spacing: -0.4px;
  margin-bottom: 1rem;
`;

export const SubtitleText = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  margin-bottom: 1rem;
  color: rgba(255, 255, 255, 0.75);
`;

export const ButtonImage = styled.img`
  height: 1rem;
  width: 1rem;
  margin-right: 0.5rem;
`;
