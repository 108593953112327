import { ReactNode, useEffect, useState } from 'react';
import { useMeasure } from 'react-use';
import * as S from './MarqueeText.styles';
import {
  MARQUEE_TEXT_ANIMATION_ADDITIONAL_SCROLL_BUFFER_IN_PIXELS,
  MARQUEE_TEXT_ANIMATION_LENGTH_IN_SECONDS,
  MARQUEE_TEXT_ANIMATION_START_DELAY_IN_SECONDS,
} from './MarqueeText.constants';

export type Props = {
  additionalScrollBufferInPixels?: number;
  animationLengthInSeconds?: number;
  animationStartDelayInSeconds?: number;
  animationBlocked?: boolean;
  children: ReactNode;
};

export function MarqueeText({
  additionalScrollBufferInPixels = MARQUEE_TEXT_ANIMATION_ADDITIONAL_SCROLL_BUFFER_IN_PIXELS,
  animationLengthInSeconds = MARQUEE_TEXT_ANIMATION_LENGTH_IN_SECONDS,
  animationStartDelayInSeconds = MARQUEE_TEXT_ANIMATION_START_DELAY_IN_SECONDS,
  animationBlocked = false,
  children = '',
}: Props) {
  const [distanceFromRight, setDistanceFromRight] = useState(0);
  const [containerRef, containerMeasurements] = useMeasure<HTMLDivElement>();
  const [textRef, textMeasurements] = useMeasure<HTMLDivElement>();

  function startAnimation() {
    let startGoingLeftInterval: NodeJS.Timeout | undefined;
    let comeBackRightInterval: NodeJS.Timeout | undefined;
    let restartInterval: NodeJS.Timeout | undefined;

    const startGoingLeftTimeInMilliseconds = animationStartDelayInSeconds * 1000;
    const comeBackRightTimeInMilliseconds =
      startGoingLeftTimeInMilliseconds + (animationLengthInSeconds / 2) * 1000;
    const restartTimeInMilliseconds =
      comeBackRightTimeInMilliseconds + (animationLengthInSeconds / 2) * 1000;

    const maximumShift =
      textMeasurements.width - containerMeasurements.width + additionalScrollBufferInPixels;

    setDistanceFromRight(0);
    startGoingLeftInterval = setTimeout(
      () => setDistanceFromRight(maximumShift),
      startGoingLeftTimeInMilliseconds,
    );
    comeBackRightInterval = setTimeout(
      () => setDistanceFromRight(0),
      comeBackRightTimeInMilliseconds,
    );
    restartInterval = setTimeout(startAnimation, restartTimeInMilliseconds);

    return function stopAnimation() {
      clearTimeout(startGoingLeftInterval);
      clearTimeout(comeBackRightInterval);
      clearTimeout(restartInterval);
      setDistanceFromRight(0);
    };
  }

  useEffect(() => {
    const shouldAnimate = textMeasurements.width > containerMeasurements.width && !animationBlocked;
    if (shouldAnimate) return startAnimation();
  }, [textMeasurements.width, containerMeasurements.width, animationBlocked]);

  return (
    <S.MarqueeTextContainer ref={containerRef}>
      <S.MarqueeTextScrollingText ref={textRef} distanceFromRight={distanceFromRight}>
        {children}
      </S.MarqueeTextScrollingText>
    </S.MarqueeTextContainer>
  );
}
