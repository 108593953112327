import { NOOP } from '@Globals';
import { ButtonHTMLAttributes } from 'react';

import * as S from './Button.styles';

export enum Variants {
  Base = 'Base',
  Primary = 'Primary',
  Secondary = 'Secondary',
  Tertiary = 'Tertiary',
  Outline = 'Outline',
  Destructive = 'Destructive',
  Transparent = 'Transparent',
}

export enum VariantStates {
  Error = 'Error',
  Idle = 'Idle',
  Loading = 'Loading',
  Success = 'Success',
}

export enum ButtonVariantSizes {
  XSmall = 'XSmall',
  Small = 'Small',
  Regular = 'Regular',
  Medium = 'Medium',
  Large = 'Large',
  XLarge = 'XLarge',
}

export interface Props extends ButtonHTMLAttributes<HTMLButtonElement> {
  analyticsEvent?: string;
  backgroundColor?: string;
  'data-testid'?: string;
  isFullWidth?: boolean;
  keepTextCase?: boolean;
  variant?: Variants;
  variantState?: VariantStates;
  size?: ButtonVariantSizes;
  /** @description Only supported in Variants.Secondary  */
  iconSrc?: string;
  /** @description Only supported in Variants.Secondary  */
  iconStyle?: React.CSSProperties;
}

export const Button: React.FC<Props> = ({
  analyticsEvent,
  backgroundColor,
  children,
  'data-testid': testId,
  disabled = false,
  isFullWidth = false,
  onClick = NOOP,
  variant = Variants.Base,
  variantState = VariantStates.Idle,
  size = ButtonVariantSizes.Regular,
  keepTextCase,
  iconSrc,
  iconStyle,
  ...rest
}) => {
  const props = {
    'data-analytics-event': analyticsEvent,
    'data-testid': testId,
    backgroundColor,
    children,
    disabled:
      disabled ||
      [VariantStates.Error, VariantStates.Success, VariantStates.Loading].includes(variantState),
    isFullWidth,
    onClick,
    variantState,
    size,
    keepTextCase: Boolean(keepTextCase),
    ...rest,
  };

  switch (variant) {
    case Variants.Base:
      return <S.BaseButton {...props} />;
    case Variants.Primary:
      return <S.PrimaryButton {...props} />;
    case Variants.Secondary:
      return (
        <S.SecondaryButton {...props}>
          {iconSrc ? <S.Image src={iconSrc} style={iconStyle} /> : null}
          {props.children}
        </S.SecondaryButton>
      );
    case Variants.Tertiary:
      return <S.TertiaryButton {...props} />;
    case Variants.Outline:
      return <S.OutlineButton {...props} />;
    case Variants.Destructive:
      return <S.DestructiveButton {...props} />;
    case Variants.Transparent:
      return <S.TransparentButton {...props} />;
  }
};
