import React, { useEffect, useState } from 'react';
import * as S from './SideDeck.styles';
import { ExploreMusic, Library } from '@Music';
import { Profile } from '../Profile';
import { useDynamicMentalStates } from '../../api/modules/DynamicMentalStates';
import { uiSliceActions } from '../../reducers/uiReducer';
import { useDispatch, useSelector } from 'react-redux';
import { RootReducerType } from '../../reducers';
import { SideDeckModalType, SideDeckTab } from '../../types';
import { useIsMobileView } from '../../hooks/useIsMobileView';
import { useLocation } from 'react-router-dom';
import { useIsSideDeckOpenedEnabled } from '../../domains/Utils/useAmplitudeExperiments';
import { Transition } from 'react-transition-group';
import { DeleteAccount, EditProfile, UpdatePassword } from '@User';
import { Elements } from '@stripe/react-stripe-js';
import stripe, { ELEMENT_OPTIONS } from '../../utils/stripe';
import { UpdateStripePaymentMethodPanel } from '@Payment';
import { UpgradeStripeSubscriptionPanel } from '../../domains/Payment/components/UpgradeStripeSubscriptionPanel';

type Props = {
  playerView: JSX.Element;
};

const SideDeck = (props: Props) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { playerView } = props;
  const selectedTab = useSelector((state: RootReducerType) => state.ui.sideDeck.selectedTab?.tab);
  const modal = useSelector((state: RootReducerType) => state.ui.sideDeck.modal);
  const { data: dynamicMentalStates, isFetched } = useDynamicMentalStates();
  const isMobileView = useIsMobileView();
  const isSideDeckOpenedExpirementEnabled = useIsSideDeckOpenedEnabled();
  const [isSetFromLocation, setIsSetFromLocation] = useState(false);
  const [isSideDeckExpirementSet, setIsSideDeckExpirementSet] = useState(false);

  useEffect(() => {
    // Set default explore and library mental state id to first DA mental state id
    const defaultDynamicMentalState = dynamicMentalStates?.[0];

    if (defaultDynamicMentalState) {
      dispatch(uiSliceActions.setExploreMentalState(defaultDynamicMentalState));
      dispatch(uiSliceActions.setLibraryMentalState(defaultDynamicMentalState));
    }
  }, [isFetched, dynamicMentalStates, dispatch]);

  useEffect(() => {
    if (isSideDeckOpenedExpirementEnabled && !isSideDeckExpirementSet) {
      setIsSideDeckExpirementSet(true);
      dispatch(uiSliceActions.setSideDeckOpen(true));
    }
  }, [isSideDeckOpenedExpirementEnabled]);

  useEffect(() => {
    // Handle deep link and ensure this is only done once
    if (location.state?.sideDeckTab && !isSetFromLocation) {
      dispatch(uiSliceActions.setSideDeckOpen(true));
      dispatch(
        uiSliceActions.setSideDeckTab({
          tab: location.state?.sideDeckTab,
          profileSection: location.state?.profileSection,
        }),
      );
      setIsSetFromLocation(true);
      return;
    }

    // Handle switching between mobile and desktop view
    if (isMobileView) {
      dispatch(uiSliceActions.setSideDeckTab({ tab: SideDeckTab.Player }));
    } else {
      dispatch(uiSliceActions.setSideDeckTab({ tab: SideDeckTab.Library }));
    }
  }, [isMobileView, location.state?.sideDeckTab, dispatch]);

  const renderScreen = () => {
    switch (selectedTab) {
      case SideDeckTab.Player:
        return playerView;
      case SideDeckTab.Library:
        return <Library />;
      case SideDeckTab.Explore:
        return <ExploreMusic />;
      case SideDeckTab.Profile:
        return <Profile />;
    }
  };

  const handleCloseModal = () => {
    dispatch(uiSliceActions.setSideDeckModal(null));
  };

  return (
    <S.Container>
      <S.Content>{renderScreen()}</S.Content>
      <S.Tabs aria-label="Navigation tabs" role="tablist">
        {isMobileView && (
          <S.TabButton
            active={selectedTab === SideDeckTab.Player}
            aria-controls="player-panel"
            aria-selected={selectedTab === SideDeckTab.Player}
            data-testid="sideDeckPlayerTab"
            role="tab"
            tabIndex={0}
            onClick={() => dispatch(uiSliceActions.setSideDeckTab({ tab: SideDeckTab.Player }))}
            onKeyDown={e => {
              if (e.key === 'Enter' || e.key === ' ') {
                dispatch(uiSliceActions.setSideDeckTab({ tab: SideDeckTab.Player }));
              }
            }}
          >
            <S.PlayerIcon active={selectedTab === SideDeckTab.Player} aria-hidden="true" />
            <span>PLAYER</span>
          </S.TabButton>
        )}
        <S.TabButton
          active={selectedTab === SideDeckTab.Library}
          aria-controls="library-panel"
          aria-selected={selectedTab === SideDeckTab.Library}
          data-testid="sideDeckLibraryTab"
          role="tab"
          tabIndex={0}
          onClick={() => dispatch(uiSliceActions.setSideDeckTab({ tab: SideDeckTab.Library }))}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              dispatch(uiSliceActions.setSideDeckTab({ tab: SideDeckTab.Library }));
            }
          }}
        >
          <S.LibraryIcon active={selectedTab === SideDeckTab.Library} aria-hidden="true" />
          <span>LIBRARY</span>
        </S.TabButton>
        <S.TabButton
          active={selectedTab === SideDeckTab.Explore}
          aria-controls="explore-panel"
          aria-selected={selectedTab === SideDeckTab.Explore}
          data-testid="sideDeckExploreTab"
          role="tab"
          tabIndex={0}
          onClick={() => dispatch(uiSliceActions.setSideDeckTab({ tab: SideDeckTab.Explore }))}
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              dispatch(uiSliceActions.setSideDeckTab({ tab: SideDeckTab.Explore }));
            }
          }}
        >
          <S.ExploreIcon active={selectedTab === SideDeckTab.Explore} aria-hidden="true" />
          <span>EXPLORE</span>
        </S.TabButton>
        <S.TabButton
          active={selectedTab === SideDeckTab.Profile}
          aria-controls="profile-panel"
          aria-selected={selectedTab === SideDeckTab.Profile}
          data-testid="sideDeckProfileTab"
          role="tab"
          tabIndex={0}
          onClick={() =>
            dispatch(
              uiSliceActions.setSideDeckTab({
                tab: SideDeckTab.Profile,
                profileSection: null,
              }),
            )
          }
          onKeyDown={e => {
            if (e.key === 'Enter' || e.key === ' ') {
              dispatch(
                uiSliceActions.setSideDeckTab({
                  tab: SideDeckTab.Profile,
                  profileSection: null,
                }),
              );
            }
          }}
        >
          <S.ProfileIcon active={selectedTab === SideDeckTab.Profile} aria-hidden="true" />
          <span>PROFILE</span>
        </S.TabButton>
      </S.Tabs>

      {/* Modals */}
      <Transition
        in={modal === SideDeckModalType.EditProfile}
        mountOnEnter
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <S.FloatingWindow animationState={state}>
              <EditProfile
                onClose={handleCloseModal}
                onDeleteAccount={() =>
                  dispatch(uiSliceActions.setSideDeckModal(SideDeckModalType.DeleteAccount))
                }
              />
            </S.FloatingWindow>
          );
        }}
      </Transition>
      <Transition
        in={modal === SideDeckModalType.UpdatePassword}
        mountOnEnter
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <S.FloatingWindow animationState={state}>
              <UpdatePassword onClose={handleCloseModal} />
            </S.FloatingWindow>
          );
        }}
      </Transition>
      <Transition
        in={modal === SideDeckModalType.DeleteAccount}
        mountOnEnter
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <S.FloatingWindow animationState={state}>
              <DeleteAccount onClose={handleCloseModal} />
            </S.FloatingWindow>
          );
        }}
      </Transition>
      <Transition
        in={modal === SideDeckModalType.UpdatePayment}
        mountOnEnter
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <S.FloatingWindow animationState={state}>
              <Elements options={ELEMENT_OPTIONS} stripe={stripe.getInstance()}>
                <UpdateStripePaymentMethodPanel onClose={handleCloseModal} />
              </Elements>
            </S.FloatingWindow>
          );
        }}
      </Transition>

      <Transition
        in={modal === SideDeckModalType.UpgradeStripeSubscription}
        mountOnEnter
        timeout={200}
        unmountOnExit
      >
        {state => {
          return (
            <S.FloatingWindow animationState={state}>
              <UpgradeStripeSubscriptionPanel onClose={handleCloseModal} />
            </S.FloatingWindow>
          );
        }}
      </Transition>
    </S.Container>
  );
};

export default SideDeck;
