import { Button, ButtonVariants } from '@Cortex';

import * as S from './ExpiredMembershipCallToAction.styles';
import { Assets } from '../../../../../../utils/assets';

type Props = {
  callToActionText?: string;
  onClickRenewMembership?: () => void;
};

export function ExpiredMembershipCallToActionPresentation(props: Props) {
  return (
    <S.Container>
      <S.HeaderTextTitle>Renew to get access to exclusive features:</S.HeaderTextTitle>
      <S.Benefits>
        <S.Checkmark
          alt={Assets.icons.checkmarkGradient.alt}
          src={Assets.icons.checkmarkGradient.url}
        />
        <S.Container>
          <S.BenefitsTitle>Unlimited Sessions</S.BenefitsTitle>
          <S.BenefitsSubtitle>Access to 1000+ tracks</S.BenefitsSubtitle>
        </S.Container>
      </S.Benefits>
      <S.Benefits>
        <S.Checkmark
          alt={Assets.icons.checkmarkGradient.alt}
          src={Assets.icons.checkmarkGradient.url}
        />
        <S.Container>
          <S.BenefitsTitle>Access all Categories</S.BenefitsTitle>
          <S.BenefitsSubtitle>Discover the perfect music for your brain.</S.BenefitsSubtitle>
        </S.Container>
      </S.Benefits>
      <S.Benefits>
        <S.Checkmark
          alt={Assets.icons.checkmarkGradient.alt}
          src={Assets.icons.checkmarkGradient.url}
        />
        <S.Container>
          <S.BenefitsTitle>Access to our Mobile App</S.BenefitsTitle>
          <S.BenefitsSubtitle>
            Take brain.fm on the go with our mobile application, available for iPhone and Android.
          </S.BenefitsSubtitle>
        </S.Container>
      </S.Benefits>
      <div>
        <S.Image
          alt={Assets.images.subscriptionWidgetFocus.alt}
          src={Assets.images.subscriptionWidgetFocus.url}
        />
      </div>
      <div>
        <Button
          isFullWidth
          keepTextCase={true}
          style={{ fontSize: '0.75rem' }}
          variant={ButtonVariants.Secondary}
          onClick={props.onClickRenewMembership}
        >
          {props.callToActionText || 'RENEW SUBSCRIPTION'}
        </Button>
      </div>
    </S.Container>
  );
}
