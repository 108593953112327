import styled from 'styled-components';
import { Assets } from '../../../../utils/assets';
import { Button } from '@Cortex';

export const CallToActionWidget = styled.div`
  color: white;
  width: 100%;
  display: flex;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  z-index: 101;
`;

export const SVGImage = styled.img`
  position: absolute;
  pointer-events: none;
  left: -300px;
  top: -340px;
  width: 620px;
  height: 620px;
  transform: rotate(105deg);
`;

export const SVGImageSecond = styled.img`
  position: absolute;
  right: -200px;
  top: -330px;
  pointer-events: none;
  width: 840px;
  height: 840px;
  transform: rotate(-150deg);
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
`;

export const IconContainer = styled.div`
  width: 6.75rem;
  height: 6.75rem;
  position: relative;
  left: -0.875rem;
`;

export const TextContainer = styled.div`
  position: relative;
  left: -1.5rem;
`;

export const Icon = styled.img`
  width: 6.75rem;
  height: 6.75rem;
`;

export const Row = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
  position: relative;
  z-index: 1;
`;

export const Highlight = styled.span`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  position: relative;
  display: inline-block;
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};

  &:after {
    content: '';
    position: absolute;
    display: block;
    height: 7px;
    bottom: -7px;
    width: 100%;
    background-image: url(${Assets.images.highlight.url});
    background-repeat: no-repeat;
    background-position: center bottom;
  }
`;

export const ButtonContent = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`;

export const CloseButtonContainer = styled.div`
  opacity: 0.75;
  transition: opacity 0.3s ease;

  &:hover {
    opacity: 1;
  }
`;

export const WhiteButton = styled(Button).attrs({
  keepTextCase: true,
})`
  color: black;
  text-transform: uppercase;
  letter-spacing: 1.2px;
  font-size: 0.65rem;
  background: white;

  box-shadow: none;
  background-origin: border-box;

  &:hover {
    background: rgba(255, 255, 255, 1);
  }
`;
