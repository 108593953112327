import React from 'react';
import * as S from './AdhdModeTooltip.styles';

export const AdhdModeTooltip = () => {
  return (
    <S.Container>
      <S.BoldText>ADHD Mode</S.BoldText>
      <S.DescriptionText>
        Enabling ADHD Mode plays tracks with High Neural Effect Level, designed to boost activity in
        your attentional networks and enhance focus, backed by peer-reviewed research.
      </S.DescriptionText>
      <S.SemiboldText>Toggle it on and stay on track.</S.SemiboldText>
    </S.Container>
  );
};
