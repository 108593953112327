import { useQuery } from '@tanstack/react-query';
import { useDispatch, useSelector } from 'react-redux';
import { setModal } from '../../../actions/ui';

import { RootReducerType } from '../../../reducers';
import { isOfflineError } from '../../../utils/isOfflineError';
import { Logger } from '../../../utils/logger';
import { Queries } from '../../common/queries';
import { TUnseenRewardsResponse } from './types';
import { useRequestHandler } from './useRequestHandler';

export const useUnseenRewards = () => {
  const dispatch = useDispatch();
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);

  const getUnseenRewardsCount = useRequestHandler();

  return useQuery<TUnseenRewardsResponse['result'], Error>(
    Queries.getUnseenRewardsCount(userId!),
    () => getUnseenRewardsCount(userId!),
    {
      onSuccess: data => {
        console.log(data);
        if (data?.count > 0) dispatch(setModal('referAFriendReward'));
      },
      onError: err => {
        if (isOfflineError(err)) return;
        Logger.error(new Error('Error getting Unseen Rewards Count'), {
          err,
          userId,
        });
      },
      enabled: Boolean(userId),
    },
  );
};
