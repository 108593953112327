export const getSessionTypeDescription = (mentalState: string | null): string => {
  if (!mentalState) {
    return 'Let the music play in the background while you focus on your tasks.'; // Fallback to focus if no mental state is provided
  }

  switch (mentalState) {
    case 'focus':
      return 'Let the music play in the background while you focus on your tasks.';
    case 'relax':
      return 'Let the music play in the background to help you relax and recharge.';
    case 'sleep':
      return 'Let the music play in the background while you drift off to sleep.';
    case 'meditate':
      return 'Let the music play in the background while you focus on your breathing.';
    default:
      return 'Let the music play in the background while you focus on your tasks.';
  }
};
