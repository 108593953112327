import { Dispatch } from 'react';
import * as analyticsActions from '../../../../../actions/analytics';
import { post } from '../../../../../api/client/client';
import { EnhancedMembershipPlanType } from '../../../../../types';
import { getErrorMessage } from '../../../../../utils/getErrorMessage';
import {
  trackStripeCheckoutSubscriptionStartAttempt,
  trackStripeCheckoutSubscriptionStartError,
} from '../../../../Analytics/coreAnalytics';
import { PlanTypes } from '../../../../Analytics/coreAnalytics.types';
import { PaymentStatus } from '../types';
import { getCreateStripeCheckoutSubscriptionPath } from './getCreateStripeCheckoutSubscriptionPath';
import { getIsYearlyPlan } from './getIsYearlyPlan';
import { getPlanTypeFromDisplayInterval } from './getPlanType';
import { getStripeCustomerId } from './getStripeCustomerId';

type Props = {
  email?: string;
  plan: EnhancedMembershipPlanType | null;
  token: string | null;
  userId?: string;
  utmSource?: string;
  couponId?: string;
  cancelUrl?: string;
  successUrl?: string;
  subscribeScreenFrom?: string;
  dispatch: Dispatch<any>;
  onError: (msg: string | null) => void;
  onPaymentStatusChange: (status: PaymentStatus) => void;
  fbEventId: string;
};

export const performStripeCheckout = (props: Props) => async (billingName: string) => {
  const {
    email,
    plan,
    token,
    userId,
    onError,
    utmSource,
    couponId,
    subscribeScreenFrom,
    dispatch,
    onPaymentStatusChange,
    cancelUrl,
    successUrl,
    fbEventId,
  } = props;

  const customerId = await getStripeCustomerId({ token, email });

  if (!plan || !customerId) return null;

  trackStripeCheckoutSubscriptionStartAttempt({
    couponId: couponId || '',
    planType: getIsYearlyPlan(plan)
      ? PlanTypes.Yearly
      : getPlanTypeFromDisplayInterval(plan.displayInterval),
  });

  try {
    onError(null);
    onPaymentStatusChange('loading');

    const { result, status, messages } = await post<
      {
        couponId?: string;
        customerId: string;
        promotionCode?: string;
        userId?: string;
        utmSource?: string;
        interval: 'monthly' | 'yearly';
        cancelUrl?: string;
        successUrl?: string;
        fbEventId: string;
      },
      { status: number; result: { url: string }; messages?: string[] }
    >({
      path: getCreateStripeCheckoutSubscriptionPath(plan),
      config: {
        credentials: 'include',
      },
      body: {
        couponId: plan.couponId,
        fbEventId,
        customerId,
        promotionCode: plan.promotionCode,
        userId,
        utmSource,
        cancelUrl,
        successUrl,
        interval: getIsYearlyPlan(plan) ? 'yearly' : 'monthly',
      },
      token,
    });

    if (result.url) {
      window.location.href = result.url;
    }
  } catch (error) {
    trackStripeCheckoutSubscriptionStartError({
      errorMessage: getErrorMessage(error),
    });
    onPaymentStatusChange('error');
    dispatch(
      analyticsActions.logEventWithProperties({
        event: 'stripe_checkout_subscription_purchase_error',
        props: {
          subscribe_screen_from: subscribeScreenFrom,
          plan_type: plan.title,
        },
      }),
    );
  } finally {
    setTimeout(() => {
      onPaymentStatusChange('idle');
    }, 1000);
  }
};
