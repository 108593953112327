import { Assets } from '../../../../utils/assets';

export const getSessionTypeImage = (mentalState: string | null) => {
  if (!mentalState) {
    return {
      url: Assets.images.workingOnLaptop.url,
      alt: Assets.images.workingOnLaptop.alt,
    };
  }

  switch (mentalState) {
    case 'focus':
      return {
        url: Assets.images.workingOnLaptop.url,
        alt: Assets.images.workingOnLaptop.alt,
      };
    case 'relax':
      return {
        url: Assets.images.restingOnChair.url,
        alt: Assets.images.restingOnChair.alt,
      };
    case 'sleep':
      return {
        url: Assets.images.sleepingOnBed.url,
        alt: Assets.images.sleepingOnBed.alt,
      };
    case 'meditate':
      return {
        url: Assets.images.sittingOnFloor.url,
        alt: Assets.images.sittingOnFloor.alt,
      };
    default:
      return {
        url: Assets.images.workingOnLaptop.url,
        alt: Assets.images.workingOnLaptop.alt,
      };
  }
};
