import styled from 'styled-components';

export const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  animation: ${({ theme }) => theme.animations.fadeIn} 0.2s ease-in;
  padding: 14px;
  background-image: url('https://cdn.brain.fm/images/download_desktop_app_background_small_xl.webp');
  background-size: auto 100%;

  background-position: right top;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  position: relative;
`;

export const RedCircle = styled.div`
  width: 10px;
  height: 10px;
  background-color: red;
  border-radius: 50%;
`;

export const Body = styled.div`
  display: flex;
  align-items: center;
  gap: 16px;
`;

export const Image = styled.img`
  margin-left: 0.5rem;
  height: 2rem;
`;

export const CopyWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ButtonWrapper = styled.a`
  text-decoration: none;
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 1;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  border-bottom-left-radius: 8px;
  padding: 4px;
  transition:
    background-color 0.2s ease-in-out,
    transform 0.2s ease-in-out;

  &:hover {
    background-color: rgba(255, 255, 255, 0.2);
  }
`;
