import { Button, ButtonVariants, ButtonVariantStates } from '@Cortex';
import { Formik } from 'formik';
import * as yup from 'yup';
import { ProfileFormContainer } from '../../../shared/ProfileFormContainer';
import HeaderImage from './assets/change_password.svg';

import * as S from './UpdatePassword.styles';
import { InputField } from './components/InputField';

const validationSchema = yup.object().shape({
  currentPassword: yup.string().required('Your current password is required.'),
  newPassword: yup
    .string()
    .min(6, 'Password must be at least 6 characters long.')
    .required('Type in your new password'),
  confirmNewPassword: yup
    .string()
    .oneOf([yup.ref('newPassword'), undefined], 'The value should match New Password field.')
    .required('Confirm your new password.'),
});

export type FormSubmitArgs = {
  currentPassword: string;
  newPassword: string;
  confirmNewPassword: string;
};

export interface Props {
  errorMessage?: string;
  isLoading?: boolean;
  onClose: () => void;
  onFormSubmit: (values: FormSubmitArgs) => void;
  submitButtonTitle?: string;
}

export const UpdatePasswordDisplay = ({
  errorMessage,
  isLoading = false,
  onClose,
  onFormSubmit,
  submitButtonTitle = 'Save',
}: Props) => {
  return (
    <Formik
      initialValues={{ currentPassword: '', newPassword: '', confirmNewPassword: '' }}
      validationSchema={validationSchema}
      onSubmit={values => onFormSubmit(values)}
    >
      {({ handleChange, handleSubmit, values, touched, errors }) => {
        const currentPasswordError =
          touched.currentPassword && errors.currentPassword ? errors.currentPassword : undefined;
        const newPasswordError =
          touched.newPassword && errors.newPassword ? errors.newPassword : undefined;
        const confirmNewPasswordError =
          touched.confirmNewPassword && errors.confirmNewPassword
            ? errors.confirmNewPassword
            : undefined;

        return (
          <S.Form data-testid="changePasswordForm" onSubmit={handleSubmit}>
            <ProfileFormContainer
              headerImage={HeaderImage}
              keepTextCase
              title="Change Password"
              onClose={onClose}
            >
              <S.Container>
                <S.InputLabel htmlFor="currentPassword">Current Password</S.InputLabel>
                <InputField
                  autoComplete="current-password"
                  error={currentPasswordError ? errors.currentPassword : undefined}
                  id="currentPassword"
                  name="currentPassword"
                  placeholder="Enter current password"
                  style={{ marginBottom: '1.5rem' }}
                  type="password"
                  value={values.currentPassword}
                  variant="individual"
                  onChange={handleChange}
                />

                <S.InputLabel htmlFor="newPassword">New Password</S.InputLabel>
                <InputField
                  autoComplete="new-password"
                  error={newPasswordError ? errors.newPassword : undefined}
                  id="newPassword"
                  name="newPassword"
                  placeholder="New password"
                  type="password"
                  value={values.newPassword}
                  variant="first"
                  onChange={handleChange}
                />

                <InputField
                  autoComplete="new-password"
                  error={confirmNewPasswordError ? errors.confirmNewPassword : undefined}
                  id="confirmNewPassword"
                  name="confirmNewPassword"
                  placeholder="Confirm new password"
                  type="password"
                  value={values.confirmNewPassword}
                  variant="last"
                  onChange={handleChange}
                />

                <S.SubmitButton>
                  <Button
                    data-testid="changePasswordButton"
                    isFullWidth={true}
                    keepTextCase
                    style={{ fontSize: '0.75rem' }}
                    type="submit"
                    variant={ButtonVariants.Secondary}
                    variantState={
                      isLoading ? ButtonVariantStates.Loading : ButtonVariantStates.Idle
                    }
                  >
                    {submitButtonTitle.toUpperCase()}
                  </Button>
                </S.SubmitButton>
                <Button
                  keepTextCase
                  style={{ fontSize: '0.75rem' }}
                  type="button"
                  onClick={onClose}
                >
                  CANCEL
                </Button>
              </S.Container>
              {errorMessage ? (
                <S.ErrorText style={{ textAlign: 'center' }}>{errorMessage}</S.ErrorText>
              ) : null}
            </ProfileFormContainer>
          </S.Form>
        );
      }}
    </Formik>
  );
};
