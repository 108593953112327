import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';

import { uiSliceActions } from '../../../../reducers/uiReducer';
import {
  trackCommitmentExperienceExperimentExposure,
  getCommitmentExperienceFeatureFlag,
} from '../../../Utils/useAmplitudeExperiments';
import { ONBOARDING_MENTAL_STATE_ID_KEY } from '../../constants';
import {
  OnboardingSpecification,
  OnboardingSteps,
  OnboardingQuizAnswer,
  OnboardingCardType,
} from '../../types';
import { QuizCardsPresentation } from './QuizCards.presentation';
import { CoreAnalytics } from '@Analytics';
import { isHearTheDifferenceVariation } from '../../utils/isHearTheDifferenceVariation';

type Props = {
  isLoading: boolean;
  onboardingSpecification: OnboardingSpecification;
  onSetStep: (step: OnboardingSteps) => void;
  onSelectAnswer: (
    questionIndex: number,
    answerIndex: number,
    testimonials?: OnboardingQuizAnswer['testimonials'],
    benefits?: OnboardingQuizAnswer['benefits'],
    dynamicActivityId?: OnboardingQuizAnswer['dynamicActivityId'],
    userPropertyToSet?: OnboardingQuizAnswer['userPropertyToSet'],
  ) => void;
  onSkip: () => void;
};

export const QuizCards = (props: Props) => {
  const dispatch = useDispatch();
  const { value } = getCommitmentExperienceFeatureFlag();
  const { isLoading, onboardingSpecification, onSelectAnswer, onSetStep, onSkip } = props;
  const { cards: onboardingCards, quizSpecification, id } = onboardingSpecification;
  const [currentCardIndex, setCurrentCardIndex] = useState(0);
  const [currentResponseCardIndex, setCurrentResponseCardIndex] = useState(0);
  const [mountedCardIndex, setMountedCardIndex] = useState(0);
  const [mountedResponseCardIndex, setMountedResponseCardIndex] = useState(0);
  const [isResponseCardsMode, setIsResponseCardsMode] = useState(false);
  const [responseCards, setResponseCards] = useState<OnboardingCardType[]>([]);
  const cardsAmount = (quizSpecification?.questions || []).length;
  const responseCardsAmount = (responseCards || []).length;

  useEffect(() => {
    if (isLoading || (quizSpecification?.questions || []).length) return;
    if (value && sessionStorage.getItem(ONBOARDING_MENTAL_STATE_ID_KEY) === 'focus') {
      trackCommitmentExperienceExperimentExposure();
      if (['30-minutes', 'variable'].includes(value)) {
        onSetStep(OnboardingSteps.Commitment);
        return;
      }
    }
    onSetStep(OnboardingSteps.Funbars);
  }, [isLoading, quizSpecification?.questions]);

  const handleMountCard = useCallback(() => {
    setMountedCardIndex(currentCardIndex);
  }, [setMountedCardIndex, currentCardIndex]);

  const handleMountResponseCard = useCallback(() => {
    setMountedResponseCardIndex(currentResponseCardIndex);
  }, [setMountedResponseCardIndex, currentResponseCardIndex]);

  const handlePrevCard = useCallback(() => {
    if (currentCardIndex === 0) return;
    setCurrentCardIndex(currentCardIndex - 1);
  }, [setCurrentCardIndex, currentCardIndex, dispatch, cardsAmount]);

  const handleClickContinue = useCallback(
    (
      questionIndex: number,
      answerIndex: number,
      responseCards?: OnboardingQuizAnswer['responseCards'],
      testimonials?: OnboardingQuizAnswer['testimonials'],
      benefits?: OnboardingQuizAnswer['benefits'],
      dynamicActivityId?: OnboardingQuizAnswer['dynamicActivityId'],
      userPropertyToSet?: OnboardingQuizAnswer['userPropertyToSet'],
    ) =>
      () => {
        onSelectAnswer(
          questionIndex,
          answerIndex,
          testimonials,
          benefits,
          dynamicActivityId,
          userPropertyToSet,
        );

        CoreAnalytics.trackOnboardingQuizAnswer({
          answer: quizSpecification?.questions[currentCardIndex].answers[answerIndex].title || '',
        });

        if (responseCards) {
          setResponseCards(responseCards);
          setTimeout(() => {
            setIsResponseCardsMode(true);
          }, 100); // animation timeout

          return;
        }

        if (currentCardIndex + 1 >= cardsAmount) {
          if (!isHearTheDifferenceVariation(onboardingSpecification.id)) {
            dispatch(uiSliceActions.setShouldShowFocusPersonalizationQuiz(true));
          }
          onSetStep(OnboardingSteps.Results);
          return;
        }

        setCurrentCardIndex(currentCardIndex + 1);
      },
    [
      setCurrentCardIndex,
      onSetStep,
      currentCardIndex,
      cardsAmount,
      setIsResponseCardsMode,
      setResponseCards,
    ],
  );

  const handleNextResponseCard = useCallback(() => {
    CoreAnalytics.trackOnboardingContinueEvent();

    if (currentResponseCardIndex + 1 >= responseCardsAmount) {
      setIsResponseCardsMode(false);
      // reseting response cards vars
      setResponseCards([]);
      setCurrentResponseCardIndex(0);
      setMountedResponseCardIndex(0);
      if (currentCardIndex + 1 >= cardsAmount) {
        if (!isHearTheDifferenceVariation(onboardingSpecification.id)) {
          dispatch(uiSliceActions.setShouldShowFocusPersonalizationQuiz(true));
        }
        onSetStep(OnboardingSteps.Results);
        return;
      }
      setCurrentCardIndex(currentCardIndex + 1);
      setMountedCardIndex(currentCardIndex + 1);

      return;
    }
    setCurrentResponseCardIndex(currentResponseCardIndex + 1);
  }, [
    setIsResponseCardsMode,
    setCurrentResponseCardIndex,
    setResponseCards,
    currentResponseCardIndex,
    responseCardsAmount,
    currentCardIndex,
    cardsAmount,
    dispatch,
    onSetStep,
    setCurrentCardIndex,
    setMountedCardIndex,
  ]);

  const handleSkipCard = () => {
    CoreAnalytics.trackOnboardingSkipEvent({
      variation: id,
      cardIndex: currentCardIndex,
      cardTitle: quizSpecification?.questions[currentCardIndex]?.title || '',
      module: 'quiz',
    });
    onSkip();
  };

  const numberOfSteps = onboardingCards.length + cardsAmount;
  const activeStepIndex = currentCardIndex + onboardingCards.length;

  return (
    <QuizCardsPresentation
      activeStepIndex={activeStepIndex}
      cards={quizSpecification?.questions || []}
      currentCardIndex={currentCardIndex}
      currentResponseCardIndex={currentResponseCardIndex}
      isLoading={isLoading}
      isResponseCardsMode={isResponseCardsMode}
      mountedCardIndex={mountedCardIndex}
      mountedResponseCardIndex={mountedResponseCardIndex}
      numberOfSteps={numberOfSteps}
      responseCards={responseCards}
      onClickBack={currentCardIndex !== 0 ? handlePrevCard : undefined}
      onClickContinue={handleClickContinue}
      onHandleMountCard={handleMountCard}
      onHandleMountResponseCard={handleMountResponseCard}
      onHandleNextResponseCard={handleNextResponseCard}
      onHandleSkipResponseCard={handleSkipCard}
    />
  );
};
