import { Panel } from '@Cortex';
import { AudioControlBar } from '@Music';
import { FavoriteModal, Preferences, SessionNELTip, TimeMode } from '@Session';
import { useCallback, useEffect, useState, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Outlet, useLocation } from 'react-router-dom';
import { Transition } from 'react-transition-group';

import { setSessionModalContent, setShouldShowNELTipsModal } from '../../actions/ui';
import {
  ONBOARDING_DELAYED_HEAR_THE_DIFFERENCE_KEY,
  ONBOARDING_DELAYED_TEST_TIMER,
} from '../../domains/Onboarding/constants';
import { QuizToast } from '../../domains/PersonalizationQuiz';
import { RootReducerType } from '../../reducers';
import { uiSliceActions } from '../../reducers/uiReducer';
import { SessionModalContent } from '../../types';
import { FocusSectionDynamic } from './components/FocusSectionDynamic';
import { MilestoneWidget } from './components/MilestonesWidget';
import { useStreakMilestoneModalHandler } from './hooks/useStreakMilestoneModalHandler';
import * as S from './Session.styles';
import { SessionHeader } from './components/SessionHeader';
import { SESSION_ANIMATION_DURATION_MS } from './utils/constants';
import { getBackgroundImage } from './utils/getBackgroundImage';
import { processDynamicMentalStateColor } from './utils/processDynamicMentalStateColor';
import { dynamicReplaceTracksWithUpdatedPreferences } from '../../actions/sessionManager';
import { logEvent } from '../../actions/analytics';
import { ActivitySelection } from '../../domains/Session/components/ActivitySelection';
import { MoreTrackInfo } from '../../domains/Music/components/AudioControlBar/components/TrackInformation/components/MoreTrackInfo';
import { WidgetBanner } from '../widgetBanner';

export const Session = () => {
  useStreakMilestoneModalHandler();
  const dispatch = useDispatch();
  const location = useLocation();
  const currentTrack = useSelector((state: RootReducerType) => state.music.currentTrack);
  const currentBackgroundImageUrl = currentTrack ? getBackgroundImage(currentTrack) : null;

  const [backgroundImage, setBackgroundImage] = useState<string | null>(null);

  const [isNELTipVisible, setNELTipVisible] = useState(false);

  const sessionDynamicMentalStateColor = useSelector(
    (state: RootReducerType) =>
      state.sessionManager.sessionDynamicActivity?.mentalState.primaryColor,
  );
  const shouldShowFavoriteModal = useSelector(
    (state: RootReducerType) => state.ui.shouldShowFavoriteModal,
  );

  const mentalStateColor = useMemo(() => {
    if (!sessionDynamicMentalStateColor) return 'rgba(254, 91, 131, 0.1)'; // fallback to focus hardcoded color

    return processDynamicMentalStateColor(sessionDynamicMentalStateColor, 0.1);
  }, [sessionDynamicMentalStateColor]);

  const sessionDynamicMentalStateDisplayValue = useSelector(
    (state: RootReducerType) =>
      state.sessionManager.sessionDynamicActivity?.mentalState.displayValue,
  );

  const modalType = useSelector((state: RootReducerType) => state.ui.modalType);

  const sessionModalContent = useSelector((state: RootReducerType) => state.ui.sessionModalContent);

  const shouldShowNELTipsModal = useSelector(
    (state: RootReducerType) => state.ui.shouldShowNELTipsModal,
  );

  const shouldShowFocusPersonalizationQuiz =
    useSelector((state: RootReducerType) => state.ui.shouldShowFocusPersonalizationQuiz) &&
    sessionDynamicMentalStateDisplayValue === 'focus'; // displayValue as a temp solution

  useEffect(() => {
    if (isNELTipVisible) {
      dispatch(logEvent('player_htd_impression'));
    }
  }, [isNELTipVisible]);

  useEffect(() => {
    let isCancelled = false;
    if (!currentBackgroundImageUrl) {
      setBackgroundImage(null);
      return;
    }
    const img = new Image();
    img.src = currentBackgroundImageUrl;
    img.onload = function () {
      if (!isCancelled && img.src === currentBackgroundImageUrl) {
        setBackgroundImage(currentBackgroundImageUrl);
      }
    };
    return () => {
      isCancelled = true;
    };
  }, [currentBackgroundImageUrl]);

  useEffect(() => {
    if (location.state && location.state.actions && location.state.actions.length) {
      location.state.actions.forEach((action: any) => {
        dispatch(action);
      });
    }
  }, [dispatch, location.state]);

  useEffect(() => {
    if (!modalType && shouldShowNELTipsModal) {
      const timeout =
        sessionStorage.getItem(ONBOARDING_DELAYED_HEAR_THE_DIFFERENCE_KEY) === 'true'
          ? ONBOARDING_DELAYED_TEST_TIMER
          : SESSION_ANIMATION_DURATION_MS;
      sessionStorage.removeItem(ONBOARDING_DELAYED_HEAR_THE_DIFFERENCE_KEY);

      // timeout is used because the parent component is transitioning in
      const timeoutId = setTimeout(() => {
        setNELTipVisible(true);
      }, timeout);

      return () => {
        clearTimeout(timeoutId);
        setNELTipVisible(false);
        dispatch(setShouldShowNELTipsModal(false));
      };
    }
  }, [shouldShowNELTipsModal, modalType, dispatch]);

  const handleNELTipClose = () => {
    dispatch(setShouldShowNELTipsModal(false));
    setNELTipVisible(false);
    dispatch(logEvent('player_htd_dismiss'));
  };

  const handleTurbochargeMe = () => {
    dispatch(logEvent('turbocharge_me_click'));
    dispatch(dynamicReplaceTracksWithUpdatedPreferences({ neuralEffectLevels: ['High'] }));
  };

  const handleQuizToastClose = () => {
    dispatch(uiSliceActions.setShouldShowFocusPersonalizationQuiz(false));
  };

  const setSessionSidebar = (contentType: SessionModalContent) => {
    if (isNELTipVisible && contentType === SessionModalContent.Preferences) {
      dispatch(setShouldShowNELTipsModal(false));
      setNELTipVisible(false);
    }
    dispatch(setSessionModalContent({ sessionModalContent: contentType, origin: 'player' }));
  };

  const closeSidebarComponent = useCallback(() => {
    dispatch(setSessionModalContent({ sessionModalContent: null, origin: undefined }));
    dispatch(uiSliceActions.setExploreModalTab(null));
  }, [dispatch]);

  const setSessionModal = (contentType: SessionModalContent) => {
    if (isNELTipVisible && contentType === SessionModalContent.Preferences) {
      dispatch(setShouldShowNELTipsModal(false));
      setNELTipVisible(false);
    }
    dispatch(setSessionModalContent({ sessionModalContent: contentType, origin: 'player' }));
  };

  return (
    <>
      <Transition in={shouldShowFocusPersonalizationQuiz} mountOnEnter timeout={0} unmountOnExit>
        <S.QuizToastWrapper>
          <QuizToast
            shouldShowButtonToOpenPreferences={true}
            onClickOpenPreferences={() => setSessionModal(SessionModalContent.Preferences)}
            onClose={handleQuizToastClose}
          />
        </S.QuizToastWrapper>
      </Transition>

      <Transition in={isNELTipVisible} mountOnEnter timeout={0} unmountOnExit>
        <S.SessionNELTipWrapper>
          <SessionNELTip onClose={handleNELTipClose} onTurbochargeMeClick={handleTurbochargeMe} />
        </S.SessionNELTipWrapper>
      </Transition>

      <S.PageWrapper backgroundImageUrl={backgroundImage}>
        <WidgetBanner />
        <S.Overlay backgroundColor={mentalStateColor} />
        <S.PageContent>
          <MilestoneWidget isHidden={Boolean(modalType || shouldShowFavoriteModal)} />
          <FavoriteModal />

          <S.BackgroundWrapper>
            <S.SessionWrapper>
              <FocusSectionDynamic />
            </S.SessionWrapper>
            <AudioControlBar />
          </S.BackgroundWrapper>

          <SessionHeader
            onClickPreferences={() => setSessionSidebar(SessionModalContent.Preferences)}
            onClickTimerSettings={() => setSessionSidebar(SessionModalContent.TimerSettings)}
          />
        </S.PageContent>
        {sessionModalContent && sessionModalContent === SessionModalContent.Preferences ? (
          <S.SidebarContentContainer isCentered>
            <Panel borderRadius="0rem" padding="0" style={S.GradientContainerStyleTransparent}>
              <Preferences onClose={closeSidebarComponent} />
            </Panel>
          </S.SidebarContentContainer>
        ) : null}
        {sessionModalContent && sessionModalContent === SessionModalContent.Activity ? (
          <S.PreferencesContentContainer>
            <Panel borderRadius="0rem" padding="0" style={S.GradientContainerStyleTransparent}>
              <ActivitySelection onClose={closeSidebarComponent} />
            </Panel>
          </S.PreferencesContentContainer>
        ) : null}

        {sessionModalContent && sessionModalContent === SessionModalContent.TimerSettings ? (
          <S.PreferencesContentContainer>
            <Panel borderRadius="0rem" padding="0" style={S.GradientContainerStyleTransparent}>
              <TimeMode onClose={closeSidebarComponent} />
            </Panel>
          </S.PreferencesContentContainer>
        ) : null}

        {currentTrack &&
        sessionModalContent &&
        sessionModalContent === SessionModalContent.TrackDetails ? (
          <MoreTrackInfo track={currentTrack} onClose={closeSidebarComponent} />
        ) : null}
      </S.PageWrapper>
      <Outlet />
    </>
  );
};
