import { Text, TextBold, FontFamilies, TextSemiBold } from '@Cortex';
import styled from 'styled-components';
import { Assets } from '../../../../../../../../utils/assets';

export const HeaderSpacer = styled.div`
  height: 48px;
  width: 48px;
`;

export const CloseIconContainer = styled.div`
  backdrop-filter: blur(34px);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 100%;
  cursor: pointer;
  display: flex;
  height: 48px;
  width: 48px;
  transition: box-shadow 0.2s ease-in-out;
  align-items: center;
  justify-content: center;

  &:hover,
  &:focus {
    box-shadow: inset 0px 0px 0px 2px rgba(255, 255, 255, 0.5);
  }
`;

export const CloseIcon = styled.img`
  width: 1.5rem;
  height: 1.5rem;
  margin: auto;
`;

export const ButtonContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  width: 100%;
`;

export const Container = styled.div`
  padding: 1rem;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

export const HeaderWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 48px;
  justify-content: space-between;
  margin: 8px 8px 0 0;
`;

export const HeaderIconContainer = styled.div``;

export const PaymentContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  max-width: 1250px;
  justify-content: center;
  margin: 0 auto;
  gap: 2rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    flex-direction: column;
    max-width: 750px;
    gap: 0.5rem;
  }
`;

export const SubscriptionInfoText = styled.div`
  margin-top: 2rem;
  margin-bottom: 2rem;
  text-align: left;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  font-family: ${FontFamilies.Regular};
  color: rgba(255, 255, 255, 0.5);
`;

export const CardHeader = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 1rem;
  gap: 1rem;
  width: 100%;
`;

export const LeftBoxContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1rem;
  flex: 1;
  position: relative;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    width: 100%;
    padding: 2rem;
  }
`;

export const BackgroundImage = styled.img`
  position: absolute;
  width: 832px;

  object-fit: contain;
  z-index: -1;
  isolation: isolate;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    opacity: 0;
    pointer-events: none;
  }
`;

export const BackgroundImageAura = styled.img`
  position: absolute;
  width: 797px;
  transform: scale(2) rotate(-90deg);
  object-fit: cover;
  z-index: -1;
  top: 10%;
  isolation: isolate;
`;

export const LeftBoxHeaderIconContainer = styled.div`
  display: block;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    display: none;
  }
`;

export const RightBoxContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 4rem 2rem 1rem 2rem;
  align-items: center;
  gap: 20px;

  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    width: 100%;
  }
`;

export const Title = styled(TextBold)`
  font-size: 3rem;
  margin-top: 2rem;
  width: 100%;
  text-align: center;
`;

export const CouponContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 44px;
  display: flex;
  flex-direction: column;
`;

export const CouponGiftCardContainer = styled.div`
  margin-top: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 6px;
`;

export const CouponSection = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  gap: 2rem;
`;

export const CouponSectionInputWrapper = styled.div`
  flex: 3;
`;

export const CouponTextWrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

export const CouponText = styled(TextSemiBold)`
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.75);
`;

export const CouponButton = styled.button`
  border: none;
  outline: none;
  background: none;
  text-decoration: underline;
  font-size: 16px;
  line-height: 24px;
  color: rgba(255, 255, 255, 0.75);
  cursor: pointer;
  margin-left: 8px;
  font-family: ${FontFamilies.Regular};
`;

export const TweetContainer = styled.div`
  display: none;
  position: relative;
  overflow: hidden;
  padding: 24px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 12px;
  align-self: stretch;
  border-radius: 16px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(40px);
  margin-top: 2rem;
  max-width: 505px;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    max-width: 100%;
    display: flex;
  }

  &::after {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    width: 110%;
    height: 200%;
    background-image: url(${Assets.images.circleBlurTopBg.url});
    background-size: 200% 200%;
    background-repeat: no-repeat;
    background-position: center;
  }
`;

export const TweetContentRow = styled.div`
  display: flex;
  flex-direction: row;
  gap: 12px;

  align-items: stretch;
  width: 100%;
`;

export const TweetContentRowItem = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
`;

export const TweetDate = styled.div`
  color: rgba(255, 255, 255, 0.5);
  font-family: ${FontFamilies.Regular};
  font-size: 0.875rem;
  font-style: normal;
  line-height: 1.25rem;
`;

export const TweetNameContainer = styled.div`
  display: flex;
  flex-direction: row;
  gap: 4px;
  height: 20px;
`;

export const TweetImage = styled.img`
  width: 64px;
  height: 64px;
  flex-shrink: 0;
  border-radius: 800px;
  background: url(https://cdn.brain.fm/disposable/tweet_image_0.webp) lightgray 50% / cover
    no-repeat;
`;

export const TweetText = styled(Text)`
  font-size: 1rem;
  line-height: 1.25rem;
`;

export const TweetName = styled.div`
  color: rgba(255, 255, 255, 1);
  font-family: ${FontFamilies.Bold};
  font-size: 1rem;
  line-height: 1.25rem;
`;

export const TweetHandle = styled.div`
  color: rgba(255, 255, 255, 0.75);
  font-family: ${FontFamilies.Regular};
  font-size: 1rem;
  line-height: 1.5rem;
`;
