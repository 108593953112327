import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useWebMilestonesExperiment } from '../../../../../domains/Utils/useAmplitudeExperiments';
import { RootReducerType } from '../../../../../reducers';
import { milestonesSliceActions } from '../../../../../reducers/milestones';
import { useIsMilestonesWidgetEnabled } from './useIsMilestonesWidgetEnabled';

export const useUninterruptedListeningHandler = () => {
  const data = useWebMilestonesExperiment();
  const dispatch = useDispatch();
  const isEnabled = useIsMilestonesWidgetEnabled();
  const {
    items: { uninterruptedListening },
  } = useSelector((state: RootReducerType) => state.milestones);

  useEffect(() => {
    if (
      !data ||
      uninterruptedListening.completed ||
      !isEnabled ||
      !data.targetMinutesListened ||
      uninterruptedListening.targetMinutes
    ) {
      return;
    }
    dispatch(milestonesSliceActions.setUninterruptedTarget(data.targetMinutesListened));
  }, [data, dispatch, uninterruptedListening, isEnabled]);
};
