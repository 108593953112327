import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../reducers';
import { useReferralRewardProgramExperimentData } from '../../../domains/Utils/useAmplitudeExperiments';

export const useIsEligibleForReferralRewards = () => {
  const legacyMembershipInfo = useSelector((state: RootReducerType) => state.user.membership);
  const isReferAFriendRewards = useReferralRewardProgramExperimentData();
  const isStripeSubscription = legacyMembershipInfo?.source === 'Stripe';

  return isStripeSubscription && isReferAFriendRewards;
};
