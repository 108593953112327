import { ImgHTMLAttributes, ReactNode, CSSProperties } from 'react';
import * as S from './styles';
import CloseIcon from '../../../assets/images/close_icon.svg';
import BackIcon from '../../../assets/images/arrow_left_icon.svg';

type Props = {
  onClose: () => void;
  onBack?: () => void;
  title: string;
  description?: string;
  headerImage?: ImgHTMLAttributes<HTMLImageElement>['src'];
  children: ReactNode;
  headerStyles?: CSSProperties;
  headerImageStyles?: CSSProperties;
  descriptionStyles?: CSSProperties;
  containerStyles?: CSSProperties;
};

export const ModalContainer = ({
  onClose,
  onBack,
  title,
  description,
  descriptionStyles,
  headerImage,
  children,
  headerStyles,
  headerImageStyles,
  containerStyles,
}: Props) => {
  return (
    <S.Container style={containerStyles}>
      <S.Header style={headerStyles}>
        {onBack ? (
          <S.BackImageButton type="button" onClick={onBack}>
            <S.BackIcon src={BackIcon} />
          </S.BackImageButton>
        ) : null}
        <S.CloseImageButton type="button" onClick={onClose}>
          <S.CloseIcon src={CloseIcon} />
        </S.CloseImageButton>

        {headerImage && <S.Image src={headerImage} style={headerImageStyles} />}

        <div>
          <S.Title>{title}</S.Title>
          {description ? (
            <S.Description style={descriptionStyles}>{description}</S.Description>
          ) : null}
        </div>
      </S.Header>
      {children}
    </S.Container>
  );
};
