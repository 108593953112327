import { EffectLevels } from '@Model';

export const getNeuralEffectLevelDescription = (
  selectedNEL: string,
  mentalState: string | null,
): string => {
  if (mentalState === 'sleep' && selectedNEL === 'Medium') {
    return 'Medium strength delta waves for quality sleep.';
  }

  if (mentalState === 'relax' && selectedNEL === 'Medium') {
    return 'Medium strength alpha waves for deep relaxation.';
  }

  if (mentalState === 'meditate' && selectedNEL === 'Medium') {
    return 'Medium strength alpha waves to still your mind.';
  }

  switch (selectedNEL) {
    case 'Low':
      return "Text that explains more about the Low Neural Effect Level that's been selected.";
    case 'Medium':
      return 'Our standard level of brainwave technology is a great place to start.';
    case 'High':
      return 'Perfect if you need extra stimulation or have attentional challenges. (ADHD or simliar).';
    default:
      return 'Our standard level of brainwave technology is a great place to start.';
  }
};
