export const NEL_LEARN_MORE_LINK = 'https://brain.fm/science';

export const PRESET_GENRES = {
  modernBeats: ['Electronic', 'Grooves', 'Lofi'],
  epicAmbientSpacey: ['Atmospheric', 'Drone', 'Post Rock', 'Cinematic'],
  unplugged: ['Acoustic', 'Classical', 'Piano'],
  natureSoundscapes: [
    'Beach',
    'Chimes & Bowls',
    'Forest',
    'Nightsounds',
    'Rain',
    'Rainforest',
    'River',
    'Thunder',
    'Underwater',
    'Wind',
  ],
};

export const GENRE_TOGGLE_OPTION_STORAGE_KEY = 'genreToggleOption';
