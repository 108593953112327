import { TransitionStatus } from 'react-transition-group';
import styled, { css } from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  gap: 24px;
`;

export const FloatingWindow = styled.div<{ animationState: TransitionStatus }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  isolation: isolate;
  display: flex;
  transition:
    opacity 0.3s ease-in-out,
    transform 0.3s ease-in-out,
    background 0.3s ease-in-out 0.05s;

  ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return css`
          opacity: 0;
          transform: translateY(-0.75rem);
          background: transparent;
        `;
      case 'entered':
        return css`
          opacity: 1;
          transform: translateY(0);
          background: rgba(0, 0, 0, 0.6);
        `;
      case 'exiting':
        return css`
          opacity: 0;
          transform: translateY(0.75rem);
          background: transparent;
        `;
      case 'exited':
        return css`
          opacity: 0;
          transform: translateY(2.75rem);
          background: transparent;
        `;
    }
  }};
`;

export const CancelText = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.75rem;
  color: ${({ theme }) => theme.colors.white700};
  text-align: center;
  margin-bottom: 2rem;
`;
