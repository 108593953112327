import { Button, ButtonVariants, ButtonVariantStates, Panel } from '@Cortex';
import { NOOP } from '@Globals';
import { forwardRef } from 'react';
import { ModalContainer } from '../../../../../shared/ModalContainer';
import { TransitionStatus } from 'react-transition-group';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../../reducers';
import { getMonthlySubscriptionPriceFromPlans } from '../../../../../../utils/getMonthlySubscriptionPriceFromPlan';
import { Assets } from '../../../../../../utils/assets';

import * as S from './MonthlyDowngrade.styles';

export type Props = {
  isLoading: boolean;
  animationState: TransitionStatus;
  onSwitchToMonthlyPlan: () => void;
  onProceedToCancellation: () => void;
  onClose: () => void;
  onBack: () => void;
};

export const MonthlyDowngrade = forwardRef<HTMLDivElement, Props>(
  (
    {
      isLoading,
      animationState,
      onSwitchToMonthlyPlan = NOOP,
      onProceedToCancellation = NOOP,
      onClose = NOOP,
      onBack = NOOP,
    },
    ref,
  ) => {
    const membership = useSelector((state: RootReducerType) => state.membership);
    const membershipPlans = useSelector((state: RootReducerType) => state.membershipPlans);
    const monthlySubscriptionPrice = getMonthlySubscriptionPriceFromPlans(membershipPlans);

    return (
      <S.Container ref={ref} animationState={animationState}>
        <ModalContainer
          containerStyles={{
            maxWidth: '30rem',
            padding: '2.5rem',
            overflowY: 'visible',
          }}
          description={`Instead of committing to a full year of Brain.fm for ${membership?.renewalPrice}, you can switch to the Monthly Plan for ${monthlySubscriptionPrice}/mo.`}
          descriptionStyles={{
            fontSize: '0.8rem',
          }}
          headerImage={Assets.images.badgeWithStar.url}
          headerImageStyles={{
            height: '18.75rem',
            width: '18.75rem',
            objectFit: 'cover',
            top: '-7.875rem',
          }}
          title={`Switch to the Monthly Plan for ${monthlySubscriptionPrice}/mo.`}
          onBack={onBack}
          onClose={onClose}
        >
          <>
            <S.Blur
              alt={Assets.images.monthlyModalBlur.alt}
              src={Assets.images.monthlyModalBlur.url}
            />
            <S.SubmitButton>
              <Button
                data-testid="confirmUpgradeButton"
                isFullWidth={true}
                keepTextCase
                style={{ fontSize: '0.75rem' }}
                type="button"
                variant={ButtonVariants.Secondary}
                variantState={isLoading ? ButtonVariantStates.Loading : ButtonVariantStates.Idle}
                onClick={onSwitchToMonthlyPlan}
              >
                SWITCH TO MONTHLY PLAN
              </Button>
            </S.SubmitButton>
            <Button
              keepTextCase
              style={{ fontSize: '0.75rem' }}
              type="button"
              variant={ButtonVariants.Base}
              variantState={isLoading ? ButtonVariantStates.Loading : ButtonVariantStates.Idle}
              onClick={onProceedToCancellation}
            >
              PROCEED WITH CANCELLATION
            </Button>
          </>
        </ModalContainer>
      </S.Container>
    );
  },
);
