import styled from 'styled-components';
import InformationIconSVG from '../../../../assets/images/information_icon.svg?react';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: flex-start;
  justify-content: space-between;
  flex: 1;
`;

export const CancelWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  width: 100%;
  > div {
    font-size: 0.9rem;
  }
`;

export const CancelButton = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 1rem;
  color: #ff4949;
  cursor: pointer;
  padding: 0.5rem;
`;

export const PaymentMethod = styled.div`
  display: flex;
  width: 100%;
  padding: 1rem;
  flex-direction: row;
  justify-content: space-between;
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  font-weight: 500;
  margin-bottom: 2rem;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;
`;

export const RightPayment = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

export const TextMedium = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const Text = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  color: ${({ theme }) => theme.colors.white};
`;

export const EditButton = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  color: ${({ theme }) => theme.colors.white500};
  text-decoration: underline;
  cursor: pointer;
`;

export const PaymentButton = styled.div<{ hasBottomMargin?: boolean }>`
  ${props => (props.hasBottomMargin ? 'margin-bottom: 1rem;' : null)}
  width: 100%;
`;

export const InformationIcon = styled(InformationIconSVG)`
  width: 20px;
  height: 20px;
  opacity: 0.5;
`;

export const ExternalPaymentImage = styled.img`
  width: 20px;
  height: 20px;
`;
