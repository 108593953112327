import { RootReducerType } from '../reducers';

export function getMonthlySubscriptionPriceFromPlans(
  plans: RootReducerType['membershipPlans'],
): string | null {
  const monthlyPlan = plans.plans.ids.find(id => {
    return plans.plans.results[id]?.title === 'Monthly';
  });

  return monthlyPlan ? convertToPriceString(plans.plans.results[monthlyPlan].price) : null;
}

function convertToPriceString(price: number): string {
  const dollars = price / 100;
  return `$${dollars.toFixed(2)}`;
}
