import styled, { css } from 'styled-components';

export const ToggleContainer = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  border-radius: 52px;
  width: 52px;
  height: 32px;
  position: relative;
  transition:
    background 0.2s ease-in-out,
    box-shadow 0.2s ease-in-out,
    border 0.2s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.2);
  flex-shrink: 0;

  ${({ isActive }) =>
    isActive
      ? css`
          background: linear-gradient(90deg, #9333ea 0%, #f472b6 100%) border-box;
          box-shadow: inset 0 0 8px rgba(255, 255, 255, 0.5);
        `
      : css`
          background: transparent;
        `};
`;

export const Knob = styled.div<{ isActive: boolean }>`
  cursor: pointer;
  background-color: ${({ theme }) => theme.colors.white};
  width: 24px;
  height: 24px;
  border-radius: 100%;
  position: absolute;
  top: 50%;
  left: ${({ isActive }) => (isActive ? `calc(100% - 24px - 3px)` : '4px')};
  transform: translateY(-50%);
  box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;
`;
