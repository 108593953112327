import theme from 'src/styles/theme';
import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  position: relative;
  border-radius: 1rem;
  color: white;
  width: 100%;
  height: 12.5rem;
`;

export const BackgroundWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ImageWrapper = styled.div`
  margin-bottom: 1rem;
  width: 100px;
  img {
    width: 100%;
  }
`;

export const BlueGreenReviewsWrapper = styled.div`
  display: flex;
  position: relative;
  width: 100%;
`;

export const Review = styled.div<{ opacity: number }>`
  display: flex;
  flex-direction: column;
  position: absolute;
  opacity: ${props => props.opacity};
  padding: 2rem;
  transition: opacity 1s ease-in-out;
  border-radius: 1rem;
  background-color: rgba(255, 255, 255, 0.07);
  min-height: 12.5rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.lg} {
    padding: 1rem;
  }
`;

export const ReviewImage = styled.img`
  width: 48px;
  height: 48px;
  border-radius: 50%;
`;

export const ReviewHeader = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`;

export const ReviewBody = styled.div`
  display: inline;
  margin-top: 1rem;
  font-weight: 300;
  font-size: 1rem;
`;

export const ReviewAuthor = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.fontFamily.bold};
  font-size: 1rem;
`;

export const ReviewSubtitle = styled.div`
  color: ${({ theme }) => theme.colors.white700};
  font-family: ${({ theme }) => theme.fontFamily.regular};
  font-size: 1rem;
`;

export const ReviewDescription = styled.div`
  color: #d4d2ea;
  font-size: 0.8rem;
  font-weight: 300;
  opacity: 0.7;
`;

export const ReviewInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ReviewAuthorWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
`;

export const ReviewSpacer = styled.div`
  display: flex;
  flex-direction: column;
  opacity: 0;
`;

export const ReviewHighlight = styled.span`
  color: #fe5a83;
  display: inline;
`;

export const ReviewVerifiedImage = styled.img`
  width: 20px;
  height: 20px;
`;

export const ReviewThirdParty = styled.div`
  color: ${({ theme }) => theme.colors.white700};
  font-size: 0.75rem;
  font-weight: 300;
`;
