import styled, { keyframes } from 'styled-components';

import { FontColors } from '@Cortex';

export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 100;
  isolation: isolate;
  padding: 1rem;
  justify-content: center;
  display: flex;
  min-height: 100%;
  background: rgba(0, 0, 0, 0.6);
`;

export const ModalWrapper = styled.div`
  display: flex;
  align-self: center;
  max-width: 480px;
`;

export const ModalContent = styled.div`
  width: 100%;
  height: 100%;
  background-position-x: center;
  background-position-y: 60px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0 3rem;
`;

const bounceAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  20% {
    transform: scale(1.4);
  }
  40% {
    transform: scale(0.5);
  }
  60% {
    transform: scale(1.1);
  }
  80% {
    transform: scale(0.9);
  }
  100% {
    transform: scale(1);
  }
`;

export const BackgroundImage = styled.img`
  position: absolute;
  top: -6.5rem;
  width: 17rem;
  height: auto;
  align-self: center;
  z-index: 2;
  animation: ${bounceAnimation} 0.8s ease-in-out;
`;

export const Image = styled.img`
  position: absolute;
  bottom: 100%;
  width: 21rem;
  top: -9rem;
  height: auto;
  align-self: center;
  z-index: 3;
`;

export const AuraImage = styled.img`
  position: absolute;
  border-radius: 10px;
  top: 0;
  width: 100%;
  height: 100%;
  align-self: center;
  z-index: 0;
  pointer-events: none;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 6rem;
  margin-bottom: 0.5rem;
  z-index: 1;
`;

export const Title = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 2rem;
  text-align: center;
  color: #ffffff;
  margin-bottom: 1rem;
`;

export const Description = styled.div`
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 0.875rem;
  color: ${FontColors.WhiteTransparent};
  text-align: center;
  z-index: 1;
`;

export const ButtonWrapper = styled.div`
  margin-top: 2rem;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 2;
`;
