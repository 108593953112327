import { forwardRef } from 'react';
import * as S from './ProfileAccountInfo.styles';
import { ProfileInfo } from '@User';
import { useDispatch } from 'react-redux';
import { uiSliceActions } from '../../../../reducers/uiReducer';
import { SideDeckModalType } from '../../../../types';

export const ProfileAccountInfo = forwardRef<HTMLDivElement>((_, ref) => {
  const dispatch = useDispatch();

  return (
    <S.Container ref={ref}>
      <ProfileInfo
        onEditProfile={() =>
          dispatch(uiSliceActions.setSideDeckModal(SideDeckModalType.EditProfile))
        }
        onPasswordChange={() =>
          dispatch(uiSliceActions.setSideDeckModal(SideDeckModalType.UpdatePassword))
        }
      />
    </S.Container>
  );
});
