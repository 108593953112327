import { TransitionStatus } from 'react-transition-group';
import styled from 'styled-components';

export const Wrapper = styled.div`
  position: relative;
  margin-top: -100px;
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    margin-top: -140px;
  }
`;

export const Value = styled.div`
  font-size: 20rem;
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-variant: tabular-nums;
  ${({ theme }) => theme.mediaQuery.maxWidth.xl} {
    font-size: 21rem;
  }
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    font-size: 16rem;
  }
  ${({ theme }) => theme.mediaQuery.minWidth.md && theme.mediaQuery.maxHeight.custom(740)} {
    font-size: 10rem;
  }
  ${({ theme }) => theme.mediaQuery.minWidth.md && theme.mediaQuery.maxHeight.custom(580)} {
    font-size: 8rem;
  }
  ${({ theme }) => theme.mediaQuery.minWidth.md && theme.mediaQuery.maxHeight.custom(480)} {
    font-size: 5rem;
  }
`;

export const Separator = styled.div`
  position: absolute;
  top: 82%;
  width: 100px;
  left: 50%;
  border-radius: 5px;
  border-top: 3px solid ${({ theme }) => theme.colors.white300};
  transform: translateX(-50%);
`;

export const Description = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.colors.white600};
  font-family: ${({ theme }) => theme.font.family.ttnorms.medium};
  font-size: 1rem;
  top: 85%;
  width: 200px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  letter-spacing: 0.2rem;
`;

export const Comment = styled.div`
  position: absolute;
  color: ${({ theme }) => theme.colors.white500};
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
  top: 91%;
  width: 300px;
  text-align: center;
  left: 50%;
  transform: translateX(-50%);
  max-width: 80vw;
  margin-top: 10px;
  ${({ theme }) => theme.mediaQuery.minWidth.md && theme.mediaQuery.maxHeight.custom(580)} {
    display: none;
  }
`;

export const PrevValueContainer = styled.div<{ animationState: TransitionStatus }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
  transition: all 0.3s ease-in-out;

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 1;
      case 'entered':
        return 1;
      case 'exiting':
        return 0;
      case 'exited':
        return 0;
    }
  }};
  top: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return 0;
      case 'exiting':
        return '-100vh';
      case 'exited':
        return '-100vh';
    }
  }};
`;

export const CurrentValueContainer = styled.div<{ animationState: TransitionStatus }>`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-around;
  position: relative;
  transition: all 0.3s ease-in-out;

  opacity: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return 0;
      case 'entered':
        return 1;
      case 'exiting':
        return 0;
      case 'exited':
        return 0;
    }
  }};
  top: ${({ animationState }) => {
    switch (animationState) {
      case 'entering':
        return '-100vh';
      case 'entered':
        return 0;
      case 'exiting':
        return 0;
      case 'exited':
        return 0;
    }
  }};
`;
