import { NOOP } from '@Globals';
import { cancelStripeSubscription } from '@Payment';
import * as paymentActions from '../../../../actions/payments';
import { useCallback, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { RootReducerType } from '../../../../reducers';
import { CancelMembershipDisplay } from './CancelMembership.display';
import { getMonthlySubscriptionIdFromPlans } from '../../../../utils/getMonthlySubscriptionIdFromPlans';
import { useIsMonthlyDowngradeEnabled } from '@Utils';
import { PlanTypes } from '../../../../domains/Analytics/coreAnalytics.types';

type CancellationOption = 'confirmation' | 'cancellation' | 'monthlyDowngrade';

interface Props {
  onClose?: () => void;
}

export const CancelMembership = ({ onClose = NOOP }: Props) => {
  const dispatch = useDispatch();
  const [state, setState] = useState<CancellationOption>('confirmation');
  const membershipTitle = useSelector((state: RootReducerType) => state.user.membership?.title);
  const isOnTrial = useSelector((state: RootReducerType) => state.membership.isOnTrial);
  const membershipPlans = useSelector((state: RootReducerType) => state.membershipPlans);
  const isYearly = membershipTitle === 'Yearly';
  const monthlySubscriptionId = getMonthlySubscriptionIdFromPlans(membershipPlans);
  const inFlightRequest = useRef(false);
  const isMonthlyDowngradeExperimentEnabled = useIsMonthlyDowngradeEnabled();
  const isMonthlyDowngradeAvailable = isOnTrial && isYearly && isMonthlyDowngradeExperimentEnabled;

  const handleOnContinue = useCallback(() => {
    if (state === 'confirmation') {
      if (isMonthlyDowngradeAvailable) {
        setState('monthlyDowngrade');
      } else {
        setState('cancellation');
      }
    } else {
      setState('cancellation');
    }
  }, [isOnTrial, isYearly, isMonthlyDowngradeExperimentEnabled, state]);

  const handleOnSwitchToMonthlyPlan = useCallback(() => {
    if (!monthlySubscriptionId || inFlightRequest.current) return;

    inFlightRequest.current = true;
    dispatch(
      paymentActions.updateMembership({
        membershipId: monthlySubscriptionId,
        planType: PlanTypes.Monthly,
      }),
    );
    onClose();
  }, [monthlySubscriptionId]);

  const handleOnProceedToCancellation = (reason: string) => {
    dispatch(cancelStripeSubscription({ reason }));
    onClose();
  };

  const handleOnBack = useCallback(() => {
    if (state === 'monthlyDowngrade') {
      setState('confirmation');
    } else if (state === 'cancellation') {
      if (isMonthlyDowngradeAvailable) {
        setState('monthlyDowngrade');
      } else {
        setState('confirmation');
      }
    }
  }, [state, isMonthlyDowngradeAvailable]);

  return (
    <CancelMembershipDisplay
      isUpdatingMembership={inFlightRequest.current}
      state={state}
      onBack={handleOnBack}
      onClose={onClose}
      onContinue={handleOnContinue}
      onProceedToCancellation={handleOnProceedToCancellation}
      onSwitchToMonthlyPlan={handleOnSwitchToMonthlyPlan}
    />
  );
};
