import * as S from './Testimonials.styles';
import { Assets } from '../../../../../../../../utils/assets';
import { OnboardingTestimonials, ReviewSource } from '../../../../../../../Onboarding/types';
import { useEffect, useState } from 'react';

type Props = {
  paywallTestimonials: OnboardingTestimonials[];
};

export const Testimonials = ({ paywallTestimonials }: Props) => {
  const [currentReviewCardForAnimation, setCurrentReviewCardForAnimation] = useState<
    'blue' | 'green'
  >('blue');
  const [blueReviewCardIndex, setBlueReviewCardIndex] = useState(0);
  const [greenReviewCardIndex, setGreenReviewCardIndex] = useState(1);
  const blueReviewCard = paywallTestimonials[blueReviewCardIndex];
  const greenReviewCard = paywallTestimonials[greenReviewCardIndex];

  useEffect(() => {
    function animateReviews() {
      if (currentReviewCardForAnimation === 'blue') {
        setGreenReviewCardIndex((greenReviewCardIndex + 2) % paywallTestimonials.length);
        setCurrentReviewCardForAnimation('green');
      } else {
        setBlueReviewCardIndex((blueReviewCardIndex + 2) % paywallTestimonials.length);
        setCurrentReviewCardForAnimation('blue');
      }
    }

    setTimeout(animateReviews, 5000);
  }, [currentReviewCardForAnimation]);

  const renderHighlightedText = (text: string) => {
    const parts = text.split(/\*([^*]+)\*/g);

    return (
      <S.ReviewBody>
        {parts.map((part, index) => {
          if (index % 2 === 1) {
            return <S.ReviewHighlight key={index}>{part}</S.ReviewHighlight>;
          } else {
            return <span key={index}>{part}</span>;
          }
        })}
      </S.ReviewBody>
    );
  };

  return (
    <S.Container>
      <S.BackgroundWrapper>
        <S.BlueGreenReviewsWrapper>
          <S.Review opacity={currentReviewCardForAnimation === 'blue' ? 1 : 0}>
            <S.ReviewHeader>
              {blueReviewCard.imageUrl && (
                <S.ReviewImage alt="User profile" src={blueReviewCard.imageUrl} />
              )}
              <S.ReviewInfoWrapper>
                <S.ReviewAuthorWrapper>
                  <S.ReviewAuthor>{blueReviewCard.author}</S.ReviewAuthor>
                  {blueReviewCard.source?.type === ReviewSource.TWITTER &&
                    blueReviewCard.source.verified && (
                      <S.ReviewVerifiedImage
                        alt={Assets.images.blueVerified.alt}
                        src={Assets.images.blueVerified.url}
                      />
                    )}
                  {blueReviewCard.source?.type === ReviewSource.OTHER && (
                    <S.ReviewThirdParty>• 3rd+</S.ReviewThirdParty>
                  )}
                </S.ReviewAuthorWrapper>
                <S.ReviewSubtitle>{blueReviewCard.subtitle}</S.ReviewSubtitle>
              </S.ReviewInfoWrapper>
            </S.ReviewHeader>
            {renderHighlightedText(blueReviewCard.text)}
          </S.Review>
          {greenReviewCardIndex === -1 ? null : (
            <S.Review opacity={currentReviewCardForAnimation === 'green' ? 1 : 0}>
              <S.ReviewHeader>
                {greenReviewCard.imageUrl && (
                  <S.ReviewImage alt="User profile" src={greenReviewCard.imageUrl} />
                )}
                <S.ReviewInfoWrapper>
                  <S.ReviewAuthorWrapper>
                    <S.ReviewAuthor>{greenReviewCard.author}</S.ReviewAuthor>
                    {greenReviewCard.source?.type === ReviewSource.TWITTER &&
                      greenReviewCard.source?.verified && (
                        <S.ReviewVerifiedImage
                          alt={Assets.images.blueVerified.alt}
                          src={Assets.images.blueVerified.url}
                        />
                      )}
                    {greenReviewCard.source?.type === ReviewSource.OTHER && (
                      <S.ReviewThirdParty>• 3rd+</S.ReviewThirdParty>
                    )}
                  </S.ReviewAuthorWrapper>
                  <S.ReviewSubtitle>{greenReviewCard.subtitle}</S.ReviewSubtitle>
                </S.ReviewInfoWrapper>
              </S.ReviewHeader>
              {renderHighlightedText(greenReviewCard.text)}
            </S.Review>
          )}
        </S.BlueGreenReviewsWrapper>
      </S.BackgroundWrapper>
    </S.Container>
  );
};
