import { useCallback, useEffect } from 'react';
import { DesktopAnnouncementBig } from './components/DesktopAnnouncementBig/display';
import { DesktopAnnouncementSmall } from './components/DesktopAnnouncementSmall/display';

import { trackDesktopAnnouncementBannerExperimentExposure } from '../../../Utils/useAmplitudeExperiments';
import { Analytics } from '../../../../utils/analytics';
import { useDispatch } from 'react-redux';
import { uiSliceActions } from '../../../../reducers/uiReducer';
type Props = {
  isMobileView: boolean;
  isVisible: boolean;
  onClose: () => void;
  onDownload: () => void;
};

export function DesktopAnnouncementContainer({
  isMobileView,
  isVisible,
  onClose,
  onDownload,
}: Props) {
  const dispatch = useDispatch();
  useEffect(() => {
    if (isVisible) {
      trackDesktopAnnouncementBannerExperimentExposure();
    }
  }, [isVisible]);

  const handleClickClose = useCallback(() => {
    onClose();
    Analytics.logEvent('test_desktop_announcement_banner_dismiss');
  }, []);

  const handleClickDownload = useCallback(() => {
    onDownload();
    Analytics.logEvent('test_desktop_announcement_banner_click_download');
  }, []);

  const handleOpenModal = useCallback(() => {
    dispatch(uiSliceActions.setModalType('desktopAnnouncementMobileModal'));
  }, [dispatch]);

  if (!isVisible) {
    return null;
  }

  return isMobileView ? (
    <DesktopAnnouncementSmall onClick={handleOpenModal} />
  ) : (
    <DesktopAnnouncementBig onClickClose={handleClickClose} onClickDownload={handleClickDownload} />
  );
}
