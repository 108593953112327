import {
  OnboardingCardTypes,
  OnboardingQuizAnswerTypes,
  OnboardingSpecification,
  ReviewSource,
} from './types';

export const ONBOARDING_MENTAL_STATE_ID_KEY = 'onboardingMentalStateId';
export const ONBOARDING_DYNAMIC_ACTIVITY_ID_KEY = 'onboardingDynamicActivityId';
export const ONBOARDING_DELAYED_HEAR_THE_DIFFERENCE_KEY = 'onboardingDelayedHearTheDifference';
export const ONBOARDING_DELAYED_TEST_TIMER = 3 * 1000 * 60;
export const ONBOARDING_VARIATION_ID_KEY = 'onboardingVariationId';
export const ONBOARDING_CUSTOM_PROPERTY_KEY = 'core_custom_value_a';
export const DELAYED_PAYWALL_EXPERIMENT_HAS_RUN_KEY = 'delayed_paywall_experiment_has_run';

export const HEAR_THE_DIFFERENCE_DELAYED_VARIATION_NOSKIP = 'hear_the_difference_delay_noskip';
export const HEAR_THE_DIFFERENCE_DELAYED_VARIATION = 'hear_the_difference_delay';

export const DEFAULT_ONBOARDING: OnboardingSpecification = {
  id: 'september_2024__intro_card__fallback',
  cards: [],
  quizSpecification: {
    id: 'september_2024__intro_card__fallback',
    scores: [
      {
        id: 'nel',
        name: 'nel score',
        breakpoints: [4],
      },
      {
        id: 'genres',
        name: 'genres score',
        breakpoints: [1, 3, 5],
      },
    ],
    questions: [
      {
        title: "What's your focus goal?",
        answers: [
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Get motivated',
            value: 0,
            benefits: [
              'Get motivated and crush procrastination',
              'Enjoy getting in the zone and do more than ever',
              'Diverse genres tailored for you',
              'Unlimited listening on web and mobile',
              'New sounds added every week',
            ],
            testimonials: [
              {
                text: 'I’m a big fan of the *@‌brainfmapp*. I don’t think I’ve found a playlist on Spotify that rivals Brain.fm’s ability to keep me in the zone.',
                author: 'Steve Fink',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/steve_fink.webp',
                subtitle: '@finksta',
                source: {
                  type: ReviewSource.TWITTER,
                  verified: true,
                },
              },
              {
                text: '*@brainfmapp* is one of the best investments I make each year. I use it for several hours a day. 10x better than all the “brain music” playlists on Spotify.',
                author: 'Zac Garside',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/zac_garside.webp',
                subtitle: '@zacgarside',
                source: {
                  type: ReviewSource.TWITTER,
                  verified: false,
                },
              },
              {
                text: 'Thank you, guys! Won’t be going anywhere. The app has completely changed my life. No more Spotify, YT music or Calm.',
                author: 'Zac Magee',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/zac_magee.webp',
                subtitle: '@zacjohnm',
                source: {
                  type: ReviewSource.TWITTER,
                  verified: true,
                },
              },
              {
                text: 'I use *Brain.fm* for deep work music and have for almost ten years. Highly recommend!',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/carrie_murphy.webp',
                author: 'Carrie Murphy',
                subtitle: 'Writer | Doula | Mother | Content & copywriter for...',
                source: {
                  type: ReviewSource.OTHER,
                  verified: false,
                },
              },
            ],
            responseCards: [
              {
                body: 'Users who listen for at least *2 hours a week* report feeling *4x more motivated.*',
                type: OnboardingCardTypes.InfoTwo,
                image: 'https://cdn.brain.fm/images/onboarding/onboarding_lets_get_started.webp',
                title: "Let's get started",
                stepText: 'Goals',
                isSkippable: false,
                ctaButtonText: 'Continue',
              },
            ],
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Block distractions',
            value: 0,
            benefits: [
              'Reduce distractions and keep your focus',
              'Work effectively and do more than ever',
              'Diverse genres tailored for you',
              'Unlimited listening on web and mobile',
              'New sounds added every week',
            ],
            testimonials: [
              {
                text: 'I’m a big fan of the *@‌brainfmapp*. I don’t think I’ve found a playlist on Spotify that rivals Brain.fm’s ability to keep me in the zone.',
                author: 'Steve Fink',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/steve_fink.webp',
                subtitle: '@finksta',
                source: {
                  type: ReviewSource.TWITTER,
                  verified: true,
                },
              },
              {
                text: '*@brainfmapp* is one of the best investments I make each year. I use it for several hours a day. 10x better than all the “brain music” playlists on Spotify.',
                author: 'Zac Garside',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/zac_garside.webp',
                subtitle: '@zacgarside',
                source: {
                  type: ReviewSource.TWITTER,
                  verified: false,
                },
              },
              {
                text: 'Thank you, guys! Won’t be going anywhere. The app has completely changed my life. No more Spotify, YT music or Calm.',
                author: 'Zac Magee',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/zac_magee.webp',
                subtitle: '@zacjohnm',
                source: {
                  type: ReviewSource.TWITTER,
                  verified: true,
                },
              },
              {
                text: 'I use *Brain.fm* for deep work music and have for almost ten years. Highly recommend!',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/carrie_murphy.webp',
                author: 'Carrie Murphy',
                subtitle: 'Writer | Doula | Mother | Content & copywriter for...',
                source: {
                  type: ReviewSource.OTHER,
                  verified: false,
                },
              },
            ],
            responseCards: [
              {
                body: 'Users who listen for at least *2 hours a week* report *3x fewer focus disruptions.*',
                type: OnboardingCardTypes.InfoTwo,
                image:
                  'https://cdn.brain.fm/images/onboarding/onboarding_stay_on_task_updated.webp',
                title: 'Stay on task longer',
                stepText: 'Goals',
                isSkippable: false,
                ctaButtonText: 'Continue',
              },
            ],
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Stay energized',
            value: 0,
            benefits: [
              'Stay focused all day without fatigue',
              'Find consistent productivity and do more than ever',
              'Diverse genres tailored for you',
              'Unlimited listening on web and mobile',
              'New sounds added every week',
            ],
            testimonials: [
              {
                text: 'I’m a big fan of the *@‌brainfmapp*. I don’t think I’ve found a playlist on Spotify that rivals Brain.fm’s ability to keep me in the zone.',
                author: 'Steve Fink',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/steve_fink.webp',
                subtitle: '@finksta',
                source: {
                  type: ReviewSource.TWITTER,
                  verified: true,
                },
              },
              {
                text: '*@brainfmapp* is one of the best investments I make each year. I use it for several hours a day. 10x better than all the “brain music” playlists on Spotify.',
                author: 'Zac Garside',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/zac_garside.webp',
                subtitle: '@zacgarside',
                source: {
                  type: ReviewSource.TWITTER,
                  verified: false,
                },
              },
              {
                text: 'Thank you, guys! Won’t be going anywhere. The app has completely changed my life. No more Spotify, YT music or Calm.',
                author: 'Zac Magee',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/zac_magee.webp',
                subtitle: '@zacjohnm',
                source: {
                  type: ReviewSource.TWITTER,
                  verified: true,
                },
              },
              {
                text: 'I use *Brain.fm* for deep work music and have for almost ten years. Highly recommend!',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/carrie_murphy.webp',
                author: 'Carrie Murphy',
                subtitle: 'Writer | Doula | Mother | Content & copywriter for...',
                source: {
                  type: ReviewSource.OTHER,
                  verified: false,
                },
              },
            ],
            responseCards: [
              {
                body: 'Users who listen for at least *2 hours a week* report getting *2x more work done.*',
                type: OnboardingCardTypes.InfoTwo,
                image: 'https://cdn.brain.fm/images/onboarding/onboarding_stay_in_the_zone.webp',
                title: 'Stay in the zone longer',
                stepText: 'Goals',
                isSkippable: false,
                ctaButtonText: 'Continue',
              },
            ],
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Other',
            value: 0,
            benefits: [
              'Find your focus any time, any place',
              'Get more done and have fun doing it',
              'Diverse genres tailored for you',
              'Unlimited listening on web and mobile',
              'New sounds added every week',
            ],
            testimonials: [
              {
                text: 'I’m a big fan of the *@‌brainfmapp*. I don’t think I’ve found a playlist on Spotify that rivals Brain.fm’s ability to keep me in the zone.',
                author: 'Steve Fink',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/steve_fink.webp',
                subtitle: '@finksta',
                source: {
                  type: ReviewSource.TWITTER,
                  verified: true,
                },
              },
              {
                text: '*@brainfmapp* is one of the best investments I make each year. I use it for several hours a day. 10x better than all the “brain music” playlists on Spotify.',
                author: 'Zac Garside',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/zac_garside.webp',
                subtitle: '@zacgarside',
                source: {
                  type: ReviewSource.TWITTER,
                  verified: false,
                },
              },
              {
                text: 'Thank you, guys! Won’t be going anywhere. The app has completely changed my life. No more Spotify, YT music or Calm.',
                author: 'Zac Magee',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/zac_magee.webp',
                subtitle: '@zacjohnm',
                source: {
                  type: ReviewSource.TWITTER,
                  verified: true,
                },
              },
              {
                text: 'I use *Brain.fm* for deep work music and have for almost ten years. Highly recommend!',
                imageUrl: 'https://cdn.brain.fm/images/testimonials/carrie_murphy.webp',
                author: 'Carrie Murphy',
                subtitle: 'Writer | Doula | Mother | Content & copywriter for...',
                source: {
                  type: ReviewSource.OTHER,
                  verified: false,
                },
              },
            ],
            responseCards: [
              {
                body: 'Users who listen for at least 2 hours a week report \n   •  *4x more motivation*  \n   •  *3x fewer distractions* \n   •  *2x more work done*',
                type: OnboardingCardTypes.InfoTwo,
                image: 'https://cdn.brain.fm/images/onboarding/onboarding_boost_productivity.webp',
                title: 'Boost your productivity',
                stepText: 'Goals',
                isSkippable: false,
                ctaButtonText: 'Continue',
              },
            ],
          },
        ],
        scoreId: 'nel',
        stepText: 'Goals',
      },
      {
        title: 'What kind of work do you plan to do today?',
        answers: [
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Creative Work (art, design, video editing)',
            value: 0,
            benefits: [],
            testimonials: [],
            responseCards: [
              {
                body: "We've selected the perfect music to support your work session today.",
                type: OnboardingCardTypes.InfoTwo,
                image: 'https://cdn.brain.fm/images/creativity_card.webp',
                title: 'Music Tailored to Your Work',
                stepText: 'Your Work',
                bodyImage: 'https://cdn.brain.fm/images/creativity_minicard.webp',
                isSkippable: false,
                ctaButtonText: 'Continue',
              },
            ],
            dynamicActivityId: 'yourFirstSessionD_nrwXg0f1harJZF2RbDDWP',
            userPropertyToSet: {
              key: 'core_onboarding_activity_personalization',
              value: 'Creative Work (art, design, video editing)',
            },
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Writing (articles, reports, fiction)',
            value: 0,
            benefits: [],
            testimonials: [],
            responseCards: [
              {
                body: "We've selected the perfect music to support your work session today.",
                type: OnboardingCardTypes.InfoTwo,
                image: 'https://cdn.brain.fm/images/deep_work_card.webp',
                title: 'Music Tailored to Your Work',
                stepText: 'Your Work',
                bodyImage: 'https://cdn.brain.fm/images/deep_work_minicard.webp',
                isSkippable: false,
                ctaButtonText: 'Continue',
              },
            ],
            dynamicActivityId: 'yourFirstSessionD_QYN78dJrrzs5mD_TJgBcq',
            userPropertyToSet: {
              key: 'core_onboarding_activity_personalization',
              value: 'Writing (articles, reports, fiction)',
            },
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Data analysis (spreadsheets, research)',
            value: 0,
            benefits: [],
            testimonials: [],
            responseCards: [
              {
                body: "We've selected the perfect music to support your work session today.",
                type: OnboardingCardTypes.InfoTwo,
                image: 'https://cdn.brain.fm/images/deep_work_card.webp',
                title: 'Music Tailored to Your Work',
                stepText: 'Your Work',
                bodyImage: 'https://cdn.brain.fm/images/deep_work_minicard.webp',
                isSkippable: false,
                ctaButtonText: 'Continue',
              },
            ],
            dynamicActivityId: 'yourFirstSessionD_QYN78dJrrzs5mD_TJgBcq',
            userPropertyToSet: {
              key: 'core_onboarding_activity_personalization',
              value: 'Data analysis (spreadsheets, research)',
            },
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Studying (exams, coursework, reading)',
            value: 0,
            benefits: [],
            testimonials: [],
            responseCards: [
              {
                body: "We've selected the perfect music to support your work session today.",
                type: OnboardingCardTypes.InfoTwo,
                image: 'https://cdn.brain.fm/images/learning_card.webp',
                title: 'Music Tailored to Your Work',
                stepText: 'Your Work',
                bodyImage: 'https://cdn.brain.fm/images/learning_minicard.webp',
                isSkippable: false,
                ctaButtonText: 'Continue',
              },
            ],
            dynamicActivityId: 'yourFirstSessionD_CaoevjfzVc_RT6wyQZyO3',
            userPropertyToSet: {
              key: 'core_onboarding_activity_personalization',
              value: 'Studying (exams, coursework, reading)',
            },
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Coding and Programming',
            value: 0,
            benefits: [],
            testimonials: [],
            responseCards: [
              {
                body: "We've selected the perfect music to support your work session today.",
                type: OnboardingCardTypes.InfoTwo,
                image: 'https://cdn.brain.fm/images/deep_work_card.webp',
                title: 'Music Tailored to Your Work',
                stepText: 'Your Work',
                bodyImage: 'https://cdn.brain.fm/images/deep_work_minicard.webp',
                isSkippable: false,
                ctaButtonText: 'Continue',
              },
            ],
            dynamicActivityId: 'yourFirstSessionD_QYN78dJrrzs5mD_TJgBcq',
            userPropertyToSet: {
              key: 'core_onboarding_activity_personalization',
              value: 'Coding and Programming',
            },
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Administrative tasks (emails, scheduling)',
            value: 0,
            benefits: [],
            testimonials: [],
            responseCards: [
              {
                body: "We've selected the perfect music to support your work session today.",
                type: OnboardingCardTypes.InfoTwo,
                image: 'https://cdn.brain.fm/images/light_work_card.webp',
                title: 'Music Tailored to Your Work',
                stepText: 'Your Work',
                bodyImage: 'https://cdn.brain.fm/images/light_work_minicard.webp',
                isSkippable: false,
                ctaButtonText: 'Continue',
              },
            ],
            dynamicActivityId: 'yourFirstSessionD_dliRCb7TLb0hOGe_Jou3e',
            userPropertyToSet: {
              key: 'core_onboarding_activity_personalization',
              value: 'Administrative tasks (emails, scheduling)',
            },
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Planning and strategizing',
            value: 0,
            benefits: [],
            testimonials: [],
            responseCards: [
              {
                body: "We've selected the perfect music to support your work session today.",
                type: OnboardingCardTypes.InfoTwo,
                image: 'https://cdn.brain.fm/images/deep_work_card.webp',
                title: 'Music Tailored to Your Work',
                stepText: 'Your Work',
                bodyImage: 'https://cdn.brain.fm/images/deep_work_minicard.webp',
                isSkippable: false,
                ctaButtonText: 'Continue',
              },
            ],
            dynamicActivityId: 'yourFirstSessionD_QYN78dJrrzs5mD_TJgBcq',
            userPropertyToSet: {
              key: 'core_onboarding_activity_personalization',
              value: 'Planning and strategizing',
            },
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Reading and research',
            value: 0,
            benefits: [],
            testimonials: [],
            responseCards: [
              {
                body: "We've selected the perfect music to support your work session today.",
                type: OnboardingCardTypes.InfoTwo,
                image: 'https://cdn.brain.fm/images/learning_card.webp',
                title: 'Music Tailored to Your Work',
                stepText: 'Your Work',
                bodyImage: 'https://cdn.brain.fm/images/learning_minicard.webp',
                isSkippable: false,
                ctaButtonText: 'Continue',
              },
            ],
            dynamicActivityId: 'yourFirstSessionD_CaoevjfzVc_RT6wyQZyO3',
            userPropertyToSet: {
              key: 'core_onboarding_activity_personalization',
              value: 'Reading and research',
            },
          },
        ],
        scoreId: 'nel',
        stepText: 'Activity',
        description: 'We’ll use your answer to select the best music for your work.',
      },
      {
        title: 'Do you have ADHD? ',
        answers: [
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Yes',
            value: 4,
            responseCards: [
              {
                body: 'Our research, funded by the National Science Foundation, has found that ADHD individuals focus best to High Neural Effect Level.',
                type: OnboardingCardTypes.Faq,
                image: 'https://cdn.brain.fm/images/focus_onboarding_high.webp',
                items: [
                  {
                    image: 'https://cdn.brain.fm/images/onboarding_modulation.webp',
                    label: 'What is brainwave modulation?',
                    title: 'What is brainwave modulation?',
                    listElements: [
                      'When you are deeply focused, your neurons fire together at a specific frequency.',
                      'If they aren’t synchronized, focusing can be hard.',
                      'Brain.fm’s music includes rapid volume pulses that serve as a stimulus so your brain will sync up.',
                      'After about 5 minutes, your neurons will match the fluctuations in the music, making it easier for you to stay focused.',
                    ],
                  },
                  {
                    image: 'https://cdn.brain.fm/images/onboarding_3_nels.webp',
                    label: 'What is Neural Effect Level?',
                    title: 'What is Neural Effect Level?',
                    listElements: [
                      'The strength of neuromodulation embedded in the music.',
                      'Brain.fm  offers three levels of modulation: Low, Medium, and High.',
                      'Low is best if you are overstimulated or anxious. High is best for ADHD brains, or if you need an extra boost of stimulation!',
                    ],
                  },
                ],
                title: 'Brainwave modulation set to High',
                stepText: 'Effect Level Customization',
                ctaButtonText: 'Continue',
              },
            ],
            userPropertyToSet: {
              key: 'core_custom_value_a',
              value: true,
            },
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Maybe, but not diagnosed',
            value: 4,
            responseCards: [
              {
                body: 'Our research, funded by the National Science Foundation, has found that ADHD individuals focus best to High Neural Effect Level.',
                type: OnboardingCardTypes.Faq,
                image: 'https://cdn.brain.fm/images/focus_onboarding_high.webp',
                items: [
                  {
                    image: 'https://cdn.brain.fm/images/onboarding_modulation.webp',
                    label: 'What is brainwave modulation?',
                    title: 'What is brainwave modulation?',
                    listElements: [
                      'When you are deeply focused, your neurons fire together at a specific frequency',
                      'If they aren’t synchronized, focusing can be hard',
                      'Brain.fm’s music includes rapid volume pulses that serve as a stimulus so your brain will sync up.',
                      'After about 5 minutes, your neurons will match the fluctuations in the music, making it easier for you to stay focused',
                    ],
                  },
                  {
                    image: 'https://cdn.brain.fm/images/onboarding_3_nels.webp',
                    label: 'What is Neural Effect Level?',
                    title: 'What is Neural Effect Level?',
                    listElements: [
                      'The strength of neuromodulation embedded in the music.',
                      'Brain.fm  offers three levels of modulation: Low, Medium, and High.',
                      'Low is best if you are overstimulated or anxious. High is best for ADHD brains, or if you need an extra boost of stimulation!',
                    ],
                  },
                ],
                title: 'Brainwave modulation set to High',
                stepText: 'Effect Level Customization',
                ctaButtonText: 'Continue',
              },
            ],
            userPropertyToSet: {
              key: 'core_custom_value_a',
              value: true,
            },
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'No',
            value: 0,
            responseCards: [
              {
                body: 'Our standard Neural Effect Level is a great place to start.',
                type: OnboardingCardTypes.Faq,
                image: 'https://cdn.brain.fm/images/focus_onboarding_medium.webp',
                items: [
                  {
                    image: 'https://cdn.brain.fm/images/onboarding_modulation.webp',
                    label: 'What is brainwave modulation?',
                    title: 'What is brainwave modulation?',
                    listElements: [
                      'When you are deeply focused, your neurons fire together at a specific frequency',
                      'If they aren’t synchronized, focusing can be hard',
                      'Brain.fm’s music includes rapid volume pulses that serve as a stimulus so your brain will sync up.',
                      'After about 5 minutes, your neurons will match the fluctuations in the music, making it easier for you to stay focused',
                    ],
                  },
                  {
                    image: 'https://cdn.brain.fm/images/onboarding_3_nels.webp',
                    label: 'What is Neural Effect Level?',
                    title: 'What is Neural Effect Level?',
                    listElements: [
                      'The strength of neuromodulation embedded in the music.',
                      'Brain.fm  offers three levels of modulation: Low, Medium, and High.',
                      'Low is best if you are overstimulated or anxious. High is best for ADHD brains, or if you need an extra boost of stimulation!',
                    ],
                  },
                ],
                title: 'Brainwave modulation set to Medium',
                stepText: 'Effect Level Customization',
                ctaButtonText: 'Continue',
              },
            ],
            userPropertyToSet: {
              key: 'core_custom_value_a',
              value: false,
            },
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'I’d prefer not to respond',
            value: 0,
            responseCards: [
              {
                body: 'Our standard Neural Effect Level is a great place to start.',
                type: OnboardingCardTypes.Faq,
                image: 'https://cdn.brain.fm/images/focus_onboarding_medium.webp',
                items: [
                  {
                    image: 'https://cdn.brain.fm/images/onboarding_modulation.webp',
                    label: 'What is brainwave modulation?',
                    title: 'What is brainwave modulation?',
                    listElements: [
                      'When you are deeply focused, your neurons fire together at a specific frequency',
                      'If they aren’t synchronized, focusing can be hard',
                      'Brain.fm’s music includes rapid volume pulses that serve as a stimulus so your brain will sync up.',
                      'After about 5 minutes, your neurons will match the fluctuations in the music, making it easier for you to stay focused',
                    ],
                  },
                  {
                    image: 'https://cdn.brain.fm/images/onboarding_3_nels.webp',
                    label: 'What is Neural Effect Level?',
                    title: 'What is Neural Effect Level?',
                    listElements: [
                      'The strength of neuromodulation embedded in the music.',
                      'Brain.fm  offers three levels of modulation: Low, Medium, and High.',
                      'Low is best if you are overstimulated or anxious. High is best for ADHD brains, or if you need an extra boost of stimulation!',
                    ],
                  },
                ],
                title: 'Brainwave modulation set to Medium',
                stepText: 'Effect Level Customization',
                ctaButtonText: 'Continue',
              },
            ],
          },
        ],
        scoreId: 'nel',
        stepText: 'Effect Level',
        description: 'Sharing helps us optimize your experience, ADHD or not.',
        hasPrivacyNotice: true,
      },
      {
        title: 'What styles of music do you like to work to?',
        answers: [
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Modern Beats',
            value: 6,
            description: 'Electronic, Grooves, Lofi',
            responseCards: [
              {
                type: OnboardingCardTypes.Hero,
                title: 'We’ll personalize your sounds based on these genres!',
                stepText: 'Genre Customization',
                ctaButtonText: 'Apply personalization',
                mobileBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/beats_mobile_genres_personalization.png',
                desktopBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/beats_genres_personalization.png',
              },
            ],
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Epic, Ambient, and Spacey',
            value: 4,
            description: 'Atmospheric, Drone, Post Rock, Cinematic',
            responseCards: [
              {
                type: OnboardingCardTypes.Hero,
                title: 'We’ll personalize your sounds based on these genres!',
                stepText: 'Genre Customization',
                ctaButtonText: 'Apply personalization',
                mobileBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/ambient_mobile_genres_personalization.png',
                desktopBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/ambient_genres_personalization.png',
              },
            ],
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Unplugged',
            value: 2,
            description: 'Acoustic, Piano, Classical',
            responseCards: [
              {
                type: OnboardingCardTypes.Hero,
                title: 'We’ll personalize your sounds based on these genres!',
                stepText: 'Genre Customization',
                ctaButtonText: 'Apply personalization',
                mobileBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/unplugged_mobile_genres_personalization.png',
                desktopBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/unplugged_genres_personalization.png',
              },
            ],
          },
          {
            type: OnboardingQuizAnswerTypes.Default,
            title: 'Nature Soundscapes',
            value: 0,
            description: 'Rain, Beach, Thunder, Forest, and More',
            responseCards: [
              {
                type: OnboardingCardTypes.Hero,
                title: 'We’ll personalize your sounds based on these genres!',
                stepText: 'Genre Customization',
                ctaButtonText: 'Apply personalization',
                mobileBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/nature_mobile_genres_personalization.png',
                desktopBackgroundImage:
                  'https://cdn.brain.fm/images/onboarding/nature_genres_personalization.png',
              },
            ],
          },
        ],
        scoreId: 'genres',
        stepText: 'Genres',
      },
    ],
    neurotypeMatrix: [
      [
        {
          id: 'med_nel_nature',
          resultCards: [],
          preferredFocusGenreNames: [
            'Beach',
            'Chimes & Bowls',
            'Forest',
            'Nightsounds',
            'Rain',
            'Rainforest',
            'River',
            'Thunder',
            'Underwater',
            'Wind',
          ],
          preferredFocusNeuralEffectLevels: ['Medium'],
        },
        {
          id: 'med_nel_unplugged',
          resultCards: [],
          preferredFocusGenreNames: ['Acoustic', 'Piano', 'Classical'],
          preferredFocusNeuralEffectLevels: ['Medium'],
        },
        {
          id: 'med_nel_epic_ambient',
          resultCards: [],
          preferredFocusGenreNames: ['Atmospheric', 'Drone', 'Post Rock', 'Cinematic'],
          preferredFocusNeuralEffectLevels: ['Medium'],
        },
        {
          id: 'med_nel_modern_beats',
          resultCards: [],
          preferredFocusGenreNames: ['Electronic', 'Grooves', 'Lofi'],
          preferredFocusNeuralEffectLevels: ['Medium'],
        },
      ],
      [
        {
          id: 'high_nel_nature',
          resultCards: [],
          preferredFocusGenreNames: [
            'Beach',
            'Chimes & Bowls',
            'Forest',
            'Nightsounds',
            'Rain',
            'Rainforest',
            'River',
            'Thunder',
            'Underwater',
            'Wind',
          ],
          preferredFocusNeuralEffectLevels: ['High'],
        },
        {
          id: 'high_nel_unplugged',
          resultCards: [],
          preferredFocusGenreNames: ['Acoustic', 'Piano', 'Classical'],
          preferredFocusNeuralEffectLevels: ['High'],
        },
        {
          id: 'high_nel_epic_ambient',
          resultCards: [],
          preferredFocusGenreNames: ['Atmospheric', 'Drone', 'Post Rock', 'Cinematic'],
          preferredFocusNeuralEffectLevels: ['High'],
        },
        {
          id: 'high_nel_modern_beats',
          resultCards: [],
          preferredFocusGenreNames: ['Electronic', 'Grooves', 'Lofi'],
          preferredFocusNeuralEffectLevels: ['High'],
        },
      ],
    ],
  },
};
