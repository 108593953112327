export const getActivityHeaderDescription = (mentalState: string | null): string => {
  if (!mentalState) {
    return 'We’ve tailored your settings based on your onboarding answers to maximize your focus';
  }

  switch (mentalState) {
    case 'focus':
      return 'We’ve tailored your settings based on your onboarding answers to maximize your focus.';
    case 'relax':
      return 'We’ve tailored your settings based on your onboarding answers to maximize your relaxation.';
    case 'sleep':
      return 'We’ve tailored your settings based on your onboarding answers to maximize your sleep.';
    case 'meditate':
      return 'We’ve tailored your settings based on your onboarding answers to maximize your meditation.';
    default:
      return 'We’ve tailored your settings based on your onboarding answers to maximize your focus.';
  }
};
