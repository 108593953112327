import { useGetAvailableStripeCouponPromotion, MembershipSources } from '@Memberships';
import { useIsPaymentProcessing } from '@Payment';
import { useSelector } from 'react-redux';
import { RootReducerType } from '../../../../../../reducers';
import { useMembershipPeriodTimeLeft } from '../../hooks/useMembershipPeriodTimeLeft';
import { MembershipPanelWrapper } from '../MembershipPanelWrapper/MembershipPanelWrapper';
import { GracePeriodCallToActionPresentation } from './GracePeriodCallToAction.presentation';

type Props = {
  onClickUpdatePaymentDetails?: () => void;
};

export const GracePeriodCallToAction = (props: Props) => {
  const {
    gracePeriodTimeLeftInDays,
    gracePeriodTimeLeftInHours,
    gracePeriodTimeLeftInMinutes,
    gracePeriodTimeLeftInSeconds,
  } = useMembershipPeriodTimeLeft();
  const membershipTitle = useSelector((state: RootReducerType) => state.user.membership?.title);
  const isPaymentProcessing = useIsPaymentProcessing();
  const membershipSource = useSelector(
    (state: Pick<RootReducerType, 'membership'>) => state.membership.source,
  );
  const isLoading = membershipSource === MembershipSources.Loading || isPaymentProcessing;
  const title = `${membershipTitle} Plan`;
  const subtitle = `It looks like your default payment method is no longer working! No worries, we’ve provided
        you a grace period to get any details updated.`;

  return (
    <MembershipPanelWrapper isExpired isLoading={isLoading} subtitle={subtitle} title={title}>
      <GracePeriodCallToActionPresentation
        daysRemaining={gracePeriodTimeLeftInDays}
        hoursRemaining={gracePeriodTimeLeftInHours % 24}
        minutesRemaining={gracePeriodTimeLeftInMinutes % 60}
        secondsRemaining={gracePeriodTimeLeftInSeconds}
        onClickUpdatePaymentDetails={props.onClickUpdatePaymentDetails}
      />
    </MembershipPanelWrapper>
  );
};
