import { MenuItem, MenuItemIcons, Button, ButtonVariants, ButtonVariantSizes } from '@Cortex';
import { Assets } from '../../../../utils/assets';
import * as S from './ReferAFriendWidget.styles';
import AuraSVGImage from './assets/aura.svg';

export type Props = {
  icon?: MenuItemIcons;
  isMobileView: boolean;
  onClickButton: () => void;
  onClickClose?: () => void;
};
export const ReferAFriendWidgetPresentation = (props: Props) => {
  const { icon, onClickButton, onClickClose, isMobileView } = props;

  const bannerText = isMobileView ? 'Give' : 'Friends get';
  const buttonText = isMobileView ? 'REFER' : 'REFER A FRIEND';

  return (
    <S.CallToActionWidget>
      <S.Body data-testid="referAFriendWidgetBody">
        <S.Icon src={Assets.icons.referBold.url} />
        {bannerText} 30 days <S.Highlight>FREE</S.Highlight>
      </S.Body>
      <S.Row>
        <S.GradientButton
          data-testid="referAFriendWidgetDesktop"
          keepTextCase={true}
          size={ButtonVariantSizes.Small}
          variant={ButtonVariants.Secondary}
          onClick={onClickButton}
        >
          <S.ButtonContent>{buttonText}</S.ButtonContent>
        </S.GradientButton>
        <S.CloseButtonContainer>
          <MenuItem
            icon={props.icon || MenuItemIcons.Alert}
            isActive={true}
            onClickIcon={props.onClickClose}
          />
        </S.CloseButtonContainer>
      </S.Row>
      <S.SVGImage src={AuraSVGImage} />
    </S.CallToActionWidget>
  );
};
