export const getSessionTimeDescription = (mentalState: string | null): string => {
  if (!mentalState) {
    return 'Plan at least 30 minutes of uninterrupted focus time.'; // Fallback to focus if no mental state is provided
  }

  switch (mentalState) {
    case 'focus':
      return 'Plan at least 30 minutes of uninterrupted focus time.';
    case 'relax':
      return 'Plan at least 15 minutes to unwind and destress.';
    case 'sleep':
      return 'Plan at least 10 minutes to wind down.';
    case 'meditate':
      return 'Plan at least 15 minutes to quiet your mind and relax.';
    default:
      return 'Plan at least 30 minutes of uninterrupted focus time.';
  }
};
