import { NOOP } from '@Globals';
import { MembershipSources, MembershipStates } from '@Memberships';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../../reducers';
import { getCardInformationDisplayValue } from '../../lib/getCardInformationDisplayValue';
import { useIsPaymentProcessing } from '../../lib/useIsPaymentProcessing';
import { BillingPanel } from './BillingPanel.display';
import { Assets } from '../../../../utils/assets';

export interface Props {
  onClickCancel?: () => void;
  onPaymentMethodChange?: () => void;
}

export function BillingPanelContainer({
  onClickCancel = NOOP,
  onPaymentMethodChange = NOOP,
}: Props) {
  const isPaymentProcessing = useIsPaymentProcessing();
  const membership = useSelector((state: Pick<RootReducerType, 'membership'>) => state.membership);

  const getTooltipText = () => {
    if (membership.source === MembershipSources.Apple) {
      return 'You signed up for your Brain.fm subscription through the Apple App store. \n \nYou’ll need to manage your payment method through the mobile device used to start your subscription.';
    }

    if (membership.source === MembershipSources.Google) {
      return 'You signed up for your Brain.fm subscription through the Google Play store. \n \nYou’ll need to manage your payment method through the mobile device used to start your subscription.';
    }

    return undefined;
  };

  const getExternalPaymentImageSrc = () => {
    if (membership.source === MembershipSources.Apple) {
      return Assets.icons.apple.url;
    }

    if (membership.source === MembershipSources.Google) {
      return Assets.icons.googlePlayStore.url;
    }

    return undefined;
  };

  if (membership.source === MembershipSources.Loading || isPaymentProcessing)
    return <BillingPanel cardInformation="" isLoading={true} />;

  return membership.source === MembershipSources.Stripe ? (
    <BillingPanel
      cardInformation={getCardInformationDisplayValue(membership)}
      updatePaymentButtonText={membership.cardType ? 'Edit' : 'Add payment method'}
      onClickCancel={membership.state === MembershipStates.Cancelled ? undefined : onClickCancel}
      onClickUpdatePaymentMethod={onPaymentMethodChange}
    />
  ) : (
    <BillingPanel
      cardInformation={`${membership.source} Subscription`}
      externalPaymentImageSrc={getExternalPaymentImageSrc()}
      tooltipText={getTooltipText()}
    />
  );
}
