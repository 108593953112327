import { Text } from '@Cortex';
import styled from 'styled-components';

export const redAura = {
  outer: `linear-gradient(313.03deg, #F34675 34.15%, rgba(243, 70, 117, 0) 92.55%)`,
  inner: `linear-gradient(232.01deg, rgba(255, 0, 31, 0.8) -3.6%, rgba(207, 61, 87, 0.8) 68.92%)`,
};

export const normalAura = {
  outer:
    'linear-gradient(187.11deg, rgba(208, 26, 234, 0.8) 19.55%, rgba(44, 168, 234, 0.8) 87.99%)',
  inner:
    'linear-gradient(240.5deg, rgba(208, 26, 234, 0.8) 16.31%, rgba(44, 168, 234, 0.8) 67.25%)',
};

// the z-indexes ensure that the aura effect does not overlap the header texts!

export const Container = styled.div`
  border-radius: inherit;
  color: white;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1.5rem;
  width: 100%;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 12px;

  // safari has a bug with overflow:hidden on absolutely positioned children;
  // this is a workaround for it.
  // https://stackoverflow.com/questions/44948735/overflow-hidden-is-not-working-with-absolute-element-in-safari
  transform: translateZ(0);
`;

export const Header = styled.div`
  display: flex;
  flex-direction: column;
`;

export const HeaderIcon = styled.div`
  margin-right: 0.6rem;
  position: relative;
  z-index: 1; // see note at top
`;

export const HeaderText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
`;

export const HeaderMainInfo = styled.div`
  display: flex;
  align-items: center;
`;
export const HeaderAdditionalInfo = styled.div`
  display: flex;
  flex-direction: column;
`;
export const HeaderTextTitle = styled.div`
  color: white;
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 2rem;
`;

export const HeaderTextSubtitle = styled.div`
  color: rgba(255, 255, 255, 0.9);
  font-family: ${({ theme }) => theme.font.family.ttnorms.regular};
  font-size: 1rem;
`;

export const Expired = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: #ff4949;
  padding: 6px;
  border-radius: 56px;
  backdrop-filter: blur(10px);
  gap: 5px;
`;

export const ExpiredIcon = styled.img`
  height: 16px;
  width: 16px;
  display: block;
`;

export const ExpiredText = styled.div`
  color: ${({ theme }) => theme.colors.white};
  font-family: ${({ theme }) => theme.font.family.ttnorms.bold};
  font-size: 0.75rem;
  letter-spacing: 1.2px;
  margin-top: 1px;
`;

export const Divider = styled.div`
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
  margin: 1.5rem 0;
`;

export const Children = styled.div`
  text-transform: initial;
  z-index: 2; // see note at top
`;
