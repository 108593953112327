import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export interface MilestonesState {
  hasTakenOnboardingWhileInNonControlTest: boolean;
  isDismissed: boolean;
  items: {
    firstActivity: {
      completed: boolean;
      timestamp?: number;
    };
    uninterruptedListening: {
      completed: boolean;
      targetMinutes: number;
      timestamp?: number;
    };
    favorites: {
      completed: boolean;
      timestamp?: number;
      target: number;
    };
  };
  progressModal: {
    isOpen: boolean;
    data: {
      title: string;
      description: string;
      icon?: 'stepsIcon';
      buttonText: string;
    };
  };
}

const initialState: MilestonesState = {
  hasTakenOnboardingWhileInNonControlTest: false,
  isDismissed: false,
  items: {
    firstActivity: {
      completed: false,
      timestamp: undefined,
    },
    uninterruptedListening: {
      completed: false,
      targetMinutes: 0, // Will be set from amplitude experiments
      timestamp: undefined,
    },
    favorites: {
      completed: false,
      target: 3,
      timestamp: undefined,
    },
  },
  progressModal: {
    isOpen: false,
    data: {
      title: '',
      description: '',
      icon: undefined,
      buttonText: '',
    },
  },
};

const milestonesSlice = createSlice({
  name: 'milestones',
  initialState,
  reducers: {
    setOnboardingTaken: state => {
      state.hasTakenOnboardingWhileInNonControlTest = true;
    },
    setUninterruptedTarget: (state, action: PayloadAction<number>) => {
      state.items.uninterruptedListening.targetMinutes = action.payload;
    },
    completeFirstActivity: state => {
      if (!state.items.firstActivity.completed) {
        state.items.firstActivity.completed = true;
        state.items.firstActivity.timestamp = Date.now();
      }
    },
    completeUninterruptedListening: state => {
      if (!state.items.uninterruptedListening.completed) {
        state.items.uninterruptedListening.completed = true;
        state.items.uninterruptedListening.timestamp = Date.now();
      }
    },
    completeFavorites: state => {
      if (!state.items.favorites.completed) {
        state.items.favorites.completed = true;
        state.items.favorites.timestamp = Date.now();
      }
    },
    dismissWidget: state => {
      state.isDismissed = true;
    },
    setModal: (state, action: PayloadAction<MilestonesState['progressModal']['data']>) => {
      state.progressModal.isOpen = true;
      state.progressModal.data = action.payload;
    },
    resetModal: state => {
      state.progressModal = initialState.progressModal;
    },
    resetState: () => initialState,
  },
});

const milestonesPersistConfig = {
  key: 'milestones',
  storage: storage,
  whitelist: ['items', 'hasTakenOnboardingWhileInNonControlTest', 'isDismissed'],
};

export const milestonesSliceActions = milestonesSlice.actions;
export default persistReducer(milestonesPersistConfig, milestonesSlice.reducer);
