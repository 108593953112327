import { createAction } from '@reduxjs/toolkit';

import { ModalType, SessionModalContent, SuccessModalType } from '../types';

export const setSessionFinished = createAction<boolean>('UI_SET_SESSION_FINISHED');
export const setExploreModalOpen = createAction('UI_SET_EXPLORE_MODAL');
export const setModal = createAction<ModalType>('UI_SET_MODAL');
export const successModalClear = createAction('UI_SET_SUCCESS_MODAL_CLEAR');
export const successModalOpen = createAction<Partial<SuccessModalType>>(
  'UI_SET_SUCCESS_MODAL_OPEN',
);
export const successModalClose = createAction('UI_SET_SUCCESS_MODAL_CLOSE');
export const setPlayerVolume = createAction<number>('UI_SET_PLAYER_VOLUME');
export const setShouldShowNELTipsModal = createAction<boolean>('UI_SET_SHOULD_SHOW_NELTIPS');
export const setSessionModalContent = createAction<{
  sessionModalContent: SessionModalContent | null;
  origin?: string;
}>('UI_SET_SESSION_SIDEBAR_CONTENT');

export const setPlayerAnimationState = createAction<boolean>('UI_SET_PLAYER_ANIMATION');
