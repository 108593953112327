import { StreakTypes } from '@User';
import { useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModal } from '../../../../../../actions/ui';

import { useStreaks } from '../../../../../../hooks';
import { RootReducerType } from '../../../../../../reducers';
import { Analytics } from '../../../../../../utils/analytics';
import { Events } from '../../../../../../utils/analytics/events';
import { Logger } from '../../../../../../utils/logger';
import { useFavoritesMilestone } from './hooks/useFavoritesMilestone';
import { useStreakMilestone } from './hooks/useStreakMilestone';
import { StreaksWidgetModalDisplay } from './StreaksWidgetModal.display';
import { SHARE_TEXT } from './constants';

export const StreaksWidgetModal = () => {
  const dispatch = useDispatch();
  const [isCopied, setIsCopies] = useState(false);
  const { streaks, streakType } = useStreaks();
  const mentalStateDisplayValue = useSelector(
    (state: RootReducerType) =>
      state.sessionManager.sessionDynamicActivity?.mentalState.displayValue,
  );

  const favoritesMilestone = useFavoritesMilestone();
  const streakMilestone = useStreakMilestone();

  const modalType = useSelector((state: RootReducerType) => state.ui.modalType);
  const isActive = Boolean(modalType && ['streaksInfo', 'streaksInfoAnimated'].includes(modalType));
  const isStreaksAnimated = modalType === 'streaksInfoAnimated';

  const handleClickOutside = useCallback(() => {
    if (modalType !== 'streaksInfoAnimated' && modalType !== 'streaksInfo') return;
    dispatch(setModal(null));
  }, [dispatch, modalType]);

  const handleShare = useCallback(async () => {
    try {
      if (window.navigator?.clipboard) {
        await window.navigator.clipboard.writeText(SHARE_TEXT(streaks.data.weekly.currentCount));
        Analytics.logEventWithProperties(Events.core_streak_share, {
          core_streak_weekly_count_current: streaks.data.weekly.currentCount,
          mental_state: mentalStateDisplayValue,
        });
        setIsCopies(true);
      } else {
        Logger.error(new Error('Clipboard API not supported'));
      }
    } catch (error) {
      Logger.error(new Error('Failed to copy share text to clipboard'), { reason: error });
    } finally {
      setTimeout(() => {
        setIsCopies(false);
      }, 1500);
    }
  }, [streaks.data.weekly.currentCount, setIsCopies, mentalStateDisplayValue]);

  if (streakType === StreakTypes.Hidden) return null;

  return (
    <StreaksWidgetModalDisplay
      favoritesMilestone={favoritesMilestone}
      isActive={isActive}
      isCopied={isCopied}
      isStreaksAnimated={isStreaksAnimated}
      streakMilestone={streakMilestone}
      streaks={streaks}
      onOutsideClick={handleClickOutside}
      onShare={handleShare}
    />
  );
};
