import { FontColors, Icon, Text } from '@Cortex';
import { NOOP } from '@Globals';
import {
  PomodoroPhases,
  useIsPomodoroActive,
  usePomodoroPhase,
  useTimerDisplayValue,
} from '@Timer';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { RootReducerType } from '../../../../reducers';
import { Assets } from '../../../../utils/assets';
import { formatTime } from '../../utils/formatTime';
import * as S from './Timer.styles';
import { useTimerTypeText } from '../../../../domains/Timer/useTimerTypeText';
import { useTimerTypeIcon } from '../../utils/useTimerTypeIcon';

interface TimerProps {
  afterCountdownText: string;
  countdownText: string;
  onClick?: (e: React.MouseEvent) => void;
}

export const Timer: React.FC<TimerProps> = ({
  countdownText,
  afterCountdownText,
  onClick = NOOP,
}) => {
  const isPomodoro = useIsPomodoroActive();
  const pomodoroPhase = usePomodoroPhase();
  const timerDisplayValue = useTimerDisplayValue();
  const timerTypeIcon = useTimerTypeIcon();
  const timerTypeText = useTimerTypeText();

  const [isHovered, setIsHovered] = useState(false);
  const [timerText, setTimerText] = useState(countdownText);
  const [timePassMark, setTimePassMark] = useState(false);

  const { sessionPlayTime, currentTrackTimeStamp, sessionPlayType, timerLength, timerPlayTime } =
    useSelector((state: RootReducerType) => state.sessionManager);
  const { focusTime, breakTime } = useSelector(
    (state: RootReducerType) => state.timer.pomodoroSettings.intervals,
  );

  useEffect(() => {
    // Account for when mental state is change, to set countdownText and reset timePassMark
    setTimerText(countdownText);
    setTimePassMark(false);
  }, [countdownText]);

  const renderTimer = () => {
    if (sessionPlayType === 'TIMER') {
      const totalTimerPlayTime = (timerPlayTime || 0) + currentTrackTimeStamp;
      const timerDisplay = (timerLength || 0) - totalTimerPlayTime;

      return formatTime(timerDisplay >= 0 ? timerDisplay : 0);
    } else {
      const currentTime = sessionPlayTime + currentTrackTimeStamp;
      let time = currentTime;

      if (time >= 300 && !timePassMark) {
        setTimePassMark(true);
        setTimerText(afterCountdownText);
      }

      return timerDisplayValue;
    }
  };

  const pomodoroText =
    pomodoroPhase === PomodoroPhases.Work
      ? `${focusTime} MINUTE${focusTime !== '1' ? 'S' : ''} OF WORK`
      : `${breakTime} MINUTE BREAK`;
  const timerSessionText =
    sessionPlayType === 'TIMER' ? 'TIME LEFT' : isPomodoro ? pomodoroText : timerText;

  return (
    <S.TimerContainer
      data-testid="centerTimer"
      onClick={onClick}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <S.SessionTypeTextContainer>
        <S.SessionTypeText isVisible={isHovered}>CLICK TO TOGGLE TIMER SETTINGS</S.SessionTypeText>
        <S.SessionTypeText isVisible={!isHovered}>{timerSessionText}</S.SessionTypeText>
      </S.SessionTypeTextContainer>
      <S.TimerDisplay data-testid="timerDisplay">{renderTimer()}</S.TimerDisplay>
      <S.Pill>
        <Icon size={24} src={timerTypeIcon} />
        <Text style={{ textAlign: 'center', marginRight: '8px' }}>{timerTypeText}</Text>
        <Icon
          size={24}
          src={Assets.icons.dropdownArrow.url}
          style={{ position: 'relative', top: '1px' }}
        />
      </S.Pill>
    </S.TimerContainer>
  );
};
