import styled from 'styled-components';
import { FontColors } from '@Cortex';
import colors from '../../../../../../styles/colors';
import { GenreToggleOption } from './GenreToggle';

export const ToggleContainer = styled.div<{ option: GenreToggleOption }>`
  display: flex;
  flex-shrink: 0;
  align-items: center;
  height: 40px;
  width: 228px;
  padding: 2px;
  border-radius: 52px;
  position: relative;
  cursor: pointer;
  background-color: rgba(13, 12, 17, 0.5);

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    width: 100%;
  }
`;

export const PresetsOption = styled.div<{ option: GenreToggleOption }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  z-index: 1;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  color: ${props => (props.option === 'presets' ? FontColors.Black : FontColors.White)};
  transition: color 0.3s ease;
  padding: 8px;
`;

export const CustomOption = styled.div<{ option: GenreToggleOption }>`
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 1;
  z-index: 1;
  font-size: 0.875rem;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  color: ${props => (props.option === 'custom' ? FontColors.Black : FontColors.White)};
  transition: color 0.3s ease;
  padding: 8px;
  gap: 4px;
`;

export const CustomSelected = styled.div<{ option: GenreToggleOption }>`
  display: flex;
  height: 18px;
  width: 20px;
  padding: 1px 6px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  color: ${props => (props.option === 'custom' ? FontColors.White : FontColors.Black)};
  background-color: ${props =>
    props.option === 'custom' ? '#0D0C11' : 'rgba(255, 255, 255, 0.5)'};
  border-radius: 16px;
  font-family: ${({ theme }) => theme.fontFamily.medium};
  font-size: 12px;
`;

export const ToggleKnob = styled.div<{ option: GenreToggleOption }>`
  width: 112px;
  height: 36px;
  background: white;
  border-radius: 18px;
  border-radius: 60px;
  position: absolute;
  left: ${props => (props.option === 'custom' ? '50%' : '2px')};
  right: ${props => (props.option === 'presets' ? '2px' : '50%')};
  transition: left 0.3s ease;
  box-shadow: 0 0 2px rgba(0, 0, 0, 0.2);

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    width: 50%;
  }
`;
