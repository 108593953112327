import { useSelector } from 'react-redux';
import { RootReducerType } from '../../reducers';
import { useToken } from '@Authentication';
import Amplitude from 'amplitude-js';
import { BfmAnalytics } from '@brainfm/analytics';
import packageJson from '../../../package.json';
import { useEffect } from 'react';
import { API_V3_BASE_URL } from '@Globals';

export function initializeBrainFmAnalytics() {
  const userId = useSelector((state: RootReducerType) => state.user.info?.id);
  const userToken = useToken();
  const deviceId = Amplitude.getInstance().getDeviceId();
  const sessionId = Amplitude.getInstance().getSessionId();

  useEffect(() => {
    if (!userId || !userToken || !sessionId || !deviceId) return;

    BfmAnalytics.getInstance().init({
      baseUrl: API_V3_BASE_URL,
      userId: userId,
      token: userToken,
      sessionId: sessionId,
      deviceId: deviceId,
      platform: 'web',
      version: packageJson.version,
    });
  }, [userId, userToken]);
}
