import { Button, ButtonVariants } from '@Cortex';
import { NOOP } from '@Globals';

import { Assets } from '../../../../../../utils/assets';
import * as S from './TrialCallToAction.styles';

export interface Props {
  callToActionText?: string;
  onCTAClick?: () => void;
  isMobileView?: boolean;
}

export function TrialCallToActionPresentation({
  callToActionText = 'SUBSCRIBE',
  onCTAClick = NOOP,
  isMobileView = false,
}: Props) {
  return (
    <S.MainContainer>
      <S.Title>Get in your zone, and stay there.</S.Title>
      <S.Benefits>
        <S.Checkmark
          alt={Assets.icons.checkmarkGradient.alt}
          src={Assets.icons.checkmarkGradient.url}
        />
        <S.Container>
          <S.BenefitsTitle>Unlimited Sessions</S.BenefitsTitle>
          <S.BenefitsSubtitle>Access to 1000+ tracks</S.BenefitsSubtitle>
        </S.Container>
      </S.Benefits>
      <S.Benefits>
        <S.Checkmark
          alt={Assets.icons.checkmarkGradient.alt}
          src={Assets.icons.checkmarkGradient.url}
        />
        <S.Container>
          <S.BenefitsTitle>Access all Categories</S.BenefitsTitle>
          <S.BenefitsSubtitle>Discover the perfect music for your brain.</S.BenefitsSubtitle>
        </S.Container>
      </S.Benefits>
      <S.Benefits>
        <S.Checkmark
          alt={Assets.icons.checkmarkGradient.alt}
          src={Assets.icons.checkmarkGradient.url}
        />
        <S.Container>
          <S.BenefitsTitle>Access to our Mobile App</S.BenefitsTitle>
          <S.BenefitsSubtitle>
            Take brain.fm on the go with our mobile application, available for iPhone and Android.
          </S.BenefitsSubtitle>
        </S.Container>
      </S.Benefits>
      <div>
        <S.Image
          alt={Assets.images.subscriptionWidgetFocus.alt}
          src={Assets.images.subscriptionWidgetFocus.url}
        />
      </div>
      <S.PrimaryButtonWrapper>
        <Button
          data-testid="subscriptionButton"
          isFullWidth
          keepTextCase={true}
          style={{ fontSize: '0.75rem' }}
          variant={ButtonVariants.Secondary}
          onClick={onCTAClick}
        >
          {callToActionText}
        </Button>
      </S.PrimaryButtonWrapper>
    </S.MainContainer>
  );
}
