import styled, { keyframes, css } from 'styled-components';

import { SESSION_ANIMATION_DURATION_MS } from './utils/constants';
import { Assets } from '../../utils/assets';

export const fadeinAndRise = keyframes`
  from {
    opacity: 0;
    transform: translateY(50px);
  }
  to   {
    opacity: 1;
    transform: translateY(0);
  }
`;

export const PageWrapper = styled.div<{ backgroundImageUrl?: string | null }>`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: #000000;
  background-size: cover;
  background-position: center;
  z-index: 1;

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
    ${({ backgroundImageUrl }) =>
      backgroundImageUrl
        ? `background-image: linear-gradient(rgba(0, 0, 0, 0), 90%, rgba(0, 0, 0, 1)), url(${backgroundImageUrl});`
        : null};
    background-size: cover;
    background-position: center;
    /* 50% opacity for the image and fade-in effect for initial loading */
    opacity: ${({ backgroundImageUrl }) => (Boolean(backgroundImageUrl) ? 0.5 : 0)};
    z-index: 2;
  }
`;

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
`;

export const Overlay = styled.div<{ backgroundColor: string }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Layer 3: Black overlay */
  z-index: 3;

  &:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    transition: all 0.4s ease-in-out;
    background-color: ${({ backgroundColor }) => backgroundColor}; /* Layer 4: overlay */
    z-index: 4;
  }
`;

export const PageContent = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  z-index: 5;
`;

export const SessionWrapper = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  animation: ${fadeinAndRise} ${SESSION_ANIMATION_DURATION_MS}ms;
  overflow: hidden;
  position: relative;
`;

export const BackgroundWrapper = styled.div`
  position: relative;
  flex: 1;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

export const QuizToastWrapper = styled.div`
  animation: ${({ theme }) => theme.animations.fadeIn} 0.6s ease-in-out;
  display: flex;
  position: absolute;
  z-index: 100;

  // magic, just line up with player on main screen
  bottom: 10rem;
  right: 1rem;
  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    bottom: 13.5rem;
    right: 0rem;
  }
`;

export const SessionNELTipWrapper = styled.div`
  animation: ${({ theme }) => theme.animations.fadeIn} 0.6s ease-in-out;
  display: flex;
  position: absolute;
  z-index: 100;
  isolation: isolate;
  // magic, just line up with player on main screen
  bottom: 12rem;
  right: 2rem;

  ${({ theme }) => theme.mediaQuery.maxWidth.md} {
    bottom: 14rem;
    right: 1rem;
  }
`;

export const SidebarContentContainer = styled.div<{ isCentered?: boolean }>`
  display: flex;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 100;
`;

export const PreferencesContentContainer = styled(SidebarContentContainer)`
  background: rgba(0, 0, 0, 0.5);
`;

export const CloseButtonContainer = styled.div`
  position: absolute;
  top: 2rem;
  right: 2rem;
  z-index: 999;
`;

export const CloseButton = styled.img`
  cursor: pointer;
`;

export const GradientContainerStyleTransparent = {
  background:
    'linear-gradient(180deg, rgba(255, 255, 255, 0.10) 0%, rgba(255, 255, 255, 0.01) 100%)',
  boxShadow: '0px -8px 24px 0px rgba(0, 0, 0, 0.30)',
  backdropFilter: 'blur(25px)',
  WebkitBackdropFilter: 'blur(25px)',
};

export const GradientContainerStyle = {
  border: '1px solid rgba(255, 255, 255, 0.2)',
  backgroundColor: '#0d0c11',
  backgroundImage: `url(${Assets.images.blurBg.url})`,
  backgroundRepeat: 'no-repeat',
  backgroundSize: 'cover',
  backgroundPosition: 'center center',
};
