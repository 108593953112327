import { Button, ButtonVariants, Panel } from '@Cortex';
import React from 'react';
import ReactModal from 'react-modal';
import { baseStyles } from '../../../modals/modalStyles';
import { Assets } from '../../../utils/assets';

import * as S from './AppDownloadModal.styles';

export type Props = {
  isIos: boolean;
  isOpen: boolean;
  onClose: () => void;
  primaryButtonAction: () => void;
  primaryButtonText: string;
  secondaryButtonText: string;
};

export function AppDownloadModalDisplay(props: Props) {
  const { isIos, isOpen, onClose, primaryButtonAction, primaryButtonText, secondaryButtonText } =
    props;
  return (
    <ReactModal isOpen={isOpen} style={baseStyles} onRequestClose={onClose}>
      <S.Overlay>
        <S.ModalWrapper>
          <Panel
            padding="2rem 0"
            style={{
              background:
                'linear-gradient(180deg, #322c44 0%, #1d1926 100%), rgba(255, 255, 255, 0.1)',
              border: '1px solid rgba(255, 255, 255, 0.1)',
              overflow: 'visible',
            }}
          >
            <S.ModalContent>
              <S.Image
                alt=""
                src={
                  isIos ? Assets.images.appDownloadIos.url : Assets.images.appDownloadAndroid.url
                }
              />

              <S.TextContainer>
                <S.Title>Brain.fm is even better on the app</S.Title>
                <S.Description>
                  Download now for effortless access, offline listening, and tailored features just
                  for you.
                </S.Description>
              </S.TextContainer>

              <S.ButtonWrapper>
                <Button
                  data-testid="successButtonPrimary"
                  isFullWidth
                  keepTextCase
                  style={{ fontSize: '0.75rem', letterSpacing: '1.2px' }}
                  variant={ButtonVariants.Secondary}
                  onClick={primaryButtonAction}
                >
                  <S.ButtonContent>
                    <S.PlatformIcon
                      alt=""
                      src={isIos ? Assets.icons.appleBlack.url : Assets.icons.playStoreBlack.url}
                    />
                    <>{primaryButtonText}</>
                  </S.ButtonContent>
                </Button>
                <Button
                  data-testid="closeButtonSecondary"
                  isFullWidth
                  keepTextCase
                  style={{ fontSize: '0.75rem', letterSpacing: '1.2px' }}
                  variant={ButtonVariants.Outline}
                  onClick={onClose}
                >
                  {secondaryButtonText}
                </Button>
              </S.ButtonWrapper>
              <S.AuraImage alt="" src={Assets.images.appDownloadBackground.url} />
            </S.ModalContent>
          </Panel>
        </S.ModalWrapper>
      </S.Overlay>
    </ReactModal>
  );
}
