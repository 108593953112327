import { useEffect, useRef } from 'react';
import {
  trackWebMilestonesExperimentExposure,
  useWebMilestonesExperiment,
} from '../../../../domains/Utils/useAmplitudeExperiments';
import { Analytics } from '../../../../utils/analytics';
import { useCloseMilestonesProgressModal } from './hooks/useCloseMilestonesProgressModal';
import { useFirstActivityHandler } from './hooks/useFirstActivityHandler';
import { useIsMilestonesWidgetEnabled } from './hooks/useIsMilestonesWidgetEnabled';
import { useMilestonesProgressObserver } from './hooks/useMilestonesProgressObserver';
import { useUninterruptedListeningHandler } from './hooks/useUninterruptedListeningHandler';
import { MilestoneWidgetPresentation } from './MilestonesWidget.presentation';
import { useSelector } from 'react-redux';
import { RootReducerType } from 'src/reducers';
import { useFavoritesHandler } from './hooks/useFavoritesHandler';

type Props = {
  isHidden?: boolean;
};

export const MilestoneWidget = ({ isHidden }: Props) => {
  const isEnabled = useIsMilestonesWidgetEnabled();
  const webMilestonesExperimentData = useWebMilestonesExperiment();
  const { hasTakenOnboardingWhileInNonControlTest } = useSelector(
    (state: RootReducerType) => state.milestones,
  );
  const hasTrackedExposure = useRef(false);

  useFirstActivityHandler();
  useFavoritesHandler();
  useUninterruptedListeningHandler();
  useMilestonesProgressObserver();

  const {
    isDismissed,
    progressModal: { isOpen, data },
    items: { firstActivity, uninterruptedListening, favorites },
  } = useSelector((state: RootReducerType) => state.milestones);

  const isRendered = isEnabled && !isHidden && !isDismissed; // not showing the widget if any modal is open
  useEffect(() => {
    if (!isRendered) return;
    Analytics.logEventWithProperties('test_checklist_impression', {});
  }, [isRendered]);

  useEffect(() => {
    // track exposure of web milestones experiment when the user is in the test group
    if (!hasTrackedExposure.current && webMilestonesExperimentData?.isInTestGroup && !isDismissed) {
      trackWebMilestonesExperimentExposure();
      hasTrackedExposure.current = true;
    }
  }, [webMilestonesExperimentData?.isInTestGroup, isDismissed]);

  const onCloseModal = useCloseMilestonesProgressModal();

  if (!isRendered) {
    return null;
  }

  return (
    <>
      <MilestoneWidgetPresentation
        favorites={favorites}
        firstActivity={firstActivity}
        isModalOpen={isOpen}
        modalData={data}
        uninterruptedListening={uninterruptedListening}
        onCloseModal={onCloseModal}
      />
    </>
  );
};
