import { DynamicMentalState } from '@Model';
import { AnalyticsEvents } from '../../types';

// TODO convert to enum
export type ModalType =
  | 'appMessage'
  | 'paywall'
  | 'verifyEmail'
  | 'profile'
  | 'song'
  | 'timer'
  | 'updateStripePaymentMethod'
  | 'upgradeStripeSubscription'
  | 'subscriptionSuccess'
  | 'renewSubscription'
  | 'firstActivity'
  | 'firstSession'
  | 'fastSubscription'
  | 'streaksInfo'
  | 'streaksInfoAnimated'
  | 'streakMilestone'
  | 'appDownload'
  | 'desktopAnnouncementMobileModal'
  | 'referAFriendReward'
  | null;

export enum SideDeckModalType {
  EditProfile = 'EDIT_PROFILE',
  UpdatePassword = 'UPDATE_PASSWORD',
  DeleteAccount = 'DELETE_ACCOUNT',
  UpdatePayment = 'UPDATE_PAYMENT',
  CancelPayment = 'CANCEL_PAYMENT',
  UpgradeYearly = 'UPGRADE_YEARLY',
  UpgradeStripeSubscription = 'UPGRADE_STRIPE_SUBSCRIPTION',
}

export interface UiStateType {
  isReferAFriendDismissed: boolean;
  isPlayerAnimationEnabled: boolean;
  modalType: ModalType;
  playerVolume: number;
  sessionFinished: boolean;
  sessionModalContent: SessionModalContent | null;
  sessionModalContentOrigin?: string;
  explorePanelTab: ExplorePanelTab | null;
  libraryPanelTab: LibraryPanelTab | null;
  shouldShowFocusPersonalizationQuiz: boolean;
  shouldShowNELTipsModal: boolean;
  shouldShowFavoriteModal: boolean;
  successModal: SuccessModalType;
  sideDeck: {
    isOpen: boolean;
    selectedTab: SideDeckTabProperties;
    librarySelectedMentalState: DynamicMentalState | null;
    exploreSelectedMentalState: DynamicMentalState | null;
    modal: SideDeckModalType | null;
  };
}

export const enum SideDeckTab {
  Player,
  Library,
  Explore,
  Profile,
}

export interface PlayerTabProperties {
  tab: SideDeckTab.Player;
}

export interface LibraryTabProperties {
  tab: SideDeckTab.Library;
}

export interface ExploreTabProperties {
  tab: SideDeckTab.Explore;
}

export interface ProfileTabProperties {
  tab: SideDeckTab.Profile;
  profileSection: ProfileSection | null;
}

export enum ProfileSection {
  Profile = 'Profile',
  Account = 'Account Information',
  PaymentMethod = 'Payment Method',
  Subscription = 'Subscription',
  Settings = 'Settings',
  Refer = 'Refer a Friend',
  DownloadMacApp = 'Brain.fm for macOS',
}

export type SideDeckTabProperties =
  | PlayerTabProperties
  | LibraryTabProperties
  | ExploreTabProperties
  | ProfileTabProperties;

export enum SessionModalContent {
  Activity = 'Activity',
  Preferences = 'Preferences',
  TimerSettings = 'TimerSettings',
  TrackDetails = 'TrackDetails',
}

export enum ExplorePanelTab {
  Featured = 'Featured',
  Genres = 'Genres',
}

export enum LibraryPanelTab {
  Favorites = 'Favorites',
  Recent = 'Recent',
}

export type SuccessModalType = {
  iconSrc?: string | null;
  iconType?: 'success' | 'info';
  isOpen: boolean;
  isDismissable?: boolean;
  title?: string | null;
  description?: string | null | string[];
  actions?: SuccessModalTypeAction[];
};

export type SuccessModalTypeAction = {
  analyticsEvent?: AnalyticsEvents;
  action: any;
  navigateTo?: string;
  navigateState?: {
    [key: string]: any;
  };
  text: string;
  type: 'primary' | 'secondary';
};
