import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

export interface Announcement {
  id: string;
  type?: 'info' | 'success' | 'warning' | 'error';
}

export interface AnnouncementStateType {
  announcements: Announcement[];
  seenAnnouncements: string[];
}

const initialState: AnnouncementStateType = {
  announcements: [],
  seenAnnouncements: [],
};

const announcementSlice = createSlice({
  name: 'announcement',
  initialState,
  reducers: {
    addAnnouncement: (state, action: PayloadAction<Announcement>) => {
      // Only add if not previously seen
      if (!state.seenAnnouncements.includes(action.payload.id)) {
        state.announcements.push(action.payload);
      }
    },
    dismissAnnouncement: (state, action: PayloadAction<string>) => {
      const id = action.payload;
      state.announcements = state.announcements.filter(announcement => announcement.id !== id);
      // Add to seen announcements
      if (!state.seenAnnouncements.includes(id)) {
        state.seenAnnouncements.push(id);
      }
    },
    dismissAllAnnouncements: state => {
      // Add all current announcements to seen announcements
      state.seenAnnouncements.push(...state.announcements.map(a => a.id));
      state.announcements = [];
    },
    clearState: state => {
      state.announcements = [];
      state.seenAnnouncements = [];
    },
  },
});

const persistConfig = {
  key: 'announcement',
  storage,
};

export const announcementSliceActions = announcementSlice.actions;
export const announcementPersistReducer = persistReducer(persistConfig, announcementSlice.reducer);
