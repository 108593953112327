import { useEffect } from 'react';
import { Analytics } from '../../../../../utils/analytics';
import { useShouldRenderReferAFriend } from './useShouldRenderReferAFriend';
import { BfmAnalytics } from '@brainfm/analytics';

export const useTrackImpressions = () => {
  const shouldRender = useShouldRenderReferAFriend();

  useEffect(() => {
    if (shouldRender) {
      Analytics.logEvent('player_refer_a_friend_banner_impression');

      // Extra logging for BrainFM Analytics
      BfmAnalytics.getInstance().logEvent({
        name: 'player_refer_a_friend_banner_impression',
      });
    }
  }, []);
};
