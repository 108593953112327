import { useTrackPageView, useTrackPwaInstall } from '@Analytics';
import { ForgotPasswordScreen, useMagicLinkToken } from '@Authentication';
import { SuccessModal } from '@Cortex';
import { SELF_URL, LAUNCH_DARKLY, DEEP_LINK_ROUTES } from '@Globals';
import { useIngestPromotionOnMount } from '@Memberships';
import { RedemptionCodeForwarder } from '@Memberships';
import { useFetchFavoritesAndDislikesOnUserChange } from '@Music';
import { withLDProvider, ProviderConfig } from 'launchdarkly-react-client-sdk';
import { useEffect } from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { trackDeepLinkOpenChromeIos } from './domains/Analytics/coreAnalytics';
import { IntentionsHandler } from './domains/Intentions/IntentionsHandler';
import { useGetTokenFromCookie, useSetDeviceIdFromCookie } from './hooks';
import JoinTeamHandler from './screens/JoinTeamHandler';
import Login from './screens/Login';
import Main from './screens/Main';
import NotFound from './screens/NotFound';
import { ResetPassword } from './screens/ResetPassword/index';
import Signup from './screens/Signup';
import { trackGAEvent } from './utils/analytics/ga';
import { getDeviceType } from './utils/getDeviceType';
import { useRegisterSW } from 'virtual:pwa-register/react';
import { initializeBrainFmAnalytics } from './domains/Analytics/initializeBrainFmAnalytics';
import { WebUpgradeIntentionsHandler } from './domains/Memberships/components/WebUpgradeIntentionsHandler';

function App() {
  useTrackPageView();
  useTrackPwaInstall();
  const { token, isCheckingToken } = useGetTokenFromCookie();
  const { isCheckingMagicLink } = useMagicLinkToken();
  useSetDeviceIdFromCookie();
  useIngestPromotionOnMount({ isReady: isCheckingMagicLink });
  useFetchFavoritesAndDislikesOnUserChange();

  // Initialize BrainFm Analytics
  initializeBrainFmAnalytics();

  // Global click event listener for analytics
  useEffect(() => {
    window.addEventListener('click', trackGAEvent);

    return () => window.removeEventListener('click', trackGAEvent);
  }, []);

  useRegisterSW({
    onRegisteredSW(u) {},
    onRegisterError(error) {
      console.log('SW: registration error', error);
    },
    onNeedRefresh() {
      console.log('SW: refresh needed to update');
    },
    onOfflineReady() {
      console.log('SW: offline ready');
    },
  });

  // https://developer.apple.com/documentation/sign_in_with_apple/sign_in_with_apple_js/configuring_your_webpage_for_sign_in_with_apple
  useEffect(() => {
    if (typeof AppleID === 'undefined') return;
    AppleID.auth.init({
      clientId: 'fm.brain.web-app',
      scope: 'email name',
      redirectURI: SELF_URL,
      usePopup: true,
    });
  }, [typeof AppleID]);

  useEffect(() => {
    const { isIos } = getDeviceType();
    const isDeepLink = DEEP_LINK_ROUTES.some(link => window.location.href.includes(link));
    const isDynamicActivity = window.location.href.includes('/player');

    if (isIos && (isDeepLink || isDynamicActivity)) {
      const url = `${window.location.pathname}${window.location.search}`;
      trackDeepLinkOpenChromeIos();
      window.location.replace(`brainfm://${url}`);
    }
  }, []);

  if (isCheckingToken || isCheckingMagicLink) {
    return <div />;
  }
  const urlParams = new URLSearchParams(window.location.search);
  const utmSource = urlParams.get('utm_source');

  if (!token) {
    return (
      <>
        <Routes>
          <Route element={<Signup />} path="/" />
          <Route element={<Signup />} path="/signup" />
          {/* the following route is tmp until we start using DA link for sharing as it conflicts with /intentions/:id */}
          <Route
            element={<Navigate replace to={`/${utmSource ? `?utm_source=${utmSource}` : ''}`} />}
            path="/intentions/share"
          />
          <Route element={<Login />} path="/signin" />
          <Route element={<ForgotPasswordScreen />} path="/forgot-password" />
          <Route element={<ResetPassword />} path="/reset-password" />

          {/* TODO- these are duplicated in main and they must change in sync */}
          <Route element={<WebUpgradeIntentionsHandler />} path="/intentions/webUpgrade" />
          <Route element={<RedemptionCodeForwarder />} path="/intentions/redeem/:redemptionCode" />

          <Route
            element={<JoinTeamHandler isAuthenticated={false} />}
            path="/intentions/join/:teamId"
          />
          <Route element={<JoinTeamHandler isAuthenticated={false} />} path="/join/:teamId" />
          {/* these routes should only work for logged out individuals */}
          <Route element={<IntentionsHandler />} path="/intentions/:token" />

          <Route element={<NotFound isAuthenticated={false} />} path="*" />
        </Routes>
        <SuccessModal />
      </>
    );
  }

  return <Main />;
}

const ldConfig: ProviderConfig = {
  clientSideID: LAUNCH_DARKLY,
  options: { bootstrap: 'localStorage' },
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
};
export default withLDProvider(ldConfig)(App);
