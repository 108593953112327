import { useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { setModal } from '../../../../../actions/ui';
import { useMarkRewardsSeen } from '../../../../../api/modules/MarkRewardsSeen';
import { RootReducerType } from '../../../../../reducers';
import { Analytics } from '../../../../../utils/analytics';
import { Events } from '../../../../../utils/analytics/events';
import { Logger } from '../../../../../utils/logger';

import { ReferAFriendRewardModalDisplay } from './ReferAFriendRewardModal.display';

export function ReferAFriendRewardModal() {
  const modalType = useSelector((state: RootReducerType) => state.ui.modalType);
  const dispatch = useDispatch();
  const { mutateAsync: handleMarkRewardsSeen } = useMarkRewardsSeen();

  const onClose = useCallback(() => {
    Analytics.logEventWithProperties(Events.test_extension_modal_dismissed, {});
    dispatch(setModal(null));
  }, [dispatch, setModal]);

  const isOpen = modalType === 'referAFriendReward';

  useEffect(() => {
    if (!isOpen) return;
    Analytics.logEventWithProperties(Events.test_extension_modal_impression, {});
    handleMarkRewardsSeen().catch(error => {
      Logger.error(new Error('Failed to mark rewards as seen'), { error });
    });
  }, [isOpen, handleMarkRewardsSeen]);

  return (
    <ReferAFriendRewardModalDisplay
      isOpen={isOpen}
      primaryButtonAction={onClose}
      primaryButtonText="CONTINUE"
      onClose={onClose}
    />
  );
}
