import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Announcement, announcementSliceActions } from '../reducers/announcement';
import { RootReducerType } from '../reducers';

export const useAnnouncementWidget = () => {
  const dispatch = useDispatch();
  const { announcements } = useSelector((state: RootReducerType) => state.announcement);

  const addAnnouncement = useCallback(
    (announcement: Announcement) => {
      dispatch(announcementSliceActions.addAnnouncement(announcement));
    },
    [dispatch],
  );

  const dismissAnnouncement = useCallback(
    (id: string) => {
      dispatch(announcementSliceActions.dismissAnnouncement(id));
    },
    [dispatch],
  );

  const dismissAllAnnouncements = useCallback(() => {
    dispatch(announcementSliceActions.dismissAllAnnouncements());
  }, [dispatch]);

  return { addAnnouncement, dismissAnnouncement, dismissAllAnnouncements, announcements };
};
