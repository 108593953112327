import { Assets } from '../../../../utils/assets';
import * as S from './AdhdSwitch.styles';

export interface Props {
  isActive: boolean;
  onClick: () => void;
}

export const AdhdSwitchDisplay = ({ isActive, onClick }: Props) => {
  return (
    <S.ToggleContainer isActive={isActive} onClick={onClick}>
      <S.Knob isActive={isActive}>
        <img alt="" src={Assets.icons.lightningBlack.url} />
      </S.Knob>
    </S.ToggleContainer>
  );
};
