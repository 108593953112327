import { useToken } from '@Authentication';
import { useGetAvailableStripeCouponPromotion } from '@Memberships';
import { useStripe, useElements } from '@stripe/react-stripe-js';
import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation } from 'react-router-dom';
import { RootReducerType } from '../../../../../reducers';
import { EnhancedMembershipPlanType } from '../../../../../types';
import { performPayment } from '../lib/performPayment';
import { PaymentStatus } from '../types';

interface HistoryState {
  redirect: boolean;
  from?: string;
}

type Props = {
  plan: EnhancedMembershipPlanType | null;
  onError: (msg: string | null) => void;
  onPaymentComplete?: () => void;
  onPaymentStatusChange: (status: PaymentStatus) => void;
};

export const useHandlePayment = ({
  plan,
  onError,
  onPaymentComplete,
  onPaymentStatusChange,
}: Props) => {
  const stripe = useStripe();
  const elements = useElements();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = useToken();
  const { info } = useSelector((state: RootReducerType) => state.user);
  const availablePromotion = useGetAvailableStripeCouponPromotion();
  const location = useLocation();
  const utmSource = searchParams.get('utm_source') || undefined;

  return useCallback(
    (billingName: string, isExpressCheckout?: boolean) =>
      performPayment({
        email: info?.email,
        plan,
        stripe,
        elements,
        token,
        userId: info?.id,
        utmSource,
        couponId: availablePromotion?.couponId,
        subscribeScreenFrom: (location.state as HistoryState)?.from,
        dispatch,
        onError,
        onPaymentComplete,
        onPaymentStatusChange,
        isExpressCheckout,
      })(billingName),
    [
      info?.email,
      plan,
      stripe,
      elements,
      token,
      info?.id,
      utmSource,
      availablePromotion?.couponId,
      dispatch,
      onError,
      onPaymentComplete,
      onPaymentStatusChange,
    ],
  );
};
